import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BigNumber from "bignumber.js";
import { modal } from "src/app/components/Modals/Modal";
import { ACTIONS, CLAIM_REWARD_STEPS, TOPICS } from "src/app/configs/constants";
import { validateClaimRewardsInput } from "src/app/utils/validators";
import BroadcastModal from "src/app/components/Modals/BroadcastModal";
import ClaimRewardsFormModal from "src/app/components/Modals/Reward/ClaimRewardsFormModal";
import ClaimRewardsConfirmModal from "src/app/components/Modals/Reward/ClaimRewardsConfirmModal";
import ClaimRewardSuccessModal from "src/app/components/Modals/Reward/ClaimRewardSuccessModal";
import { fetchAccountRewards } from "src/app/actions/rewardAction";
import txService from "src/app/services/txService";
import { formatBigNumber } from "src/app/utils/fortmaters";

export default function RewardContainer({ isOpenClaimModal, onCloseModal }) {
  const dispatch = useDispatch();
  const { address, lastTx } = useSelector(
    state => state.account
  );
  const { rewards } = useSelector(state => state.reward)

  const claimingAmount = rewards && rewards.remainingCumulativeAmounts[0] ? formatBigNumber(new BigNumber(rewards.remainingCumulativeAmounts[0])) : 0

  const [claimingStep, setClaimingStep] = useState(CLAIM_REWARD_STEPS.DEFAULT);
  const [forceOpenModal, setForceUpdateModal] = useState(0);
  const [error, setError] = useState("");

  const isSendingTx =
    claimingStep !== CLAIM_REWARD_STEPS.DEFAULT &&
    (lastTx.type === ACTIONS.APPROVE || lastTx.type === ACTIONS.CLAIM_REWARDS);

  useEffect(() => {
    if (isOpenClaimModal) {
      modal.show(
        ClaimRewardsFormModal,
        {
          address,
          claimingAmount,
          error,
          setError,
          openClaimingModal,
        },
        closeModal
      );
    }
  }, [isOpenClaimModal, error]); // eslint-disable-line

  useEffect(() => {
    if (claimingStep === CLAIM_REWARD_STEPS.CONFIRM) {
      modal.show(
        ClaimRewardsConfirmModal,
        { claimingAmount },
        closeModal
      );
    } else if (claimingStep === CLAIM_REWARD_STEPS.BROADCAST) {
      modal.show(
        BroadcastModal,
        { txHash: lastTx.hash, topic: TOPICS.CLAIM, nextStep: goToNextStep },
        closeModal
      );
    } else if (claimingStep === CLAIM_REWARD_STEPS.SUCCESS) {
      modal.show(
        ClaimRewardSuccessModal,
        { txHash: lastTx.hash, amount: claimingAmount },
        closeModal
      );
    }
  }, [claimingStep, forceOpenModal]); // eslint-disable-line

  useEffect(() => {
    if (!isSendingTx) return;

    txService.handleSuccess(lastTx.hash, () => {
      dispatch(fetchAccountRewards(address));
    });

    goToNextStep();
  }, [lastTx]); // eslint-disable-line

  useEffect(() => {
    clearError();
  }, [address]); // eslint-disable-line

  async function openClaimingModal() {
    const isInputValid = validateInput();
    if (!isInputValid) return;

    setClaimingStep(CLAIM_REWARD_STEPS.CONFIRM);
  }

  function validateInput() {
    let isValid = true;
    let errorMessage = validateClaimRewardsInput(
      claimingAmount,
      rewards,
      address
    );

    if (errorMessage !== null) {
      setError(errorMessage);
      isValid = false;
    } else if (isSendingTx && claimingStep !== CLAIM_REWARD_STEPS.DEFAULT) {
      setForceUpdateModal(forceOpenModal + 1);
      isValid = false;
    }

    return isValid;
  }

  function resetStep() {
    setClaimingStep(CLAIM_REWARD_STEPS.DEFAULT);
  }

  function goToNextStep() {
    setClaimingStep(claimingStep + 1);
  }

  function clearError() {
    setError("");
  }

  function closeModal() {
    onCloseModal();
    resetStep();
  }

  return null;
}
