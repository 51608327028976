import { useState, useEffect, useRef } from "react";

export default function useComponentVisible(initialIsVisible) {
  const [isOpen, setIsOpen] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    const isCloseTargetClicked = event.target.getAttribute('data-close-dropdown');
    const isOutsideClicked = ref.current && !ref.current.contains(event.target);

    if (isCloseTargetClicked || isOutsideClicked) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isOpen, setIsOpen };
}
