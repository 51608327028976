import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { roundNumber } from "src/app/utils/fortmaters";
import useGasAndTxFee from "src/app/components/Hooks/useGasAndTxFee";
import { setMigrateError, migrate } from "src/app/actions/migrateAction";
import { ACTIONS } from "src/app/configs/constants";
import Loading from "src/app/components/Commons/Loading";
import ModalPanel from "src/app/components/Modals/ModalPanel";
import { setTxConfirming } from "src/app/actions/accountAction";

export default function MigrateConfirmModal(props) {
  const dispatch = useDispatch();
  const { address, txConfirming, privateKey } = useSelector(
    (state) => state.account
  );
  const { migrateError } = useSelector((state) => state.migrate);

  const { gasPrice, gas, txFee, isGasLoading } = useGasAndTxFee(
    ACTIONS.MIGRATE,
    {
      address: address,
      migrateAmount: props.migrateAmount,
    }
  );
  useEffect(() => {
    dispatch(setMigrateError(""));
    dispatch(setTxConfirming(false));
  }, [dispatch]);

  function handleMigrateToken() {
    dispatch(migrate(props.migrateAmount, gasPrice, gas));
  }

  return (
    <div className="modal__staking modal-interact">
      <div className="modal-content">
        <div className="title">Confirm Migrate</div>
        <div className="ins">
          You are migrating {roundNumber(props.migrateAmount, 6, true)} KNC to new KNC
        </div>
        <div className="info mt-4 mb-4">
          Tx Fee: {isGasLoading ? <Loading /> : txFee} ETH
        </div>
      </div>

      <ModalPanel
        error={migrateError}
        isGasLoading={isGasLoading}
        txConfirming={txConfirming}
        txFee={txFee}
        handleCancel={props.close}
        handleConfirm={handleMigrateToken}
        showWatingMessage={!privateKey}
      />
    </div>
  );
}
