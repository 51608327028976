import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modal } from "src/app/components/Modals/Modal";
import VoteConfirmModal from "src/app/components/Modals/Vote/VoteConfirmModal";
import VoteSuccessModal from "src/app/components/Modals/Vote/VoteSuccessModal";
import BroadcastModal from "src/app/components/Modals/BroadcastModal";
import { ACTIONS, DEFAULT_GAS, TOPICS } from "src/app/configs/constants";
import { setTxConfirming } from "src/app/actions/accountAction";
import { setQueueError, queue } from "src/app/actions/queueAction";
import GasOption from "src/app/components/Commons/GasOption";
import ModalPanel from "src/app/components/Modals/ModalPanel";
import CopyableTx from "src/app/components/Commons/CopyableTx";

export default function QueueingContainer({queueInfo, clear}) {
  const { lastTx } = useSelector((state) => state.account);

  const [step, setStep] = useState(0);
  const isSendingTx = lastTx.type === ACTIONS.QUEUE;
  const modals = [
    () => modal.show(QueueConfirmModal, { ...queueInfo }, closeModal),
    () => modal.show(BroadcastModal, { txHash: lastTx.hash, topic: TOPICS.QUEUE, nextStep: nextStep }, closeModal),
    () => modal.show(QueueSuccessModal, { txHash: lastTx.hash }, closeModal)
  ];

  const requireInfo = (fn) => {
    if (Object.keys( queueInfo ).length > 0) {
      fn()
    }
  };

  useEffect(() => {
    if (!isSendingTx) return;
    nextStep();
  }, [lastTx] ); // eslint-disable-line

  useEffect(() => {
    requireInfo(() => {
      if (typeof modals[step] === 'function') modals[step]();
    })
  }, [step, queueInfo] ); // eslint-disable-line

  function closeModal() {
    setStep(0);
    clear();
  }

  function nextStep() {
    requireInfo(() => setStep(v => v + 1 ));
  }

  return <></>
}

function QueueConfirmModal({close, campID}) {
    const dispatch = useDispatch();
    const { address, txConfirming, privateKey } = useSelector((state) => state.account);
    const { queueError } = useSelector((state) => state.queue);
  
    useEffect(() => {
      dispatch(setQueueError(''));
      dispatch(setTxConfirming(false));
    }, [dispatch]);
  
    const queueSubmit = () => {
      dispatch(queue(campID));
    };
  
    return (
      <div className="modal__vote modal-interact">
        <div className="modal-content">
          <div className="title">Queue</div>
          <div className="info">Do you wish to queue the proposal?</div>
          <div className="common__note mt-4 mb-4">
            <span>Note: All queued proposal will be executed later.</span>
          </div>
  
          <GasOption
            txType={ACTIONS.QUEUE}
            txParams={{ address, campID }}
            defaultGasLimit={DEFAULT_GAS.VOTE}
          />
        </div>
  
        <ModalPanel
          error={queueError}
          txConfirming={txConfirming}
          handleCancel={close}
          handleConfirm={queueSubmit}
          showWatingMessage={!privateKey}
        />
      </div>
    )
  }
  
  function QueueSuccessModal({close, txHash}) {
    return (
      <div className="modal__vote modal-interact">
        <div className="modal__tx-done">
          <div className="modal-content">
            <div className="title">Done!</div>
            <div className="ins">The proposal has been executed already.</div>
            <CopyableTx txHash={txHash} />
          </div>
          <div className="panel">
            <div className="common__button" onClick={()=>close()}>Done</div>
          </div>
        </div>
      </div>
    )
  }
  