import { call, put, select, takeLatest } from "redux-saga/effects";
import { setExecuteError, executeActionTypes } from "src/app/actions/executeAction";
import Web3Service from "src/app/services/web3/Web3Service";
import { setLastTx, setTxConfirming } from "src/app/actions/accountAction";
import { ACTIONS } from "src/app/configs/constants";

const getAccountState = (state) => state.account;
const getGlobalState = (state) => state.global;

function* execute(action) {
  const { address, wallet, lastTx, privateKey, devicePath } = yield select(getAccountState);
  const { gasPrices, selectedGasPrice, estimatedGas } = yield select(getGlobalState);
  const { campID } = action.payload;
  
  yield put(setExecuteError(''));
  yield put(setTxConfirming(true));

  try {
    const web3Service = new Web3Service();
    const nonce = yield call(web3Service.fetchUsableNonce, address, lastTx.nonce);
    const executeTxObject = web3Service.getExecuteTxObject(address, campID, nonce, gasPrices[selectedGasPrice], estimatedGas);
    const txHash = yield call(wallet.makeTransaction, executeTxObject, privateKey, devicePath);

    yield put(setLastTx(txHash, nonce, ACTIONS.EXECUTE));
  } catch(e) {
    yield put(setExecuteError(typeof e === 'string' ? e : e.message));
  }

  yield put(setTxConfirming(false));
}

export default function* executeWatcher() {
  yield takeLatest(executeActionTypes.EXECUTE, execute);
}
