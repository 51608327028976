import React from 'react'
import CopyableTx from "src/app/components/Commons/CopyableTx"
import { roundNumber } from "src/app/utils/fortmaters";

export default function MigrateSuccessModal(props) {
  return (
    <div className="modal__staking modal-interact">
      <div className="modal__tx-done">
        <div className="modal-content">
          <div className="title">Done!</div>
          <div className="ins">Successfully migrated {roundNumber(props.migrateAmount, 6, true)} KNC to newKNC.
          </div>
          <CopyableTx txHash={props.txHash}/>
        </div>
        <div className="panel">
          <input className="common__button" type="submit" value="Done" onClick={props.close}/>
        </div>
      </div>
    </div>
  )
}
