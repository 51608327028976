import { takeLatest, put, select, call, delay } from "redux-saga/effects";
import { accountActionTypes, fetchAccountBalance } from "src/app/actions/accountAction";
import { txActionTypes, updateTxStatus, updateTxRead } from "src/app/actions/txAction";
import { appendTx } from "src/app/actions/txAction";
import { TX_STATUS, TOPICS, ACTIONS } from "src/app/configs/constants";
import Web3Service from "src/app/services/web3/Web3Service";
import { getKeyByValue } from "src/app/utils/converters";
import { setDelegatedAddress } from "src/app/actions/delegateAction";
import { fetchHistories } from "src/app/services/apis/daoService";
import { setHistories } from "src/app/actions/historyAction";
import txService from "src/app/services/txService";

const getTxsState = (state) => state.txs;
const getAccountState = (state) => state.account;

function* saveTx(action) {
  const { hash, type, amount, delegatedAddress } = action.payload;
  yield put(appendTx(hash, type, amount, delegatedAddress));
}

function* updateTxs() {
  const { data } = yield select(getTxsState);
  const { address } = yield select(getAccountState);

  try {
    for (let index = 0; index < data.length; index++) {
      const tx = data[index];

      if (tx.status === TX_STATUS.PENDING) {
        const web3Service = new Web3Service();
        let topic = getKeyByValue(ACTIONS, tx.type);
        let status = yield call(web3Service.checkTxMined, tx.hash, TOPICS[topic]);

        switch (status) {
          case true:
            status = TX_STATUS.SUCCESS;
            break;
          case false:
            status = TX_STATUS.FAILED;
            break;
          case null:
            status = TX_STATUS.PENDING;
            break;
          default:
            break;
        }

        if (status !== TX_STATUS.PENDING) {
          yield put(updateTxStatus(tx.hash, status));
          yield put(updateTxRead(tx.hash, 0));
          yield put(fetchAccountBalance(address));
        }

        if (status === TX_STATUS.SUCCESS) {
          try {
            txService.txMap[tx.hash].success();
          } catch(e) { }

          if (tx.type === ACTIONS.DELEGATE) {
            yield put(setDelegatedAddress(tx.delegatedAddress));
          }

          yield delay(2000);

          const histories = yield call(fetchHistories, address);
          yield put(setHistories(histories || []));
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* txWatcher() {
  yield takeLatest(accountActionTypes.SET_LAST_TX, saveTx);
  yield takeLatest(txActionTypes.UPDATE_TXS, updateTxs);
}
