import Web3 from "web3";
import BaseWalletService from "src/app/services/accounts/BaseWalletService";

export default class MetamaskService extends BaseWalletService {
  constructor(props) {
    super(props);

    this.ethereum = window.ethereum;

    if (this.ethereum) {
      this.web3 = new Web3(this.ethereum);
    }
  }

  subscribeToDisconnect = (clearAccount) => {
    this.getDisconnected(clearAccount);
  };

  getWalletName = () => {
    return 'Metamask';
  }
}