import { useState, useEffect } from 'react';
import Web3Service from "src/app/services/web3/Web3Service";
import { calculateTxFee } from "src/app/utils/calculators";
import { useSelector } from "react-redux";

export default function useGasAndTxFee(txType, params = {}) {
  const { gasPrices, selectedGasPrice } = useSelector(state => state.global);
  const [gasPrice, setGasPrice] = useState(null);
  const [gas, setGas] = useState(null);
  const [txFee, setTxFee] = useState(null);

  useEffect(() => {
    async function fetchGasAndTxFee() {
      const web3Service = new Web3Service();
      const gasPrice = gasPrices[selectedGasPrice];
      const estimatedGasLimit = await web3Service.estimatedGasByType(txType, params);
      const txFee = calculateTxFee(gasPrice, estimatedGasLimit);

      setGasPrice(gasPrice);
      setGas(estimatedGasLimit);
      setTxFee(txFee);
    }

    fetchGasAndTxFee();
  }, []); // eslint-disable-line

  return {
    gasPrice, gas, txFee,
    isGasLoading: gasPrice === null || gas === null
  };
}
