import React, { useState } from "react";
import { PROPOSAL_STATUSES } from "src/app/configs/constants";
import { useDispatch, useSelector } from "react-redux";
import StakeContainer from "../../Stake/StakeContainer";
import { toggleAccountModal } from "src/app/actions/globalAction";
import useCheckAccountImported from "../../Hooks/useCheckAccountImported";
import useCheckEndedProposal from "../Hooks/useCheckEndedProposal";

export default function ProposalButtons({ proposal, alreadyVotedOption, onSubmit, isStaked }) {
  const dispatch = useDispatch();
  const { address } = useSelector((state) => state.account);
  const { openActionModal } = useCheckAccountImported();
  const isProposalEnded = useCheckEndedProposal(proposal);
  const isProposalUpcoming = proposal.status === PROPOSAL_STATUSES.UPCOMING;
  const [isOpenStakeModal, setIsOpenStakeModal] = useState(false);

  function openImportModal() {
    dispatch(toggleAccountModal());
  }

  function openStakeModal() {
    openActionModal(() => setIsOpenStakeModal(true));
  }

  return (
    <div className="proposal__item-actions">
      {!isProposalEnded && (
        <div
          className={`
            common__button ${alreadyVotedOption !== false ? "common__button--hollow" : ""}
            ${!address || !isStaked || isProposalUpcoming ? "common__button--disabled" : ""}
          `}
          onClick={() => openActionModal(onSubmit)}
        >
          {alreadyVotedOption !== false ? "Change Vote" : "Vote Now"}
        </div>
      )}

      {address && !isStaked && !isProposalEnded && (
        <div className="common__button" onClick={openStakeModal}>
          Stake Now
        </div>
      )}

      {!address && !isProposalEnded && (
        <div className="common__button" onClick={openImportModal}>
          Import Wallet
        </div>
      )}

      <StakeContainer isOpenStakeModal={isOpenStakeModal} onCloseModal={() => setIsOpenStakeModal(false)} />
    </div>
  );
}
