import React from "react";
import { Redirect, Switch } from "react-router-dom";
import ComponentErrorBoundary from "src/app/components/Errors/ComponentErrorBoundary";
import { ModalListener } from "src/app/components/Modals/Modal";
import { routes } from "src/app/configs/routes";
import ProposalList from "src/app/components/Proposal/ProposalList";
import History from "src/app/components/History/History";
import Reward from "src/app/components/Reward/Reward";
import useFetchingData from "src/app/components/Hooks/useFetchingData";
import useSettingUpAccount from "src/app/components/Hooks/useSettingUpAccount";
import Timeout from "src/app/components/Commons/Timeout";
import Layout from "src/app/components/Layouts/Layout";
import DelegationExplain from "src/app/components/Delegate/DelegationExplain";
import ProposalDetails from "src/app/components/Proposal/ProposalDetails";
import Faq from "src/app/components/Faq/Faq";
import About from "src/app/components/About/About";
import ScrollToTop from "src/app/components/Commons/ScrollToTop";
import Migrate from "src/app/components/Migrate";

export default function Body() {
  useSettingUpAccount();
  useFetchingData();
  return (
    <div>
      <Timeout />
      <ComponentErrorBoundary>
        <ScrollToTop />
        <Switch>
          <Layout path={routes.vote} exact component={ProposalList} />
          <Layout path={routes.learn} exact component={ProposalList} />
          <Layout path={routes.history} exact component={History} />
          <Layout path={routes.delegation} exact component={DelegationExplain} />
          <Layout path={routes.proposal.path} exact component={ProposalDetails} />
          <Layout path={routes.faq} exact component={Faq} pageMode={true} />
          <Layout path={routes.about} exact component={About} pageMode={true} fullWidth={true} />
          <Layout path={routes.migrate} exact component={Migrate} pageMode={true} />
          <Layout path={routes.reward} exact component={Reward} pageMode={true} />
          <Redirect to={routes.vote} />
        </Switch>
      </ComponentErrorBoundary>
      <ModalListener />

      <div className={`modal-overlay`} />
    </div>
  );
}
