export const globalActionTypes = {
  SET_HEADER_DATA: "GLOBAL.SET_HEADER_DATA",
  SET_HEADER_DATA_LOADING: "GLOBAL.SET_HEADER_DATA_LOADING",
  SET_DAO_INFO: "GLOBAL.SET_DAO_INFO",
  TOGGLE_ACCOUNT_MODAL: "GLOBAL.TOGGLE_ACCOUNT_MODAL",
  CLOSE_MODALS: "GLOBAL.CLOSE_MODALS",
  SET_GAS_PRICES: "GLOBAL.SET_GAS_PRICES",
  SET_SELECTED_GAS_PRICE: "GLOBAL.SET_SELECTED_GAS_PRICE",
  SET_ESTIMATED_GAS: "GLOBAL.SET_ESTIMATED_GAS",
  SET_CURRENT_NETWORK_DATA: "GLOBAL.SET_CURRENT_NETWORK_DATA",
  SET_VOTING_REWARD: "GLOBAL.SET_VOTING_REWARD",
};

export function setHeaderData(data) {
  return {
    type: globalActionTypes.SET_HEADER_DATA,
    payload: data,
  };
}

export function setHeaderDataLoading(isLoading) {
  return {
    type: globalActionTypes.SET_HEADER_DATA_LOADING,
    payload: isLoading,
  };
}

export function setDaoInfo(info) {
  return {
    type: globalActionTypes.SET_DAO_INFO,
    payload: info,
  };
}

export function toggleAccountModal() {
  return {
    type: globalActionTypes.TOGGLE_ACCOUNT_MODAL,
  };
}

export function closeModals() {
  return {
    type: globalActionTypes.CLOSE_MODALS,
  };
}

export function setGasPrices(gasPrices) {
  return {
    type: globalActionTypes.SET_GAS_PRICES,
    payload: gasPrices,
  };
}

export function setSelectedGasPrice(gasPriceKey) {
  return {
    type: globalActionTypes.SET_SELECTED_GAS_PRICE,
    payload: gasPriceKey,
  };
}

export function setEstimatedGas(estimatedGas) {
  return {
    type: globalActionTypes.SET_ESTIMATED_GAS,
    payload: estimatedGas,
  };
}

export function setCurrentNetworkData(networkData) {
  return {
    type: globalActionTypes.SET_CURRENT_NETWORK_DATA,
    payload: networkData,
  };
}

export function setVotingReward(votingReward) {
  return {
    type: globalActionTypes.SET_VOTING_REWARD,
    payload: votingReward,
  };
}
