import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import createSagaMiddleware from "redux-saga";
// import { logger } from 'redux-logger';
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "src/assets/styles/index.scss";
import * as serviceWorker from "src/serviceWorker";
import rootSaga from "src/app/sagas";
import rootReducer from "src/app/reducers";
import { ApolloProvider } from "@apollo/client";
import Body from "src/app/components/Layouts/Body";
import { client } from "src/app/services/apollo/client";

let composeEnhancers = compose;

// If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
/* istanbul ignore next */
if (process.env.NODE_ENV !== "production" && typeof window === "object") {
  /* eslint-disable no-underscore-dangle */
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

  // NOTE: Uncomment the code below to restore support for Redux Saga
  // Dev Tools once it supports redux-saga version 1.x.x
  // if (window.__SAGA_MONITOR_EXTENSION__)
  //   reduxSagaMonitorOptions = {
  //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
  //   };
  /* eslint-enable */
}

const sagaMiddleware = createSagaMiddleware();
let middleware = [sagaMiddleware];

const enhancers = [applyMiddleware(...middleware)];

const store = createStore(rootReducer, composeEnhancers(...enhancers));

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

if (new Date().getTime() > 1677517200000) {
  window.location.replace("https://kyberswap.com/kyberdao/stake-knc");
}

const App = (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Body />
        </BrowserRouter>
      </PersistGate>
    </ApolloProvider>
  </Provider>
);

ReactDOM.render(App, document.getElementById("root"));

serviceWorker.unregister();
