import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { routes } from "src/app/configs/routes";
import { fetchCampaignById } from "src/app/services/apis/daoService";
import ProposalRecap from "src/app/components/Proposal/Elements/ProposalRecap";
import ProposalOptions from "src/app/components/Proposal/Elements/ProposalOptions";
import { getSocialIconByLink } from "src/app/utils/converters";
import Loading from "src/app/components/Commons/Loading";
import ProposalButtons from "src/app/components/Proposal/Elements/ProposalButtons";
import ProposalStatus from "src/app/components/Proposal/Elements/ProposalStatus";
import VotingContainer from "src/app/components/Proposal/VotingContainer";
import useSelectedOption from "src/app/components/Proposal/Hooks/useSelectedOption";
import { renderProposalTitle } from "src/app/utils/fortmaters";
import ProposalMessage from "src/app/components/Proposal/Elements/ProposalMessage";
import { FETCHING_INTERVALS, PROPOSAL_STATUSES } from "src/app/configs/constants";
import QueueingContainer from "src/app/components/Proposal/QueueingContainer";
import ExecutingContainer from "src/app/components/Proposal/ExecutingContainer";
import IcGoBack from "src/assets/icons/IcGoBack";

export default function ProposalDetails() {
  let { proposalId } = useParams();
  const history = useHistory();
  const [proposal, setProposal] = useState(null);
  const {
    alreadyVotedOption,
    votingPower,
    selectedOption,
    percentageOptions,
    onSelect,
    voteError,
    onSubmit,
    voteInfo,
    setVoteInfo,
    isStaked,
    message,
  } = useSelectedOption(proposal);

  /** Fetching Proposal details by interval **/
  useEffect(() => {
    async function getProposalById() {
      let foundProposal = await fetchCampaignById(proposalId);

      if (!foundProposal) history.push(routes.vote);

      setProposal(foundProposal);
    }

    getProposalById();
    const fetchProposalInterval = setInterval(() => {
      getProposalById();
    }, FETCHING_INTERVALS.DEFAULT);

    return () => {
      clearInterval(fetchProposalInterval);
    };
  }, [proposalId]); // eslint-disable-line

  function renderLink(link) {
    const icon = getSocialIconByLink(link);

    return (
      <a href={link} target="_blank" rel="noreferrer noopener">
        <div className={`proposal__item-social-icon icon__${icon}`} />
      </a>
    );
  }

  const [queueInfo, setQueueInfo] = useState({});
  const [executeInfo, setExecuteInfo] = useState({});

  return (
    <div className="proposal proposal-details">
      {proposal && (
        <div className="common__slide-up">
          <Link to={routes.vote} className="proposal-details__back">
            <IcGoBack className="mr-2" />
            <span>Back</span>
          </Link>
          <div className="proposal-details__header">
            <div>
              <div className="proposal-details__title">{renderProposalTitle(proposal)}</div>
              <ProposalStatus proposal={proposal} />
            </div>
          </div>

          <div className="proposal-details__container">
            <div className="proposal-details__left common__block">
              <ProposalRecap proposal={proposal} alreadyVotedOption={alreadyVotedOption} votingPower={votingPower} />
              <div className="proposal-details__desc" dangerouslySetInnerHTML={{ __html: proposal.desc }} />
              {proposal.link && (
                <div className="proposal__item-social mt-3">
                  <div className="proposal__item-learn">Learn more on:</div>
                  {renderLink(proposal.link)}
                </div>
              )}
            </div>

            <div className="proposal-details__right">
              <div className="common__block">
                <ProposalMessage message={voteError} type="error" />
                <ProposalMessage message={message} type="info" />
                <ProposalOptions
                  proposal={proposal}
                  alreadyVotedOption={alreadyVotedOption}
                  selectedOption={selectedOption}
                  percentageOptions={percentageOptions}
                  onSelect={onSelect}
                  showAllAddress={true}
                />
              </div>

              <ProposalButtons
                proposal={proposal}
                alreadyVotedOption={alreadyVotedOption}
                onSubmit={onSubmit}
                isStaked={isStaked}
              />
              <div className="proposal__item-actions">
                {proposal.status === PROPOSAL_STATUSES.SUCCEEDED && (
                  <div
                    className="common__button"
                    onClick={() => {
                      setQueueInfo({ campID: proposal.proposal_id });
                    }}
                  >
                    Queue
                  </div>
                )}
                {/* {proposal.status === PROPOSAL_STATUSES.QUEUED && <div className="common__button" onClick={() => { setExecuteInfo({campID: proposal.proposal_id})}}>Execute</div>} */}
              </div>
            </div>
          </div>
        </div>
      )}

      {!proposal && (
        <div className="text-center">
          <Loading />
        </div>
      )}

      <VotingContainer voteInfo={voteInfo} clear={() => setVoteInfo({})} />
      <QueueingContainer queueInfo={queueInfo} clear={() => setQueueInfo({})} />
      <ExecutingContainer executeInfo={executeInfo} clear={() => setExecuteInfo({})} />
    </div>
  );
}
