import React from "react";

export default function IcV1(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.6667 14.6666C11.4 14.6666 12 14.0666 12 13.3333L11.9933 11.2133C11.9933 10.8599 11.8533 10.5266 11.6067 10.2733L9.33333 7.99992L11.6067 5.71325C11.8533 5.46659 11.9933 5.12659 11.9933 4.77325L12 2.66659C12 1.93325 11.4 1.33325 10.6667 1.33325H5.33333C4.6 1.33325 4 1.93325 4 2.66659V4.77325C4 5.12659 4.14 5.46659 4.38667 5.71992L6.66667 7.99992L4.39333 10.2666C4.14 10.5199 4 10.8599 4 11.2133V13.3333C4 14.0666 4.6 14.6666 5.33333 14.6666H10.6667ZM5.33333 4.72659V3.33325C5.33333 2.96659 5.63333 2.66659 6 2.66659H10C10.3667 2.66659 10.6667 2.96659 10.6667 3.33325V4.72659C10.6667 4.90659 10.5933 5.07325 10.4733 5.19992L8 7.66659L5.52667 5.19325C5.40667 5.07325 5.33333 4.89992 5.33333 4.72659Z"
        fill="#868787"
      />
    </svg>
  );
}
