import { globalActionTypes } from "src/app/actions/globalAction";
import { DEFAULT_GAS_PRICE } from "src/app/configs/constants";

const initialState = {
  headerData: {
    knc: {
      rate: 0,
      change24h: 0,
    },
    eth: {
      rate: 0,
      change24h: 0,
    },
    isLoading: false,
  },
  daoInfo: {},
  modals: {
    account: false,
  },
  gasPrices: {
    "Super Fast": 60,
    Fast: 30,
    Standard: 20,
    Low: 15,
  },
  votingReward: 0,
  selectedGasPrice: DEFAULT_GAS_PRICE,
  estimatedGas: 0,
  currentNetworkData: null,
};

export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case globalActionTypes.SET_HEADER_DATA: {
      return {
        ...state,
        headerData: action.payload,
      };
    }
    case globalActionTypes.SET_HEADER_DATA_LOADING: {
      return {
        ...state,
        headerData: {
          ...state.headerData,
          isLoading: action.payload,
        },
      };
    }
    case globalActionTypes.SET_DAO_INFO: {
      return {
        ...state,
        daoInfo: action.payload,
      };
    }
    case globalActionTypes.TOGGLE_ACCOUNT_MODAL: {
      return {
        ...state,
        modals: {
          ...state.modals,
          account: !state.modals.account,
        },
      };
    }
    case globalActionTypes.CLOSE_MODALS: {
      return {
        ...state,
        modals: {
          account: false,
        },
      };
    }
    case globalActionTypes.SET_GAS_PRICES: {
      return {
        ...state,
        gasPrices: action.payload,
      };
    }
    case globalActionTypes.SET_SELECTED_GAS_PRICE: {
      return {
        ...state,
        selectedGasPrice: action.payload,
      };
    }
    case globalActionTypes.SET_ESTIMATED_GAS: {
      return {
        ...state,
        estimatedGas: action.payload,
      };
    }
    case globalActionTypes.SET_CURRENT_NETWORK_DATA: {
      return {
        ...state,
        currentNetworkData: action.payload,
      };
    }
    case globalActionTypes.SET_VOTING_REWARD: {
      return {
        ...state,
        votingReward: action.payload,
      };
    }
    default:
      return state;
  }
}
