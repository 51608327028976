import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import TrezorService from "src/app/services/accounts/Devices/TrezorService";
import { modal } from "src/app/components/Modals/Modal";
import DeviceSelectAddress from "src/app/components/Modals/Device/DeviceSelectAddress";
import BasicModal from "src/app/components/Modals/BasicModal";
import DeviceLoading from "src/app/components/Modals/Device/DeviceLoading";
import { importAccount } from "src/app/actions/accountAction";
import { TREZOR_DERIVATION_PATHS, WALLET_TYPES } from "src/app/configs/constants";

export default function TrezorAccount(props) {
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  const [selectedPath, setSelectedPath] = useState(TREZOR_DERIVATION_PATHS[0]);

  useEffect(() => {
    if (!init) return;
    connect();
  }, [selectedPath.value]); // eslint-disable-line

  function connect() {
    setInit(true);
    setConnection();
  }

  async function setConnection() {
    showLoadingModal();

    try {
      const wallet = new TrezorService();

      await wallet.setConnection(selectedPath.value);

      showSelectAddressModal(wallet, selectedPath);
    } catch (e) {
      showErrorModal(e.message);
    }
  }

  function onSelectAddress(address, wallet) {
    const devicePath = `${selectedPath.value}/${address.index}`;
    dispatch(importAccount(address.address, wallet, WALLET_TYPES.TREZOR, { devicePath }));
    modal.close();
  }

  function showSelectAddressModal(wallet, path) {
    modal.show(DeviceSelectAddress, {
      deviceType: 'Trezor',
      selectedPath: path,
      wallet: wallet,
      onSelectAddress: onSelectAddress,
      onSelectPath: setSelectedPath,
      paths: TREZOR_DERIVATION_PATHS
    });
  }

  function showLoadingModal() {
    modal.show(DeviceLoading, { hideCloseButton: true });
  }

  function showErrorModal(errorMessage) {
    modal.show(BasicModal, {
      title: "Error",
      content: (
        <Fragment>
          {errorMessage && (
            <div>{errorMessage}</div>
          )}

          {!errorMessage && (
            <div>Cannot detect Trezor device. Please plug in your Trezor.</div>
          )}
        </Fragment>
      )
    });
  }

  return (
    <div className={`account__item ${props.className}`} onClick={connect}>
      <div className="account__item-icon trezor"/>
      <div className="account__item-name">Trezor</div>
    </div>
  )
};
