import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modal } from "src/app/components/Modals/Modal";
import StakingConfirmModal from "src/app/components/Modals/Stake/StakingConfirmModal";
import ApproveTokenModal from "src/app/components/Modals/Approve/ApproveTokenModal";
import Web3Service from "src/app/services/web3/Web3Service";
import { compareTwoNumber } from "src/app/utils/calculators";
import StakingSuccessModal from "src/app/components/Modals/Stake/StakingSuccessModal";
import { ACTIONS, STAKING_STEPS, TOPICS } from "src/app/configs/constants";
import { toBigAmount } from "src/app/utils/converters";
import { validateBalanceInput } from "src/app/utils/validators";
import BroadcastModal from "src/app/components/Modals/BroadcastModal";
import StakingFormModal from "src/app/components/Modals/Stake/StakingFormModal";
import { setPendingStakeAmount } from "src/app/actions/accountAction";
import txService from "src/app/services/txService";
import ENV from "src/app/configs/env";

export default function StakeContainer({isOpenStakeModal, onCloseModal}) {
  const dispatch = useDispatch();
  const { accountInfo, address, balance, lastTx } = useSelector((state) => state.account);

  const [stakingAmount, setStakingAmount] = useState("");
  const [stakingStep, setStakingStep] = useState(STAKING_STEPS.DEFAULT);
  const [forceOpenModal, setForceUpdateModal] = useState(0);
  const [error, setError] = useState("");

  const isSendingTx = stakingStep !== STAKING_STEPS.DEFAULT &&
    (lastTx.type === ACTIONS.APPROVE || lastTx.type === ACTIONS.STAKE);

  useEffect(() => {
    if (isOpenStakeModal) {
      modal.show(StakingFormModal, {
        address: address,
        stakingAmount: stakingAmount,
        error: error,
        setError: setError,
        setStakingAmount: setStakingAmount,
        openStakingModal: openStakingModal
      }, closeModal);
    }
  }, [isOpenStakeModal, stakingAmount, error]); // eslint-disable-line

  useEffect(() => {
    if (stakingStep === STAKING_STEPS.APPROVE_ZERO) {
      modal.show(ApproveTokenModal, { isApproveToMax: false, token: ENV.KNC_ADDRESS, delegator: ENV.CONTRACTS.STAKING }, closeModal);
    } else if (stakingStep === STAKING_STEPS.APPROVE_MAX) {
      modal.close(); 
      setTimeout(() => {
        modal.show(ApproveTokenModal, { isApproveToMax: true, token: ENV.KNC_ADDRESS, delegator: ENV.CONTRACTS.STAKING }, closeModal);
      }, 0);
    } else if (stakingStep === STAKING_STEPS.CONFIRM) {
      modal.show(StakingConfirmModal, { stakingAmount: stakingAmount }, closeModal);
    } else if (stakingStep === STAKING_STEPS.BROADCAST) {
      modal.show(
        BroadcastModal,
        { txHash: lastTx.hash, topic: TOPICS.STAKE, nextStep: goToNextStep },
        closeModal
      );
    } else if (stakingStep === STAKING_STEPS.SUCCESS) {
      modal.show(
        StakingSuccessModal,
        { txHash: lastTx.hash, stakingAmount: stakingAmount },
        closeModal
      );
    }
  }, [stakingStep, forceOpenModal]); // eslint-disable-line

  useEffect(() => {
    if (!isSendingTx) return;

    txService.handleSuccess(lastTx.hash, () => {
      const currentPendingStakeAmount = accountInfo.pending_stake_amount;
      dispatch(setPendingStakeAmount(currentPendingStakeAmount + stakingAmount));
    });
    goToNextStep();
  }, [lastTx]); // eslint-disable-line

  useEffect(() => {
    clearError();
  }, [address]); // eslint-disable-line

  async function openStakingModal() {
    const isInputValid = validateInput();

    if (!isInputValid) return;

    const web3Service = new Web3Service();
    const tokenAllowance = await web3Service.fetchTokenAllowance(address);

    if (+tokenAllowance === 0) {
      setStakingStep(STAKING_STEPS.APPROVE_MAX);
    } else if (compareTwoNumber(tokenAllowance, toBigAmount(stakingAmount)) === -1) {
      setStakingStep(STAKING_STEPS.APPROVE_ZERO);
    } else {
      setStakingStep(STAKING_STEPS.CONFIRM);
    }
  }

  function validateInput() {
    let isValid = true;
    let errorMessage = validateBalanceInput(stakingAmount, balance.KNC, address);

    if (errorMessage !== null) {
      setError(errorMessage);
      isValid = false;
    } else if (isSendingTx && stakingStep !== STAKING_STEPS.DEFAULT) {
      setForceUpdateModal(forceOpenModal + 1);
      isValid = false;
    }

    return isValid;
  }

  function resetStep() {
    setStakingStep(STAKING_STEPS.DEFAULT);
  }

  function goToNextStep() {
    setStakingStep(stakingStep + 1);
  }

  function clearError() {
    setError("");
  }

  function closeModal() {
    onCloseModal();
    setStakingAmount("");
    resetStep();
  }

  return null;
}
