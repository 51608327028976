export const accountActionTypes = {
  IMPORT_ACCOUNT: 'ACCOUNT.IMPORT_ACCOUNT',
  CLEAR_ACCOUNT: 'ACCOUNT.CLEAR_ACCOUNT',
  CLEAR_PRIVATEKEY: 'ACCOUNT.CLEAR_PRIVATEKEY',
  FETCH_ACCOUNT_BALANCE: 'ACCOUNT.FETCH_ACCOUNT_BALANCE',
  SET_ACCOUNT_BALANCE: 'ACCOUNT.SET_ACCOUNT_BALANCE',
  SET_BALANCE_LOADING: 'ACCOUNT.SET_BALANCE_LOADING',
  SET_LAST_TX: 'ACCOUNT.SET_LAST_TX',
  SET_TX_CONFIRMING: 'ACCOUNT.SET_TX_CONFIRMING',
  SET_INIT_ACCOUNT_STATE: 'ACCOUNT.SET_INIT_ACCOUNT_STATE',
  SET_ACCOUNT_INFO: 'ACCOUNT.SET_ACCOUNT_INFO',
  SET_PENDING_STAKE_AMOUNT: 'ACCOUNT.SET_PENDING_STAKE_AMOUNT',
};

export function importAccount(address, wallet, type, accountParams = {}) {
  return {
    type: accountActionTypes.IMPORT_ACCOUNT,
    payload: { address, wallet, type, accountParams }
  }
}

export function clearAccount() {
  return {
    type: accountActionTypes.CLEAR_ACCOUNT
  }
}

export function clearPrivateKey() {
  return {
    type: accountActionTypes.CLEAR_PRIVATEKEY
  }
}

export function setInitAccountState(initState) {
  return {
    type: accountActionTypes.SET_INIT_ACCOUNT_STATE,
    payload: { initState }
  }
}

export function fetchAccountBalance(address) {
  return {
    type: accountActionTypes.FETCH_ACCOUNT_BALANCE,
    payload: address
  }
}

export function setAccountBalance(ETHBalance, KNCBalance, KNCBalanceOld, stakedKNCBalance) {
  return {
    type: accountActionTypes.SET_ACCOUNT_BALANCE,
    payload: { ETHBalance, KNCBalance, KNCBalanceOld, stakedKNCBalance }
  }
}

export function setBalanceLoading(isLoading) {
  return {
    type: accountActionTypes.SET_BALANCE_LOADING,
    payload: isLoading
  }
}

export function setLastTx(hash, nonce, type, amount, delegatedAddress) {
  return {
    type: accountActionTypes.SET_LAST_TX,
    payload: { hash, nonce, type, amount, delegatedAddress }
  }
}

export function setTxConfirming(txConfirming) {
  return {
    type: accountActionTypes.SET_TX_CONFIRMING,
    payload: txConfirming
  }
}

export function setAccountInfo(accountInfo) {
  return {
    type: accountActionTypes.SET_ACCOUNT_INFO,
    payload: accountInfo
  }
}

export function setPendingStakeAmount(amount) {
  return {
    type: accountActionTypes.SET_PENDING_STAKE_AMOUNT,
    payload: amount
  }
}
