import React from 'react';
import { useSelector } from "react-redux";
import CopyableTx from "src/app/components/Commons/CopyableTx";

export default function WithdrawSuccessModal(props) {
  const { lastTx } = useSelector((state) => state.account);

  return (
    <div className="modal__withdraw modal-interact">
      <div className="modal__tx-done">
        <div className="modal-content">
          <div className="title">Done!</div>
          <div className="ins">You have successfully withdrawn your stake.</div>
          <CopyableTx txHash={lastTx.hash} />
        </div>
        <div className="panel">
          <div className="staking__button common__button" onClick={props.close}>Done</div>
        </div>
      </div>
    </div>
  )
}
