export const txActionTypes = {
  APPEND_TX: 'TX.APPEND_TX',
  UPDATE_TXS: 'TX.UPDATE_TXS',
  UPDATE_TX: 'TX.UPDATE_TX',
  UPDATE_TX_READ: 'TX.UPDATE_TX_READ',
  MARK_ALL_TX_READ: 'TX.MARK_ALL_TX_READ',
};

export function appendTx(txHash, type, amount, delegatedAddress) {
  return {
    type: txActionTypes.APPEND_TX,
    payload: { txHash, type, amount, delegatedAddress }
  }
}

export function updateTxsStatus() {
  return {
    type: txActionTypes.UPDATE_TXS,
  }
}

export function updateTxStatus(txHash, status) {
  return {
    type: txActionTypes.UPDATE_TX,
    payload: { txHash, status }
  }
}

export function updateTxRead(txHash, isRead) {
  return {
    type: txActionTypes.UPDATE_TX_READ,
    payload: { txHash, isRead }
  }
}

export function markAllTxRead() {
  return {
    type: txActionTypes.MARK_ALL_TX_READ,
  }
}