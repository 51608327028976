import React from "react";
import img from "src/assets/images/icons/arrow-back-white.svg";
import IcGoBack from "src/assets/icons/IcGoBack";
export default function PageModal(props) {
  return (
    <div className="modal--page">
      <div className="modal--page__header">
        <IcGoBack onClick={props.close} className="mr-2" />
        {props.title}
      </div>
      <div className="modal--page__content">{props.content}</div>
    </div>
  );
}
