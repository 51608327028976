import React from "react";

export default function ProposalMessage(props) {
  return (
    <>
      {!!props.message && (
        <div className="text-center mb-3 common__slide-up">
          <div
            className={`common__message-block common__message-block--${props.type}`}
            dangerouslySetInnerHTML={{ __html: props.message }}
          />
        </div>
      )}
    </>
  );
}
