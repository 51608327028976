export const queueActionTypes = {
    QUEUE: 'QUEUE.SUBMIT',
    SET_QUEUE_ERROR: 'QUEUE.SET_QUEUE_ERROR',
  };
  
  export function queue(campID) {
    return {
      type: queueActionTypes.QUEUE,
      payload: { campID }
    }
  }
  
  export function setQueueError(error) {
    return {
      type: queueActionTypes.SET_QUEUE_ERROR,
      payload: error
    }
  }
  