import BigNumber from "bignumber.js";
import Web3 from "web3";
import { call, put, select, takeLatest, delay } from "redux-saga/effects";
import { rewardActionTypes, setRewardError, setRewards } from "src/app/actions/rewardAction";
import { setLastTx, setTxConfirming } from "src/app/actions/accountAction";
import { fetchUserRewards } from 'src/app/services/apis/daoService'
import Web3Service from "src/app/services/web3/Web3Service";
import { ACTIONS } from "src/app/configs/constants";

const getAccountState = (state) => state.account;
const getGlobalState = (state) => state.global;

function* fetchAccountRewards(action) {
  try {
    const { address } = yield select(getAccountState);
    if(!address) return;
    const addressParam = action.payload;
    const web3Service = new Web3Service();

    const merkleData = yield call(web3Service.fetchMerkleData);
    let cycle;
    let merkleDataFileUrl;

    if (merkleData && merkleData[0] && merkleData[0][0]) {
      cycle = parseInt(merkleData[0][0].toString())
    }

    if (!cycle) {
      throw new Error('Cycle invalid');
    }

    if (merkleData && merkleData[0] && merkleData[0][2]) {
      merkleDataFileUrl = merkleData[0][2];
    }

    if (!merkleDataFileUrl) {
      throw new Error('No merkle data file');
    }

    const result = yield call(fetchUserRewards, merkleDataFileUrl, Web3.utils.toChecksumAddress(addressParam));

    // Check if merkle data has record of current address
    if (!result || !result.tokens || !result.cumulativeAmounts) {
      result.cycle = cycle;
      result.cumulativeAmounts = ['0'];
      result.remainingCumulativeAmounts = ['0'];

      return yield put(
        setRewards(result)
      );
    }

    const claimedRewardAmounts = yield call(web3Service.fetchClaimedRewardAmounts, addressParam, result.tokens);

    const remainingCumulativeAmounts = result.tokens.map(
      (_, index) => {
        const cummulativeAmount = result && result.cumulativeAmounts && result.cumulativeAmounts[index];

        if (!cummulativeAmount) {
          return '0';
        }

        const claimedAmount = claimedRewardAmounts[index] || 0;

        return new BigNumber(cummulativeAmount).minus(claimedAmount).toString();
      }
    );

    result.cycle = cycle;
    result.remainingCumulativeAmounts = remainingCumulativeAmounts;

    yield put(
      setRewards(result)
    );
  } catch (e) {
    console.error(e)
    yield delay(2000);
    yield call(fetchAccountRewards, action);
  }
}

function* claimRewards(action) {
  const {
    cycle,
    index,
    tokens,
    cumulativeAmounts,
    merkleProof,
  } = action.payload

  const { address, wallet, lastTx, privateKey, devicePath } = yield select(getAccountState);
  const { gasPrices, selectedGasPrice, estimatedGas } = yield select(getGlobalState);

  yield put(setRewardError(''));
  yield put(setTxConfirming(true));

  try {
    const web3Service = new Web3Service();

    const isValidClaim = yield call(
      web3Service.isValidClaim,
      cycle,
      index,
      address,
      tokens,
      cumulativeAmounts,
      merkleProof
    );

    if (!isValidClaim) {
      throw new Error('Invalid claim');
    }

    const method = 'getClaimRewardsTxObject';

    const nonce = yield call(web3Service.fetchUsableNonce, address, lastTx.nonce);
    const txObject = web3Service[method](address, {
      cycle,
      index,
      tokens,
      cumulativeAmounts,
      merkleProof,
    }, nonce, gasPrices[selectedGasPrice], estimatedGas);
    const txHash = yield call(wallet.makeTransaction, txObject, privateKey, devicePath);

    yield put(setLastTx(txHash, nonce, ACTIONS.CLAIM_REWARDS));
  } catch (e) {
    console.error(e);
    yield put(setRewardError(typeof e === 'string' ? e : e.message));
  }

  yield put(setTxConfirming(false));
}

export default function* rewardWatcher() {
  yield takeLatest(rewardActionTypes.FETCH_ACCOUNT_REWARDS, fetchAccountRewards);
  yield takeLatest(rewardActionTypes.CLAIM_REWARDS, claimRewards);
}
