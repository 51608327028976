import { call, put, select, takeLatest } from "redux-saga/effects";
import { stakeActionTypes, setStakeError } from "src/app/actions/stakeAction";
import { setLastTx, setTxConfirming } from "src/app/actions/accountAction";
import Web3Service from "src/app/services/web3/Web3Service";
import { ACTIONS } from "src/app/configs/constants";

const getAccountState = (state) => state.account;

function* stake(action) {
  const { address, wallet, lastTx, privateKey, devicePath } = yield select(getAccountState);
  const { stakingAmount, gasPrice, gas } = action.payload;

  yield put(setStakeError(''));
  yield put(setTxConfirming(true));

  try {
    const web3Service = new Web3Service();
    const nonce = yield call(web3Service.fetchUsableNonce, address, lastTx.nonce);
    const stakeTxObject = web3Service.getStakeTxObject(address, stakingAmount, nonce, gasPrice, gas);
    const txHash = yield call(wallet.makeTransaction, stakeTxObject, privateKey, devicePath);

    yield put(setLastTx(txHash, nonce, ACTIONS.STAKE, stakingAmount));
  } catch(e) {
    yield put(setStakeError(typeof e === 'string' ? e : e.message));
  }

  yield put(setTxConfirming(false));
}

export default function* stakeWatcher() {
  yield takeLatest(stakeActionTypes.STAKE, stake);
}
