import { txActionTypes } from "src/app/actions/txAction";
import { TX_STATUS } from "src/app/configs/constants";
import { accountActionTypes } from "src/app/actions/accountAction";

const initialState = {
  data: [],
};

export default function txReducer(state = initialState, action) {
  switch (action.type) {
    case txActionTypes.APPEND_TX: {
      const { txHash, type, amount, delegatedAddress } = action.payload;
      const txData = {
        hash: txHash,
        amount: amount,
        delegatedAddress: delegatedAddress,
        type: type,
        status: TX_STATUS.PENDING,
        read: 0,
        timestamp: Date.now(),
      };
      return {
        ...state,
        data: prependItem(state.data, txData),
      };
    }

    case txActionTypes.UPDATE_TX: {
      const { txHash, status } = action.payload;
      let newData = updateTxsArray(state.data, txHash, "status", status);

      return {
        ...state,
        data: newData,
      };
    }

    case txActionTypes.UPDATE_TX_READ: {
      const { txHash, isRead } = action.payload;
      let newData = updateTxsArray(state.data, txHash, "read", isRead);

      return {
        ...state,
        data: newData,
      };
    }

    case txActionTypes.MARK_ALL_TX_READ: {
      let newData = state.data.map(tx => {
        tx.read = 1;
        return tx;
      })

      return {
        ...state,
        data: newData,
      };
    }

    case accountActionTypes.CLEAR_ACCOUNT: {
      return { ...initialState };
    }

    default:
      return state;
  }
}

function prependItem(array, data) {
  let index = 0;
  return [...array.slice(0, index), data, ...array.slice(index)];
}

function updateTxsArray(array, txHash, key, value) {
  return array.map((item) => {
    if (item.hash !== txHash) {
      return item;
    }
    item[key] = value;
    return { ...item };
  });
}
