import React, { useEffect, useMemo, useState } from "react";
import { displayFormattedNumber, formatAddress, formatBigNumber, formatPercentage } from "src/app/utils/fortmaters";
import { LIMIT, PAGING, PROPOSAL_STATUSES } from "src/app/configs/constants";
import { modal } from "src/app/components/Modals/Modal";
import BasicModal from "src/app/components/Modals/BasicModal";
import trophyIcon from "src/assets/images/icons/trophy.svg";
import viewMore from "src/assets/images/icons/view-more.svg";
import { fetchCampaignById } from "src/app/services/apis/daoService";
import { PROPOSAL_TYPES } from "src/app/configs/constants";
import Loading from "src/app/components/Commons/Loading";

export default function ProposalOptions({
  proposal,
  alreadyVotedOption,
  selectedOption,
  percentageOptions,
  onSelect,
  showAllAddress = false,
}) {
  const [winningOption, setWinningOption] = useState(null);
  const [isFetchingVoters, setIsFetchingVoters] = useState(false);
  const [chosenOption, setChosenOption] = useState(null);

  useEffect(() => {
    if (proposal.status === PROPOSAL_STATUSES.EXECUTED) {
      let percentageOptionsMap = [...percentageOptions].sort((a, b) => b - a);
      let winningIndex = percentageOptions.findIndex((v) => v === percentageOptionsMap[0]);
      setWinningOption(winningIndex);
    }
  }, [proposal, percentageOptions]);

  useEffect(() => {
    if (
      proposal.proposal_type === PROPOSAL_TYPES.GENERIC &&
      !alreadyVotedOption &&
      proposal.status !== PROPOSAL_STATUSES.ACTIVE &&
      proposal.status !== PROPOSAL_STATUSES.UPCOMING
    ) {
      const defaultCheckedOption = proposal.options.filter((option) => option.toLocaleLowerCase() !== "none");
      onSelect(defaultCheckedOption.map((o, i) => i));
    }
  }, [proposal, alreadyVotedOption]);

  useEffect(() => {
    if (!isFetchingVoters || chosenOption === false) return;
    async function fetchVoters() {
      let foundProposal = await fetchCampaignById(proposal.proposal_id);
      const voters = foundProposal ? foundProposal.vote_stats.votes : null;
      setTimeout(() => {
        setIsFetchingVoters(false);
        openViewAllVoter(chosenOption, voters);
      }, 1000);
    }

    fetchVoters();
  }, [isFetchingVoters, chosenOption]); // eslint-disable-line

  function renderVotersByOption(option, voters, limit = PAGING.ADDRESS_DISPLAY) {
    voters = !voters ? proposal.vote_stats.votes : voters;
    let stakerAddresses = [];
    let count = 0;

    stakerAddresses.push(
      <div className="proposal__address-item" key={option}>
        <div className="font-light">ADDRESS</div>
        <div className="font-light">VOTE</div>
      </div>
    );

    if (!showAllAddress && !isFetchingVoters) {
      stakerAddresses.push(
        <div className="common__flex-horizon-center mt-2" key={`${option}-loading`}>
          <Loading />
        </div>
      );

      setIsFetchingVoters(true);
      setChosenOption(option);

      return stakerAddresses;
    }

    for (let i = 0; i < voters.length; i++) {
      if (voters[i].option !== option) continue;

      const stakerName = voters[i].staker_name;
      const stakerAddress = voters[i].staker;

      if (limit && count > limit) {
        break;
      } else if (limit && count === limit) {
        stakerAddresses.push(
          <div key={stakerAddress} className="common__link text-right mt-3" onClick={() => openViewAllVoter(option)}>
            View More
          </div>
        );
      } else {
        stakerAddresses.push(
          <div className="proposal__address-item" key={stakerAddress}>
            <div className="proposal__address-name">{stakerName ? stakerName : formatAddress(stakerAddress)}</div>
            <div>{displayFormattedNumber(formatBigNumber(voters[i].power))}</div>
          </div>
        );
      }

      count++;
    }

    if (count === 0) {
      stakerAddresses.push(
        <div className="common__flex-horizon-center mt-2" key={`${option}-nothing`}>
          <div>No Data</div>
        </div>
      );
    }

    return stakerAddresses;
  }

  function renderHighlightVoters(option) {
    const limit = LIMIT.VOTER_NAME;
    const votes = proposal.vote_stats.votes;

    if (!votes) return "";

    let voterNames = "";
    let count = 0;

    for (let i = 0; i < votes.length; i++) {
      const stakerName = votes[i].staker_name;

      if (votes[i].option !== option || !stakerName) continue;
      if (count >= limit) break;

      voterNames += count === 0 ? stakerName : `, ${stakerName}`;

      count++;
    }

    return voterNames;
  }

  function renderTotalVoteInOption(option) {
    const optionStats = proposal.vote_stats.options.find((item) => {
      return item.option === option;
    });

    if (!optionStats) return 0;

    return displayFormattedNumber(optionStats.vote_count);
  }

  function openViewAllVoter(option, voters) {
    modal.show(BasicModal, {
      title: `Addresses voted in Option ${proposal.opts_desc[option]}`,
      content: (
        <div className="proposal-details proposal__address mt-1">{renderVotersByOption(option, voters, false)}</div>
      ),
    });
  }

  function removeItemOnce(arr, value) {
    var index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  const noneOptionValue = useMemo(() => {
    return proposal.options.findIndex((option, i) => {
      const optionDescription = proposal.opts_desc ? proposal.opts_desc[i] : "";
      const optionString = optionDescription || option;
      return optionString.toLocaleLowerCase() === "none";
    });
  }, [proposal]);

  return (
    <div className="proposal__item-options">
      {proposal.options.map((option, index) => {
        const optionValue = index;
        const isChecked = selectedOption && selectedOption.includes(optionValue);
        const isVoted = alreadyVotedOption && alreadyVotedOption.includes(optionValue);
        const calculatedPercent = percentageOptions[optionValue] || 0;
        const formattedPercent = calculatedPercent !== 0 ? formatPercentage(calculatedPercent) : "0%";
        const optionDescription = proposal.opts_desc ? proposal.opts_desc[index] : null;
        const optionString = optionDescription || option;
        const isNoneOption = optionString.toLocaleLowerCase() === "none";
        const isCheckedClass = isVoted ? "proposal__item-option--checked" : "";
        const isWinningClass = winningOption === optionValue ? "proposal__item-option--winning" : "";
        const isProposalEnded = proposal.status === PROPOSAL_STATUSES.ENDED;
        const highlightVoters = renderHighlightVoters(optionValue);

        return (
          <div key={index} className={`proposal__item-option ${isCheckedClass} ${isWinningClass}`}>
            <div className={`proposal__item-block ${isProposalEnded ? "disabled" : ""}`}>
              <label className={`common__option`}>
                <div className="font-sm">
                  <div className="proposal__item-progress" style={{ width: `${calculatedPercent}%` }}></div>
                  <div className="common__flex position-relative">
                    <div className="font-weight-medium">{optionString}</div>
                    <div>{formattedPercent}</div>
                  </div>
                </div>
                <input
                  className="common__option-input"
                  type="checkbox"
                  name={proposal.proposal_id}
                  onChange={() => {
                    if (proposal.proposal_type === PROPOSAL_TYPES.GENERIC && !isNoneOption) {
                      if (!isChecked || selectedOption.length > 1) {
                        const options = !!selectedOption ? removeItemOnce(selectedOption, noneOptionValue) : [];
                        onSelect(!isChecked ? [...options, optionValue] : [...removeItemOnce(options, optionValue)]);
                      }
                    } else {
                      onSelect([optionValue]);
                    }
                  }}
                  checked={isChecked}
                />
                <span
                  className={`common__option-${proposal.proposal_type === PROPOSAL_TYPES.BINARY ? "radio" : "check"}`}
                />
              </label>
            </div>

            {!!calculatedPercent && !showAllAddress && (
              <div className="proposal__item-bot">
                <div className="proposal__item-name">{highlightVoters}</div>
                <img
                  className="proposal__item-view"
                  src={viewMore}
                  alt="View More"
                  onClick={() => openViewAllVoter(optionValue)}
                />
              </div>
            )}

            {!!calculatedPercent && showAllAddress && (
              <div className="proposal__address">
                <div className="proposal__address-recap">
                  <div className="font-bold">
                    {renderTotalVoteInOption(optionValue)}
                    {winningOption === optionValue && (
                      <img className="ml-2 align-bottom" src={trophyIcon} alt="trophyIcon" />
                    )}
                  </div>
                  <div className="font-light">{formattedPercent}</div>
                </div>
                <div>{renderVotersByOption(optionValue)}</div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}
