export const withdrawActionTypes = {
  WITHDRAW: 'WITHDRAW.WITHDRAW',
  SET_ERROR: 'WITHDRAW.SET_ERROR'
};

export function withdraw(amount) {
  return {
    type: withdrawActionTypes.WITHDRAW,
    payload: { amount }
  }
}

export function setWithdrawError(error) {
  return {
    type: withdrawActionTypes.SET_ERROR,
    payload: error
  }
}
