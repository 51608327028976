import { approveActionTypes } from "src/app/actions/approveAction";

const initialState = {
  approveError: ''
};

export default function approveReducer(state = initialState, action) {
  switch (action.type) {
    case approveActionTypes.SET_ERROR: {
      return {
        ...state,
        approveError: action.payload
      }
    }
    default:
      return state
  }
}