import React from "react";

export default function LogoKyberDAO(props) {
  return (
    <svg width="125" height="44" viewBox="0 0 125 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.4893 22.0414L29.4023 31.1677C29.5296 31.2396 29.6734 31.2772 29.8196 31.2768C29.9658 31.2764 30.1094 31.238 30.2363 31.1654C30.3632 31.0927 30.469 30.9883 30.5434 30.8624C30.6177 30.7365 30.6581 30.5934 30.6604 30.4472L30.6632 13.6411C30.6615 13.4946 30.6217 13.3511 30.5475 13.2248C30.4734 13.0985 30.3675 12.9937 30.2404 12.9209C30.1133 12.848 29.9694 12.8097 29.8229 12.8096C29.6764 12.8095 29.5325 12.8476 29.4053 12.9203L13.4893 22.0414Z"
        fill="#31CB9E"
      />
      <path
        d="M28.4777 8.98733L17.9141 0.912295C17.8014 0.827632 17.6693 0.772511 17.5298 0.751954C17.3904 0.731396 17.248 0.746055 17.1156 0.794595C16.9833 0.843135 16.8652 0.924014 16.7721 1.02986C16.679 1.13571 16.6139 1.26317 16.5827 1.40063L12.7202 19.3787L28.3919 10.3797C28.5131 10.3119 28.6154 10.2148 28.6893 10.0973C28.7632 9.97973 28.8064 9.8455 28.8149 9.70691C28.8235 9.56832 28.797 9.42981 28.7381 9.30408C28.6792 9.17836 28.5896 9.06944 28.4777 8.98733Z"
        fill="#31CB9E"
      />
      <path
        d="M17.9071 43.1841L28.4977 35.1003C28.6072 35.0166 28.6945 34.9073 28.7517 34.7819C28.809 34.6565 28.8345 34.519 28.8261 34.3814C28.8177 34.2439 28.7755 34.1105 28.7034 33.993C28.6313 33.8756 28.5314 33.7777 28.4125 33.708L12.7192 24.7161L16.5881 42.6954C16.6164 42.8332 16.6794 42.9616 16.771 43.0683C16.8627 43.1751 16.98 43.2568 17.112 43.3057C17.2439 43.3546 17.3862 43.3691 17.5253 43.3478C17.6644 43.3266 17.7958 43.2702 17.9071 43.1841Z"
        fill="#31CB9E"
      />
      <path
        d="M9.4098 22.0407L13.5778 2.67035C13.614 2.50257 13.5976 2.32771 13.5307 2.16963C13.4639 2.01156 13.3499 1.87796 13.2043 1.78709C13.0586 1.69622 12.8885 1.6525 12.7172 1.6619C12.5458 1.67129 12.3815 1.73335 12.2467 1.8396L1.58273 9.98445C1.31256 10.1911 1.09371 10.4572 0.943254 10.7623C0.792793 11.0673 0.714775 11.403 0.715273 11.7431L0.711918 32.3721C0.711309 32.7123 0.789219 33.0479 0.939581 33.353C1.08994 33.6581 1.3087 33.9244 1.57881 34.1311L12.2401 42.2671C12.3759 42.369 12.5392 42.4274 12.7088 42.4347C12.8784 42.442 13.0462 42.3978 13.1902 42.308C13.3342 42.2182 13.4477 42.0869 13.5157 41.9315C13.5838 41.776 13.6032 41.6036 13.5715 41.4368L9.4098 22.0407Z"
        fill="#31CB9E"
      />
      <path
        d="M93.0311 16.5932C93.9312 16.5814 94.8266 16.7262 95.677 17.0213C96.4253 17.2819 97.1132 17.6911 97.6992 18.2244C98.2579 18.7421 98.6985 19.3742 98.9907 20.0777C99.5961 21.5996 99.5958 23.2956 98.9897 24.8173C98.695 25.5245 98.2548 26.1618 97.6977 26.6878C97.1146 27.2316 96.4262 27.65 95.675 27.917C94.8259 28.2181 93.93 28.3658 93.0291 28.3531L89.3589 28.3526L89.3609 16.5927L93.0311 16.5932ZM91.2488 18.3034L91.2474 26.6601L93.0116 26.6603C93.6489 26.6693 94.2826 26.5639 94.8827 26.3492C95.4086 26.1615 95.8885 25.8639 96.2904 25.4763C96.674 25.0968 96.9744 24.6417 97.1726 24.1398C97.5886 23.0467 97.5888 21.8388 97.1731 20.7456C96.9763 20.251 96.6756 19.8043 96.2914 19.4358C95.8874 19.0593 95.4074 18.7736 94.8837 18.5981C94.2813 18.3949 93.6488 18.2953 93.0132 18.3036L91.2488 18.3034Z"
        fill="#141927"
      />
      <path
        d="M111.31 28.356L109.279 28.3558L108.441 26.3955L103.149 26.3948L102.312 28.3545L100.298 28.3543L105.485 16.3994H106.127L111.31 28.356ZM103.827 24.7733L107.747 24.7741L105.806 19.9096L103.827 24.7733Z"
        fill="#141927"
      />
      <path
        d="M118.332 16.4015C119.157 16.3958 119.974 16.5533 120.738 16.8651C122.213 17.4637 123.394 18.6194 124.024 20.082C124.348 20.8427 124.514 21.6609 124.514 22.4875C124.514 23.3142 124.347 24.1323 124.023 24.8929C123.711 25.615 123.26 26.2687 122.695 26.817C122.129 27.3626 121.464 27.7949 120.736 28.0904C119.972 28.402 119.155 28.5593 118.33 28.5534C117.503 28.5585 116.682 28.401 115.916 28.0896C115.184 27.7957 114.518 27.3596 113.956 26.8065C113.399 26.2525 112.954 25.5959 112.647 24.8729C112.165 23.7491 112.034 22.5062 112.269 21.3064C112.504 20.1066 113.096 19.0056 113.966 18.1469C114.531 17.5967 115.197 17.1612 115.927 16.8643C116.69 16.5528 117.508 16.3955 118.332 16.4015ZM118.349 18.1299C117.772 18.1243 117.199 18.2364 116.666 18.4593C116.161 18.6671 115.704 18.9757 115.322 19.3662C114.941 19.7567 114.643 20.2209 114.447 20.7305C114.23 21.2807 114.121 21.8675 114.126 22.4589C114.12 23.0561 114.229 23.6489 114.446 24.205C114.647 24.7189 114.947 25.1883 115.328 25.5863C115.708 25.9761 116.162 26.2852 116.664 26.4951C117.197 26.7183 117.77 26.8305 118.348 26.825C118.907 26.8321 119.463 26.7261 119.98 26.5135C120.498 26.3008 120.967 25.9858 121.36 25.5873C121.746 25.1912 122.049 24.7216 122.251 24.2062C122.469 23.6503 122.578 23.0575 122.572 22.4604C122.577 21.8718 122.468 21.2877 122.251 20.7407C122.05 20.2314 121.75 19.7674 121.368 19.3757C120.985 18.984 120.529 18.6726 120.024 18.4598C119.495 18.2362 118.924 18.124 118.349 18.1299Z"
        fill="#141927"
      />
      <path
        d="M44.5994 28.3456L42.7109 28.3453L42.7129 16.5854L44.6014 16.5857L44.5994 28.3456ZM47.006 22.0918L52.4395 28.3468L50.1052 28.3463L44.6538 22.0915L49.5906 16.5865L51.9069 16.587L47.006 22.0918Z"
        fill="#141927"
      />
      <path
        d="M56.5912 29.3454C56.3158 30.0622 55.8581 30.6947 55.2632 31.1803C54.7095 31.5718 54.0431 31.7719 53.3653 31.7503C53.1211 31.7495 52.8772 31.7346 52.6347 31.7056C52.3944 31.6774 52.1563 31.6327 51.9221 31.572V29.9862C52.1361 30.0096 52.3262 30.0274 52.4926 30.0396C52.6587 30.0513 52.8189 30.0572 52.9733 30.0573C53.3478 30.0645 53.7181 29.9789 54.0515 29.8082C54.4326 29.5628 54.7237 29.2002 54.8807 28.7749L55.2368 27.9554L51.7461 19.5979L53.7592 19.5984L56.2531 25.9776L58.8557 19.5992L60.8508 19.5994L56.5912 29.3454Z"
        fill="#141927"
      />
      <path
        d="M63.7011 20.669C64.0445 20.2692 64.4742 19.9525 64.9577 19.7427C65.4828 19.5129 66.0507 19.3976 66.6238 19.4043C67.1989 19.3979 67.769 19.511 68.298 19.7365C68.8271 19.962 69.3035 20.295 69.6971 20.7143C70.0894 21.1348 70.3951 21.6284 70.5967 22.167C70.8144 22.7479 70.9229 23.364 70.9169 23.9844C70.9234 24.6019 70.8146 25.2152 70.5962 25.7928C70.3939 26.3263 70.0914 26.8161 69.7051 27.2359C69.3243 27.646 68.8633 27.9734 68.3507 28.1979C67.8114 28.4329 67.2285 28.5512 66.6403 28.545C66.0232 28.5526 65.4119 28.4247 64.8495 28.1705C64.3297 27.9381 63.883 27.5685 63.5575 27.1013V28.3485L61.8296 28.3482L61.8316 14.9846L63.7021 14.9849L63.7011 20.669ZM66.3206 21.1148C65.9895 21.1146 65.6608 21.1718 65.3492 21.2838C65.0333 21.3976 64.745 21.5767 64.5029 21.8094C64.244 22.0614 64.0406 22.3649 63.906 22.7003C63.7479 23.109 63.6722 23.545 63.6831 23.9831C63.6724 24.4249 63.7511 24.8643 63.9145 25.2749C64.051 25.6115 64.2576 25.9153 64.5204 26.166C64.7628 26.3937 65.0512 26.5669 65.3661 26.6739C65.6729 26.7796 65.9951 26.8339 66.3196 26.8345C66.684 26.8376 67.045 26.7648 67.3798 26.6208C67.6986 26.4836 67.9866 26.2837 68.2265 26.0329C68.4707 25.7726 68.6614 25.467 68.788 25.1333C69.0604 24.3792 69.0606 23.5535 68.7885 22.7993C68.661 22.4638 68.467 22.1576 68.218 21.8992C67.9752 21.6501 67.6843 21.453 67.3628 21.32C67.0326 21.1826 66.6782 21.1128 66.3206 21.1148V21.1148Z"
        fill="#141927"
      />
      <path
        d="M76.6911 19.4062C77.2461 19.4011 77.7967 19.5041 78.3123 19.7094C78.7917 19.8969 79.2274 20.1811 79.5921 20.5444C79.9569 20.9078 80.2428 21.3423 80.4321 21.821C80.6369 22.3275 80.7397 22.8695 80.7348 23.4158C80.7353 23.6422 80.7203 23.8685 80.69 24.0929C80.6693 24.2613 80.6366 24.4279 80.5922 24.5916L74.0706 24.5906C74.167 25.2559 74.5107 25.8604 75.033 26.2836C75.5735 26.6977 76.2406 26.9118 76.921 26.8897C77.3985 26.8966 77.8721 26.8026 78.3108 26.6137C78.7348 26.4244 79.126 26.1689 79.4697 25.8567L80.3778 27.1398C79.921 27.5749 79.39 27.9248 78.81 28.1728C78.2008 28.4276 77.5459 28.5549 76.8856 28.5468C76.2396 28.5524 75.5984 28.4345 74.9966 28.1992C74.4416 27.9859 73.9358 27.6618 73.5101 27.2466C73.0844 26.8314 72.7478 26.3338 72.5207 25.7842C72.2807 25.2149 72.1594 24.6026 72.1642 23.9847C72.1595 23.3677 72.2779 22.7559 72.5123 22.185C72.7335 21.6421 73.0605 21.1486 73.4742 20.7332C73.8868 20.3217 74.3736 19.9923 74.9089 19.7622C75.4719 19.5214 76.0788 19.4002 76.6911 19.4062ZM76.6368 20.992C76.0378 20.9768 75.4542 21.1829 74.9976 21.5709C74.5348 21.9763 74.2212 22.5252 74.107 23.1298L78.8643 23.1305C78.8711 22.8323 78.8133 22.5362 78.6949 22.2624C78.5764 21.9886 78.4001 21.7437 78.1781 21.5445C77.7493 21.1779 77.2009 20.9814 76.6368 20.992H76.6368Z"
        fill="#141927"
      />
      <path
        d="M86.8117 19.5145C86.9458 19.5133 87.0798 19.5223 87.2126 19.5415C87.3253 19.5592 87.4292 19.5799 87.5243 19.6038L87.5238 21.2609L86.9538 21.2606C86.6183 21.2592 86.2846 21.3103 85.9649 21.4122C85.6633 21.5055 85.3842 21.6601 85.1451 21.8663C84.908 22.0754 84.7194 22.3339 84.5926 22.6235C84.4497 22.9583 84.3796 23.3197 84.3871 23.6837L84.3866 28.352L82.5161 28.3517L82.5176 19.603L84.2455 19.6033L84.245 20.7794C84.539 20.3764 84.9278 20.052 85.377 19.8349C85.8248 19.6209 86.3153 19.5113 86.8117 19.5145Z"
        fill="#141927"
      />
    </svg>
  );
}
