import React, { useEffect } from "react";
import useComponentVisible from "src/app/components/Hooks/useComponentVisible";
import usePrevious from "src/app/components/Hooks/usePrevious";

export default function DropDown(props) {
  const { ref, isOpen, setIsOpen } = useComponentVisible(false);
  const prevIsOpen = usePrevious(isOpen);
  
  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      props.onClose && props.onClose()
    }
  }, [props, prevIsOpen, isOpen]);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <div className={`common__dropdown ${props.className ? props.className : ''} ${isOpen ? "active" : ""}`} ref={ref}>
      <span className="common__dropdown-title" onClick={toggleOpen}>{props.title}</span>
      <div className="common__dropdown-wrapper">
        <div className="common__dropdown-content">{props.content}</div>
      </div>
    </div>
  );
}
