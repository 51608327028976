import { call, put, select, takeLatest } from "redux-saga/effects";
import { migrateActionTypes, setMigrateError } from "src/app/actions/migrateAction";
import { setLastTx, setTxConfirming } from "src/app/actions/accountAction";
import Web3Service from "src/app/services/web3/Web3Service";
import { ACTIONS } from "src/app/configs/constants";

const getAccountState = (state) => state.account;

function* migrate(action) {
  const { address, wallet, lastTx, privateKey, devicePath } = yield select(getAccountState);
  const { migrateAmount, gasPrice, gas } = action.payload;

  yield put(setMigrateError(''));
  yield put(setTxConfirming(true));

  try {
    const web3Service = new Web3Service();
    const nonce = yield call(web3Service.fetchUsableNonce, address, lastTx.nonce);
    const migrateTxObject = web3Service.getMigrateTxObject(address, migrateAmount, nonce, gasPrice, gas);
    const txHash = yield call(wallet.makeTransaction, migrateTxObject, privateKey, devicePath);

    yield put(setLastTx(txHash, nonce, ACTIONS.MIGRATE, migrateAmount));
  } catch(e) {
    yield put(setMigrateError(typeof e === 'string' ? e : e.message));
  }

  yield put(setTxConfirming(false));
}

export default function* migrateWatcher() {
  yield takeLatest(migrateActionTypes.MIGRATE, migrate);
}
