import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modal } from "src/app/components/Modals/Modal";
import VoteConfirmModal from "src/app/components/Modals/Vote/VoteConfirmModal";
import VoteSuccessModal from "src/app/components/Modals/Vote/VoteSuccessModal";
import BroadcastModal from "src/app/components/Modals/BroadcastModal";
import { ACTIONS, TOPICS } from "src/app/configs/constants";
import txService from "src/app/services/txService";
import { setProposalMessage } from "src/app/actions/campaignAction";

export default function VotingContainer({voteInfo, clear}) {
  const dispatch = useDispatch();
  const { lastTx } = useSelector((state) => state.account);
  const { votes } = useSelector(state => state.vote);

  const [step, setStep] = useState(0);
  const isSendingTx = lastTx.type === ACTIONS.VOTE;
  const modals = [
    () => modal.show(VoteConfirmModal, { ...voteInfo }, closeModal),
    () => modal.show(BroadcastModal, { txHash: lastTx.hash, topic: TOPICS.VOTE, nextStep: nextStep }, closeModal),
    () => modal.show(VoteSuccessModal, { txHash: lastTx.hash }, closeModal)
  ];

  const requireVoteInfo = (fn) => {
    if (Object.keys( voteInfo ).length > 0) {
      fn()
    }
  };

  useEffect(() => {
    if (!isSendingTx) return;
    nextStep();
  }, [lastTx] ); // eslint-disable-line

  useEffect(() => {
    requireVoteInfo(() => {
      if (step === 1) {
        txService.handleSuccess(lastTx.hash, updateVotes);
      }

      if (typeof modals[step] === 'function') modals[step]();
    })
  }, [step, voteInfo] ); // eslint-disable-line

  function closeModal() {
    setStep(0);
    clear();
  }

  function nextStep() {
    requireVoteInfo(() => setStep(v => v + 1 ));
  }

  function updateVotes() {
    let voteFound = false;

    for (let i = 0; i < votes.length; i++) {
      if (votes[i].proposal_id === voteInfo.campID) {
        voteFound = true;
        break;
      }
    }

    if (voteFound) return;

    dispatch(setProposalMessage({
      id: voteInfo.campID,
      value: 'Your vote has been casted successfully. Please wait for a while until your vote is fully recognized in the network.'
    }));

    setTimeout(() => {
      dispatch(setProposalMessage(null));
    }, 15000);
  }

  return <></>
}
