import React from "react";

export default function IcMigrate(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.11331 14C6.87998 14 6.69998 13.7933 6.73331 13.56L7.33331 9.33333H4.99998C4.41331 9.33333 4.77998 8.83333 4.79331 8.81333C5.63331 7.32667 6.89331 5.12667 8.55998 2.19333C8.62665 2.07333 8.75998 2 8.89331 2C9.12665 2 9.30665 2.20667 9.27331 2.44L8.67331 6.66667H11.0133C11.28 6.66667 11.4266 6.79333 11.28 7.10667C9.08665 10.9333 7.81331 13.1667 7.44665 13.8067C7.37998 13.9267 7.25331 14 7.11331 14Z"
        fill="#868787"
      />
    </svg>
  );
}
