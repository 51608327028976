import { rewardActionTypes } from "src/app/actions/rewardAction";

const initialState = {
  rewardError: '',
  rewards: {
    cycle: 0,
    startEpoch: 0,
    endEpoch: 0,
    index: 0,
    tokens: [],
    cumulativeAmounts: [],
    remainingCumulativeAmounts: [],
    merkleRoot: '',
    merkleProof: []
  },
};

export default function rewardReducer(state = initialState, action) {
  switch (action.type) {
    case rewardActionTypes.SET_ERROR: {
      return {
        ...state,
        rewardError: action.payload
      }
    }
    case rewardActionTypes.SET_REWARDS: {
      const { rewards } = action.payload;

      return {
        ...state,
        rewards: {
          cycle: rewards.cycle,
          startEpoch: rewards.startEpoch,
          endEpoch: rewards.endEpoch,
          index: rewards.index,
          tokens: rewards.tokens,
          cumulativeAmounts: rewards.cumulativeAmounts,
          remainingCumulativeAmounts: rewards.remainingCumulativeAmounts,
          merkleRoot: rewards.merkleRoot,
          merkleProof: rewards.proof
        } || initialState.rewards,
      }
    }
    case rewardActionTypes.RESET_REWARDS: {
      return initialState
    }
    default:
      return state
  }
}
