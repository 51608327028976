import ENV from "./env";

export const routes = {
  vote: "/vote",
  learn: "/learn",
  proposal: {
    path: "/proposal/:proposalId",
    value: "/proposal",
  },
  reward: "/reward",
  history: "/history",
  delegation: "/delegation",
  faq: "/faq",
  about: "/about",
  report: "/report",
  migrate: "/migrate",
  v1: ENV.URLS.DAOV1
};
