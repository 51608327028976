import React, { useState, useEffect } from 'react';
import SlideDown, { SlideDownContent, SlideDownTrigger } from "src/app/components/Commons/SlideDown";

export default function FaqItem(props) {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (props.isOpened) setIsActive(true);
  },[props.isOpened]);

  function toggleItem() {
    if (isActive) closeOpenProp();
    setIsActive(!isActive);
  }

  function closeItem() {
    setIsActive(false);
    closeOpenProp();
  }

  function closeOpenProp() {
    if (props.setIsOpened) {
      props.setIsOpened(false);
    }
  }

  return (
    <div id={props.id ? props.id : ''}>
      <SlideDown active={isActive}>
        <SlideDownTrigger toggleContent={toggleItem}>
          <div className="faq__item">
            <div className="faq__item-title">{props.title}</div>
          </div>
        </SlideDownTrigger>

        <SlideDownContent close={closeItem}>
          <div className="faq__item-text">
            {props.children}
          </div>
        </SlideDownContent>
      </SlideDown>
    </div>
  )
}
