import React from "react";
import { useSelector } from "react-redux";
import { displayFormattedNumber, formatPercentage } from "src/app/utils/fortmaters";
import { calculatePercent } from "src/app/utils/calculators";
import Loading from "src/app/components/Commons/Loading";
import { PROPOSAL_STATUSES } from "src/app/configs/constants";
import informationIcon from "src/assets/images/icons/information.svg";
import Tooltip from "src/app/components/Commons/Tooltip";

export default function ProposalRecap({ proposal, alreadyVotedOption, votingPower }) {
  const { address, accountInfo } = useSelector(state => state.account);
  const totalVote = proposal.vote_stats.total_vote_count;

  function renderVotingPower() {
    let totalVotingPower = totalVote;
    if (!alreadyVotedOption) totalVotingPower = totalVote + votingPower;

    const votingPowerPercent = formatPercentage(calculatePercent(votingPower, totalVotingPower));

    return ` (${votingPower === 0 ? `0%` : votingPowerPercent})`;
  }

  return (
    <div className="proposal__item-data">
      <div>
        <div className="mb-2">Total addresses</div>
        <div><b>{displayFormattedNumber(proposal.vote_stats.total_address_count) || 0}</b></div>
      </div>
      <div>
        <div className="mb-2">KNC<br/>Amount</div>
        <div><b>{displayFormattedNumber(totalVote) || 0}</b></div>
      </div>
      {(address && proposal.status === PROPOSAL_STATUSES.ACTIVE) && (
        <div>
          <div className="mb-2">
            <span>Your Voting<br/>Power</span>
            <Tooltip
              trigger={<img src={informationIcon} alt="Information"/>}
              content={'Your voting power is determined by the total KNC staked by you and delegated to you in previous Epochs'}
              longText={true}
            />
          </div>
          {accountInfo && (
            <div>
              <b>
                <span>{displayFormattedNumber(votingPower)}</span>
                <span className="proposal__item-power">
                  {renderVotingPower()}
                </span>
              </b>
            </div>
          )}
          {!accountInfo && <Loading/>}
        </div>
      )}
    </div>
  )
}
