import { migrateActionTypes } from "src/app/actions/migrateAction";

const initialState = {
  migrateError: ''
};

export default function migrateReducer(state = initialState, action) {
  switch (action.type) {
    case migrateActionTypes.SET_ERROR: {
      return {
        ...state,
        migrateError: action.payload
      }
    }
    default:
      return state
  }
}