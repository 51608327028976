import React from "react";
import { Route } from "react-router-dom";
import Footer from "src/app/components/Layouts/Footer";
import Header from "src/app/components/Layouts/Header";
import StakeInfo from "src/app/components/Commons/StakeInfo";
import EpochProgress from "src/app/components/Commons/EpochProgress";
import AccountImportModal from "src/app/components/Modals/GlobalModals/AccountImportModal";
import { useState } from "react";

const Announcement = () => {
  const [show, setShow] = useState(true);
  if (!show) return <></>;
  return (
    <div
      style={{
        backgroundColor: "#ffb84e",
        color: "#222",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "12px",
        fontSize: "16px",
        lineHeight: "20px",
        position: "relative",
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: "6px" }}
      >
        <g clip-path="url(#clip0_1101_1144)">
          <path
            d="M15 10.0001C15 10.4584 15.375 10.8334 15.8333 10.8334H17.5C17.9583 10.8334 18.3333 10.4584 18.3333 10.0001C18.3333 9.54175 17.9583 9.16675 17.5 9.16675H15.8333C15.375 9.16675 15 9.54175 15 10.0001Z"
            fill="#222222"
          />
          <path
            d="M13.825 14.0166C13.55 14.3832 13.625 14.8916 13.9916 15.1582C14.4333 15.4832 14.9 15.8332 15.3416 16.1666C15.7083 16.4416 16.225 16.3666 16.4916 15.9999C16.4916 15.9916 16.5 15.9916 16.5 15.9832C16.775 15.6166 16.7 15.0999 16.3333 14.8332C15.8916 14.4999 15.425 14.1499 14.9916 13.8249C14.625 13.5499 14.1083 13.6332 13.8333 13.9999C13.8333 14.0082 13.825 14.0166 13.825 14.0166Z"
            fill="#222222"
          />
          <path
            d="M16.5083 4.00829C16.5083 3.99996 16.5 3.99996 16.5 3.99162C16.225 3.62496 15.7083 3.54996 15.35 3.82496C14.9083 4.15829 14.4333 4.50829 14 4.84162C13.6333 5.11662 13.5667 5.63329 13.8417 5.99162C13.8417 5.99996 13.85 5.99996 13.85 6.00829C14.125 6.37496 14.6333 6.44996 15 6.17496C15.4417 5.84996 15.9083 5.49162 16.35 5.15829C16.7083 4.89162 16.775 4.37496 16.5083 4.00829Z"
            fill="#222222"
          />
          <path
            d="M6.6665 7.5H3.33317C2.4165 7.5 1.6665 8.25 1.6665 9.16667V10.8333C1.6665 11.75 2.4165 12.5 3.33317 12.5H4.1665V15C4.1665 15.4583 4.5415 15.8333 4.99984 15.8333C5.45817 15.8333 5.83317 15.4583 5.83317 15V12.5H6.6665L10.8332 15V5L6.6665 7.5Z"
            fill="#222222"
          />
          <path
            d="M12.9165 9.99992C12.9165 8.89159 12.4332 7.89159 11.6665 7.20825V12.7833C12.4332 12.1083 12.9165 11.1083 12.9165 9.99992Z"
            fill="#222222"
          />
        </g>
        <defs>
          <clipPath id="clip0_1101_1144">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <span>
        We will be moving all KyberDAO activities to KyberSwap. You can access the new Kyber Governance page by visiting
        this{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://kyberswap.com/kyberdao/stake-knc"
          style={{ fontWeight: 600, textDecoration: "underline" }}
        >
          link
        </a>
      </span>
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ position: "absolute", right: "20px", cursor: "pointer" }}
        onClick={() => setShow(false)}
      >
        <path
          d="M9.3335 9.33325L18.6668 18.6666"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.6668 9.33325L9.3335 18.6666"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};
export default function Layout({ component: Component, ...props }) {
  return (
    <Route
      {...props}
      render={(matchProps) => (
        <>
          <Announcement />
          <Header />
          <div className={`body-wrapper ${props.pageMode ? "body-wrapper--page" : ""}`}>
            <div className={!props.fullWidth && "container"}>
              {!props.pageMode && (
                <>
                  <StakeInfo />
                  <EpochProgress />
                </>
              )}
              <Component {...matchProps} />
            </div>
            <AccountImportModal />
          </div>
          <Footer />
        </>
      )}
    />
  );
}
