import React from "react";
import { routes } from "src/app/configs/routes";
import { Link } from "react-router-dom";
import ProposalRecap from "src/app/components/Proposal/Elements/ProposalRecap";
import ProposalOptions from "src/app/components/Proposal/Elements/ProposalOptions";
import useSelectedOption from "src/app/components/Proposal/Hooks/useSelectedOption";
import { renderProposalTitle } from "src/app/utils/fortmaters";
import ProposalStatus from "src/app/components/Proposal/Elements/ProposalStatus";
import ProposalButtons from "src/app/components/Proposal/Elements/ProposalButtons";
import ProposalMessage from "src/app/components/Proposal/Elements/ProposalMessage";
import useCheckEndedProposal from "./Hooks/useCheckEndedProposal";
import { PROPOSAL_STATUSES } from "src/app/configs/constants";

export default function ProposalItem({ proposal, submit, queue, execute }) {
  const {
    alreadyVotedOption,
    votingPower,
    selectedOption,
    percentageOptions,
    onSelect,
    voteError,
    onSubmit,
    isStaked,
    highestOption,
    message,
  } = useSelectedOption(proposal, submit);

  const isProposalEnded = useCheckEndedProposal(proposal);
  const isEligible = [PROPOSAL_STATUSES.SUCCEEDED, PROPOSAL_STATUSES.FINALIZED].includes(proposal.status);
  const winningOptionIndex = highestOption;
  const optionDescription = winningOptionIndex
    ? winningOptionIndex.map((o) => proposal.opts_desc[o] || o).join(", ")
    : null;

  return (
    <div className="proposal__block">
      <ProposalMessage message={voteError} type="error" />
      <ProposalMessage message={message} type="info" />
      <div className={`proposal__item ${isProposalEnded ? "proposal__item--ended" : ""}`}>
        <div className="proposal__item-info">
          <div className="proposal__item-header">
            <Link to={`${routes.proposal.value}/${proposal.proposal_id}`} className="proposal__item-title">
              {renderProposalTitle(proposal)}
            </Link>
            {isProposalEnded && isEligible && highestOption !== null && (
              <div className="proposal__item-win">{/* Final Result: {optionDescription} */}</div>
            )}
          </div>
          <ProposalStatus proposal={proposal} />
          {proposal.status === PROPOSAL_STATUSES.SUCCEEDED && (
            <div
              className="common__button common__button--sm ml-2 mt-2 mt-lg-0"
              onClick={() => {
                queue({ campID: proposal.proposal_id });
              }}
            >
              Queue
            </div>
          )}
          {/* {proposal.status === PROPOSAL_STATUSES.QUEUED && <div className="common__button common__button--sm ml-2 mt-2 mt-lg-0" onClick={() => { execute({campID: proposal.proposal_id})}}>Execute</div>} */}
          <ProposalRecap proposal={proposal} alreadyVotedOption={alreadyVotedOption} votingPower={votingPower} />
          <div className="text-right">
            <Link to={`${routes.proposal.value}/${proposal.proposal_id}`} className="common__link">
              Details
            </Link>
          </div>
        </div>

        <ProposalOptions
          proposal={proposal}
          alreadyVotedOption={alreadyVotedOption}
          selectedOption={selectedOption}
          percentageOptions={percentageOptions}
          onSelect={onSelect}
        />

        <ProposalButtons
          proposal={proposal}
          alreadyVotedOption={alreadyVotedOption}
          onSubmit={onSubmit}
          isStaked={isStaked}
        />
      </div>
    </div>
  );
}
