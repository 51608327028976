export const voteActionTypes = {
  VOTE: 'VOTE.SUBMIT',
  SET_VOTES: 'VOTE.SET_VOTES',
  SET_VOTE_ERROR: 'VOTE.SET_VOTE_ERROR',
};

export function vote(campID, option) {
  return {
    type: voteActionTypes.VOTE,
    payload: { campID, option }
  }
}

export function setVotes(votes) {
  return {
    type: voteActionTypes.SET_VOTES,
    payload: votes
  }
}

export function setVoteError(error) {
  return {
    type: voteActionTypes.SET_VOTE_ERROR,
    payload: error
  }
}
