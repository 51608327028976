import Web3 from "web3";
import ENV from "src/app/configs/env";
import { fromNetworkIdToName } from "src/app/utils/converters";

export default class BaseWalletService {
  constructor(props = {}) {
    this.needTobeInitiated = false;
    this.address = props ? props.address : null;
    this.ethereum = null;
    this.web3 = new Web3(new Web3.providers.HttpProvider(ENV.NODE.URL, ENV.NODE.CONNECTION_TIMEOUT));
  }

  connect = async (onEthereumError = null, onNetworkError = null) => {
    if (!this.web3 && !this.ethereum) {
      this.returnEthereumError(onEthereumError);
      return false;
    }

    const currentNetworkId = await this.getCurrentNetworkId();
    if (!currentNetworkId) {
      this.returnEthereumError(onEthereumError);
      return false;
    } else if (+currentNetworkId !== ENV.NETWORK_ID) {
      if (typeof onNetworkError === 'function') onNetworkError(currentNetworkId);
      return false
    }

    let accounts;
    if (this.ethereum) {
      accounts = await this.ethereum.enable();
    } else {
      accounts = await this.web3.eth.getAccounts();
    }

    this.address = accounts[0];

    if (!this.address) {
      this.returnEthereumError(onEthereumError);
      return false;
    }

    return this.address;
  };

  returnEthereumError = (onEthereumError) => {
    if (typeof onEthereumError === 'function') onEthereumError();
  }

  getDisconnected = (clearAccount) => {
    this.ethereum.on('accountsChanged', (accounts) => {
      if (accounts[0] === this.address) return;
      clearAccount();
    });

    this.ethereum.on('networkChanged', (networkId) => {
      if (+networkId === ENV.NETWORK_ID) return;
      clearAccount();
    });
  };

  makeTransaction = async (txObject, privateKey, devicePath) => {
    try {
      let txHash;

      await this.throwErrorOnNetworkError();

      if (privateKey) {
        const signedTxObj = await this.signTransaction(txObject, privateKey);
        txHash = await this.sendSignedTransaction(signedTxObj.rawTransaction);
      } else if (devicePath) {
        const signedRawTx = await this.signTransaction(txObject, devicePath);
        txHash = await this.sendSignedTransaction(signedRawTx);
      } else {
        txHash = await this.sendTransaction(txObject);
      }

      return txHash;
    } catch (error) {
      throw Error(error);
    }
  };

  sendTransaction = (txObject) => {
    return new Promise((resolve, reject) => {
      this.web3.eth.sendTransaction(txObject, function (err, txHash) {
        if (!err) {
          resolve(txHash);
        } else {
          let errorMessage = err.message;

          if (err.code === -32602) {
            errorMessage = 'Your current address is different from your previously imported one. Please re-import your address to make the transaction.';
          } else if (err.code === 4100) {
            errorMessage = 'You are not authorized to interact with this address. Please re-import your address to make the transaction.';
          }

          reject(errorMessage);
        }
      })
    })
  };

  signTransaction = (txObject, privateKey) => {
    return new Promise((resolve, reject) => {
      this.web3.eth.accounts.signTransaction(txObject, privateKey, function (err, signedTxObj) {
        if (!err) {
          resolve(signedTxObj);
        } else {
          reject(err.message);
        }
      })
    })
  };

  sendSignedTransaction = (rawTx) => {
    return new Promise((resolve, reject) => {
      this.web3.eth.sendSignedTransaction(rawTx, function (err, txHash) {
        if (!err) {
          resolve(txHash);
        } else {
          reject(err.message);
        }
      })
    })
  };

  getCurrentNetworkId = async () => {
    let currentNetworkId;

    if (this.web3) {
      currentNetworkId = await this.web3.eth.net.getId();
    } else {
      currentNetworkId = this.ethereum.networkVersion;
    }

    return currentNetworkId;
  };

  throwErrorOnNetworkError = async () => {
    const currentNetworkId = await this.getCurrentNetworkId();
    if (!currentNetworkId || +currentNetworkId !== ENV.NETWORK_ID) {
      throw Error(`Your network should be on ${fromNetworkIdToName(ENV.NETWORK_ID)}. Currently it is on ${fromNetworkIdToName(currentNetworkId)}.`);
    }
  }
}
