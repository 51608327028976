import React from "react";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import imgAboutTop from "src/assets/images/backgrounds/knc-graphic.png";
import diagram from "src/assets/images/backgrounds/knc-and-dao.jpeg";
import stakingPartnerImage from "src/assets/images/backgrounds/stakingpartner.svg";
import IcArchive from "src/assets/icons/IcArchive";
import IcMoneyBag from "src/assets/icons/IcMoneyBag";
import unagiiIcon from "src/assets/images/logos/unagii-icon.png";
import tokenIcon from "src/assets/images/logos/token-icon.png";
import socialImg from "src/assets/images/backgrounds/about-social.png";
import aggregateImg from "src/assets/images/backgrounds/aggregate-3.png";
import { routes } from "src/app/configs/routes";
import BasicModal from "src/app/components/Modals/BasicModal";
import { modal } from "src/app/components/Modals/Modal";
import { ABOUT_VIDEO_IDS, INTRODUCTION_VIDEO_ID } from "src/app/configs/constants";

export default function About() {
  const params = {
    slidesPerView: "auto",
    spaceBetween: 12,
    centeredSlides: true,
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    on: {
      slideNextTransitionEnd: function () {
        const slidePrev = document.querySelector(".swiper-slide-prev");
        slidePrev.querySelector("iframe").src = slidePrev.getAttribute("src");
        slidePrev.querySelector(".about__partners-mask").style.display = "block";
      },
      slidePrevTransitionEnd: function () {
        const slidePrev = document.querySelector(".swiper-slide-next");
        slidePrev.querySelector("iframe").src = slidePrev.getAttribute("src");
        slidePrev.querySelector(".about__partners-mask").style.display = "block";
      },
    },
  };

  function playVideo(e) {
    const wrapper = e.target.parentElement;
    if (wrapper.classList.contains("swiper-slide-active")) {
      e.target.style.display = "none";
      const src = wrapper.getAttribute("src");
      wrapper.querySelector("iframe").src = src + "?autoplay=1";
    }
  }

  function openIntroductionVideo() {
    modal.show(BasicModal, {
      title: "Katalyst: KyberDAO and KNC Staking",
      content: (
        <iframe
          className="mt-3 w-100"
          height="320"
          title="Katalyst: KyberDAO and KNC Staking"
          src={`https://www.youtube.com/embed/${INTRODUCTION_VIDEO_ID}?autoplay=1`}
          frameborder="0"
          allowFullScreen
        />
      ),
      className: "large",
    });
  }

  return (
    <div className="about">
      <div className="about__top">
        <div className="container position-relative">
          <div className="about__title">
            Stake <span className="text-success">KNC</span>, Participate in Governance, Earn Rewards.
          </div>
          <div className="about__subtitle">
            <p>Kyber Network is governed by the community through KyberDAO, a Decentralized Autonomous Organization.</p>
            <p>
              KNC holders stake KNC tokens to vote on governance proposals that shape Kyber's future and earn KNC
              rewards from trading fees.
            </p>
          </div>
          <div className="about__top__buttons mt-5">
            <Link to="/" className="common__button mr-4">
              <IcArchive className="mr-1" />
              Stake Now
            </Link>
            <a
              href="https://kyberswap.com/?utm_source=KyberDAO&utm_medium=KyberDAO-Get+KNC&utm_campaign=KyberDAO-KyberSwap&utm_id=KyberDAO-KyberSwap&utm_content=KyberDAO-Get+KNC#/swap"
              target="_blank"
              rel="noopener noreferrer"
              className="common__button common__button--hollow"
            >
              <IcMoneyBag className="mr-1" />
              Get KNC
            </a>
          </div>

          <div className="about__top__image">
            <img className="d-none d-lg-block pos " src={imgAboutTop} alt="" />
          </div>
        </div>
      </div>

      <div className="about__diagram">
        <div className="container">
          <div className="text-center">
            <div className="about__section-title mx-auto">Kyber Network Crystal (KNC)</div>
            <p className="about__text">
              KNC is a utility and governance token and an integral part of Kyber Network. KNC holders stake and vote to
              receive trading fees from protocols in the network. As more trades are executed and new protocols added,
              more rewards are generated.
            </p>
          </div>
          <img src={diagram} alt="" />
          <a
            className="common__button common__button--learn-more "
            href="https://kyber.network/about/knc"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn More
          </a>
        </div>
      </div>

      <div className="about__partners text-center">
        <div className="container mb-5">
          <div className="about__section-title mx-auto">Our Partners</div>
          <div className="about__text mx-auto mt-3">
            Reputable projects are supporting the KyberDAO, either by providing an easy way to stake KNC tokens or
            participating in Kyber governance.{" "}
          </div>
          <img className="my-5 py-3" src={stakingPartnerImage} alt="Staking Partners" />

          <div className="about__text">3rd-Party Staking Pools Include</div>
          <div className="third-party">
            <a
              className="third-party__icon"
              href="https://app.unagii.com/stake/kyber/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src={unagiiIcon} alt="" />
            </a>
            <a className="third-party__icon" href="https://xtoken.market/" target="_blank" rel="noreferrer noopener">
              <img src={tokenIcon} alt="" />
            </a>
          </div>
        </div>
        <Swiper {...params}>
          {ABOUT_VIDEO_IDS.map((video, i) => (
            <div key={i} src={`https://www.youtube.com/embed/${video}`}>
              <div className="about__partners-mask" onClick={playVideo} />
              <div className="embed-responsive embed-responsive-21by9">
                <iframe
                  title={video}
                  className="embed-responsive-item"
                  src={`https://www.youtube.com/embed/${video}`}
                  allowFullScreen
                />
              </div>
            </div>
          ))}
        </Swiper>
      </div>

      <div className="about__defi">
        <div className="container">
          <div className="text-center">
            <div className="about__section-title">Join us in powering the DeFi ecosystem</div>
            <div className="about__defi-img">
              <img className="my-5 py-3" src={socialImg} alt="Kyber social" />
              <a
                href="https://github.com/KyberNetwork/developer-portal/blob/stakingSection/README.md"
                className="github"
                target="_blank"
                rel="noreferrer noopener"
              >
                github
              </a>
              <a
                href="https://discord.com/invite/NB3vc8J9uv"
                className="discord"
                target="_blank"
                rel="noreferrer noopener"
              >
                discord
              </a>
              <a href="https://twitter.com/KyberDAO/" className="twitter" target="_blank" rel="noreferrer noopener">
                twitter
              </a>
              <a
                href="https://www.reddit.com/r/kybernetwork/"
                className="reddit"
                target="_blank"
                rel="noreferrer noopener"
              >
                reddit
              </a>
              <a
                href="https://blog.kyber.network/search?q=KyberDAO"
                className="medium"
                target="_blank"
                rel="noreferrer noopener"
              >
                medium
              </a>
              <a
                href="https://www.youtube.com/channel/UCQ-8mEqsKM3x9dTT6rrqgJw"
                className="youtube"
                target="_blank"
                rel="noreferrer noopener"
              >
                youtube
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="about__faq">
        <div className="about__faq__container">
          <div className="left">
            <div className="about__section-title mx-auto">FAQ</div>
            <div className="about__text mt-3 mt-lg-5">
              New around here? Start with the basics.
              <br />
              Learn about Staking, Voting and more.
            </div>
            <img src={aggregateImg} alt="" className="d-md-none" />
            <Link to={routes.faq} className="common__button common__button--learn-more">
              Learn More
            </Link>
          </div>
          <div className="right d-none d-md-block">
            <img src={aggregateImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
