import { call, put, select, takeLatest } from "redux-saga/effects";
import { setDelegateError, delegateActionTypes } from "src/app/actions/delegateAction";
import Web3Service from "src/app/services/web3/Web3Service";
import { setLastTx, setTxConfirming } from "src/app/actions/accountAction";
import { ACTIONS } from "src/app/configs/constants";

const getAccountState = (state) => state.account;
const getGlobalState = (state) => state.global;

function* delegate(action) {
  const { address, wallet, lastTx, privateKey, devicePath } = yield select(getAccountState);
  const { gasPrices, selectedGasPrice, estimatedGas } = yield select(getGlobalState);
  const { delegatedAddress } = action.payload;

  yield put(setDelegateError(''));
  yield put(setTxConfirming(true));

  try {
    const web3Service = new Web3Service();
    const nonce = yield call(web3Service.fetchUsableNonce, address, lastTx.nonce);
    const delegateTxObject = web3Service.getDelegateTxObject(address, delegatedAddress, nonce, gasPrices[selectedGasPrice], estimatedGas);
    const txHash = yield call(wallet.makeTransaction, delegateTxObject, privateKey, devicePath);

    yield put(setLastTx(txHash, nonce, ACTIONS.DELEGATE, null, delegatedAddress));
  } catch(e) {
    yield put(setDelegateError(typeof e === 'string' ? e : e.message));
  }

  yield put(setTxConfirming(false));
}

export default function* delegateWatcher() {
  yield takeLatest(delegateActionTypes.DELEGATE, delegate);
}
