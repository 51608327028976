export const delegateActionTypes = {
  DELEGATE: 'DELEGATE.SUBMIT',
  SET_DELEGATE_ERROR: 'DELEGATE.SET_DELEGATE_ERROR',
  SET_DELEGATED_ADDRESS: 'DELEGATE.SET_DELEGATED_ADDRESS',
};

export function setDelegateError(error) {
  return {
    type: delegateActionTypes.SET_DELEGATE_ERROR,
    payload: error
  }
}

export function delegate(delegatedAddress) {
  return {
    type: delegateActionTypes.DELEGATE,
    payload: { delegatedAddress }
  }
}

export function setDelegatedAddress(address) {
  return {
    type: delegateActionTypes.SET_DELEGATED_ADDRESS,
    payload: address
  }
}
