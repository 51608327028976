import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { delegate, setDelegateError } from "src/app/actions/delegateAction";
import { ACTIONS, DEFAULT_GAS } from "src/app/configs/constants";
import ModalPanel from "src/app/components/Modals/ModalPanel";
import { setTxConfirming } from "src/app/actions/accountAction";
import GasOption from "src/app/components/Commons/GasOption";

export default function RemoveDelegateConfirmModal({close}) {
  const dispatch = useDispatch();
  const { address, txConfirming, privateKey } = useSelector(state => state.account);
  const { delegateError } = useSelector((state) => state.delegate);

  useEffect(() => {
    dispatch(setDelegateError(''));
    dispatch(setTxConfirming(false));
  }, [dispatch]);

  const removeDelegated = () => {
    dispatch(delegate(address));
  };

  return (
    <div className="modal__delegate modal-interact">
      <div className="modal-content">
        <div className="title">Do you wish to remove your representative?</div>
        <div className="note">
          <span>Note: Removing your current representative will shift the voting power to yourself.</span>
        </div>

        <GasOption
          txType={ACTIONS.DELEGATE}
          txParams={{ address, delegatedAddress: address }}
          defaultGasLimit={DEFAULT_GAS.DELEGATE}
        />
      </div>

      <ModalPanel
        error={delegateError}
        txConfirming={txConfirming}
        handleCancel={close}
        handleConfirm={removeDelegated}
        showWatingMessage={!privateKey}
      />
    </div>
  )
}
