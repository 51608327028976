import React from "react";

export default function IcBell(props) {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.00016 16.75C7.91683 16.75 8.66683 16 8.66683 15.0833H5.3335C5.3335 16 6.07516 16.75 7.00016 16.75ZM12.0002 11.75V7.58333C12.0002 5.025 10.6335 2.88333 8.25016 2.31667V1.75C8.25016 1.05833 7.69183 0.5 7.00016 0.5C6.3085 0.5 5.75016 1.05833 5.75016 1.75V2.31667C3.3585 2.88333 2.00016 5.01667 2.00016 7.58333V11.75L0.333496 13.4167V14.25H13.6668V13.4167L12.0002 11.75Z"
        fill={props.fill || "#3A3A3A"}
      />
    </svg>
  );
}
