import React from "react";
import { useDispatch } from "react-redux";
import { lowerCase } from "src/app/utils/fortmaters";
import { verifyKey, anyErrors } from "src/app/utils/validators";
import { unlock, addressFromKey } from "src/app/utils/keys";
import { modal } from "src/app/components/Modals/Modal";
import BasicModal from "src/app/components/Modals/BasicModal";
import InputPasswordModal from "src/app/components/Modals/InputPasswordModal";
import { importAccount } from "src/app/actions/accountAction";
import { WALLET_TYPES } from "src/app/configs/constants";
import KeystoreService from "src/app/services/accounts/KeystoreService";

export default function KeystoreAccount() {
  const dispatch = useDispatch();

  function selectFile(e) {
    return new Promise((resolve, reject) => {
      let file = e.target.files[0];
      let fileReader = new FileReader();
      fileReader.onload = (event) => {
        let keystring = lowerCase(event.target.result);
        let errors = {};
        errors["keyError"] = verifyKey(keystring);
        if (anyErrors(errors)) {
          reject(new Error(errors["keyError"]));
        } else {
          resolve(keystring);
        }
      };
      fileReader.readAsText(file);
    });
  }

  function unLockKeyStore(keyString, passphrase) {
    try {
      const wallet = new KeystoreService();
      const privateKey = unlock(keyString, passphrase, true);
      const address = addressFromKey(keyString);

      dispatch(
        importAccount(address, wallet, WALLET_TYPES.KEYSTORE, {
          privateKey: privateKey.toString("hex"),
          keyString: keyString
        })
      );

      modal.close();
      return () => {};
    } catch (e) {
      return (fn) => fn(e.message);
    }
  }

  function handleOnChangeFile(event) {
    selectFile(event)
      .then((keystring) => {
        modal.show(InputPasswordModal, {
          title: "Enter your passphrase",
          onSubmit: (passphrase) => unLockKeyStore(keystring, passphrase)
        });
      })
      .catch((e) => {
        modal.show(BasicModal, {
          title: "Error",
          content: <div>{e.message}</div>,
        });
      });
  }

  function resetSelectedFile(e) {
    e.target.value = null
  }

  return (
    <label className="account__item">
      <div className="account__item-icon keystore" />
      <div className="account__item-name">Keystore</div>
      <input
        id="select-file"
        className="d-none"
        type="file"
        onClick={resetSelectedFile}
        onChange={handleOnChangeFile}
      />
    </label>
  );
}
