import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import VotingContainer from "src/app/components/Proposal/VotingContainer";
import QueueingContainer from "src/app/components/Proposal/QueueingContainer";
import ExecutingContainer from "src/app/components/Proposal/ExecutingContainer";
import ProposalItem from "src/app/components/Proposal/ProposalItem";
import { PAGING, PROPOSAL_TYPES, PROPOSAL_STATUSES } from "src/app/configs/constants";
import { renderProposalTitle } from "src/app/utils/fortmaters";
import StakeContainer from "../Stake/StakeContainer";

const tags = [
  PROPOSAL_TYPES.FEE,
  PROPOSAL_TYPES.BRR,
  PROPOSAL_TYPES.GENERAL,
  PROPOSAL_TYPES.BINARY,
  PROPOSAL_TYPES.GENERIC,
];

export default function ProposalList() {
  const { activeCampaigns, upcomingCampaigns, endedCampaigns } = useSelector((state) => state.campaign);
  // const { currentNetworkData } = useSelector((state) => state.global);

  const [isOpenStakeModal, setIsOpenStakeModal] = useState(false);
  const [filteredProposals, setFilteredProposals] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [activeTags] = useState(tags);
  const [filterStatus, setFilterStatus] = useState(null);
  const [voteInfo, setVoteInfo] = useState({});
  const [queueInfo, setQueueInfo] = useState({});
  const [executeInfo, setExecuteInfo] = useState({});
  const [page, setPage] = useState(1);

  useEffect(() => {
    function sortProposals(proposals, status) {
      proposals.sort((x, y) => {
        const t = {
          [PROPOSAL_STATUSES.ACTIVE]: ["start_timestamp", 1],
          [PROPOSAL_STATUSES.UPCOMING]: ["start_timestamp", -1],
          [PROPOSAL_STATUSES.ENDED]: ["end_timestamp", 1],
        }[status];

        return t[1] * (y[t[0]] - x[t[0]]);
      });
    }

    sortProposals(activeCampaigns, PROPOSAL_STATUSES.ACTIVE);
    sortProposals(upcomingCampaigns, PROPOSAL_STATUSES.UPCOMING);
    sortProposals(endedCampaigns, PROPOSAL_STATUSES.ENDED);

    const allProposals = [...activeCampaigns, ...upcomingCampaigns, ...endedCampaigns];
    let foundProposals = [];

    allProposals.forEach((proposal) => {
      if (checkValidProposal(proposal)) foundProposals.push(proposal);
    });

    setFilteredProposals(foundProposals);
  }, [searchKeyword, activeTags, filterStatus, page, activeCampaigns, upcomingCampaigns.length, endedCampaigns.length]); // eslint-disable-line

  function checkValidProposal(proposal) {
    const proposalTitle = renderProposalTitle(proposal).toLowerCase();
    const containKeyword = searchKeyword !== "" ? proposalTitle.indexOf(searchKeyword.toLowerCase()) !== -1 : true;
    const containTag = activeTags.length ? activeTags.some((tag) => proposal.proposal_type === tag) : false;
    const containProposal = filterStatus ? proposal.status === filterStatus : true;

    return containKeyword && containTag && containProposal;
  }

  return (
    <div className="proposal mt-3">
      <div className="proposal__header">
        {/* {currentNetworkData && (
          <div className="common__note--box common__slide-up">
            Current Network fee: {currentNetworkData.fee}%, Burn: {currentNetworkData.burn}%, Reward: {currentNetworkData.reward}%, Rebate: {currentNetworkData.rebate}%
          </div>
        )} */}
        <div className="proposal__filter">
          {[
            null,
            PROPOSAL_STATUSES.SUCCEEDED,
            PROPOSAL_STATUSES.QUEUED,
            PROPOSAL_STATUSES.EXECUTED,
            PROPOSAL_STATUSES.CANCELED,
          ].map((status) => (
            <div
              key={status}
              className={`proposal__filter-item ${filterStatus === status ? "active" : ""}`}
              onClick={() => setFilterStatus(status)}
            >
              <span className="text-uppercase">
                {status ? (status === PROPOSAL_STATUSES.SUCCEEDED ? "Approved" : status) : "All"}
              </span>
            </div>
          ))}
        </div>
        <div className="proposal__search-input">
          <input
            className="common__input"
            type="text"
            placeholder="Search proposals"
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
          />
        </div>
      </div>

      <div className="proposal__body">
        <div>
          {filteredProposals.length > 0 ? (
            filteredProposals.slice(0, page * PAGING.PROPOSAL_DISPLAY).map((proposal, index) => {
              return (
                <div className="mb-3 common__slide-up" key={`${proposal.proposal_id}-${index}`}>
                  <ProposalItem
                    proposal={proposal}
                    submit={setVoteInfo}
                    queue={setQueueInfo}
                    execute={setExecuteInfo}
                  />
                </div>
              );
            })
          ) : (
            <div className="common__slide-up">
              <div className="proposal__block">No any proposals found</div>
            </div>
          )}
        </div>

        {page * PAGING.PROPOSAL_DISPLAY < filteredProposals.length && (
          <div className="common__flex-horizon-center">
            <div className="common__link" onClick={() => setPage(page + 1)}>
              View More
            </div>
          </div>
        )}
      </div>
      <VotingContainer voteInfo={voteInfo} clear={() => setVoteInfo({})} />
      <StakeContainer isOpenStakeModal={isOpenStakeModal} onCloseModal={() => setIsOpenStakeModal(false)} />
      <QueueingContainer queueInfo={queueInfo} clear={() => setQueueInfo({})} />
      <ExecutingContainer executeInfo={executeInfo} clear={() => setExecuteInfo({})} />
    </div>
  );
}
