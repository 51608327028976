import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import { modal } from "src/app/components/Modals/Modal";
import DelegateConfirmModal from "src/app/components/Modals/Delegate/DelegateConfirmModal";
import DelegateSuccessModal from "src/app/components/Modals/Delegate/DelegateSuccessModal";
import RemoveDelegateConfirmModal from "src/app/components/Modals/Delegate/RemoveDelegateConfirmModal";
import RemoveDelegateSuccessModal from "src/app/components/Modals/Delegate/RemoveDelegateSuccessModal";
import BroadcastModal from "src/app/components/Modals/BroadcastModal";
import { ACTIONS, TOPICS } from "src/app/configs/constants";
import { setDelegatedAddress } from "src/app/actions/delegateAction";

export default function DelegatingContainer({ delegateInfo, clear }) {
  const { address, lastTx } = useSelector((state) => state.account);
  const isSendingTx = lastTx.type === ACTIONS.DELEGATE;
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);

  const isRepresentativeRemoved = delegateInfo.isRemoved || (delegateInfo.delegatedAddress === address);
  const modals = [
    () => modal.show(isRepresentativeRemoved ? RemoveDelegateConfirmModal : DelegateConfirmModal, { ...delegateInfo }, closeModal),
    () => modal.show(BroadcastModal, { txHash: lastTx.hash, topic: TOPICS.DELEGATE, nextStep: updateStore }, closeModal),
    () => modal.show(isRepresentativeRemoved ? RemoveDelegateSuccessModal : DelegateSuccessModal, { ...delegateInfo, txHash: lastTx.hash }, closeModal)
  ];

  useEffect(() => {
    requireDelegateInfo(() => {
      if (typeof modals[step] === 'function') modals[step]();
    })
  }, [step, delegateInfo]); // eslint-disable-line

  useEffect(() => {
    if (!isSendingTx) return;
    nextStep();
  }, [lastTx]); // eslint-disable-line

  const requireDelegateInfo = (fn) => {
    if (Object.keys(delegateInfo).length > 0) { fn() }
  };

  function closeModal() {
    setStep(0);
    clear();
  }

  function nextStep() {
    requireDelegateInfo(() => setStep(v => v + 1));
  }

  function updateStore() {
    dispatch(setDelegatedAddress(isRepresentativeRemoved ? "" : delegateInfo.delegatedAddress));
    nextStep();
  }

  return <></>
}
