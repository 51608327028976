import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import AccountBalance from "src/app/components/Accounts/AccountBalance";
import { validateBalanceInput } from "src/app/utils/validators";
import { withdraw, setWithdrawError } from "src/app/actions/withdrawAction";
import { ACTIONS, DEFAULT_GAS } from "src/app/configs/constants";
import ModalPanel from "src/app/components/Modals/ModalPanel";
import { setTxConfirming } from "src/app/actions/accountAction";
import GasOption from "src/app/components/Commons/GasOption";

export default function WithdrawConfirmModal(props) {
  const dispatch = useDispatch();
  const { address, balance, txConfirming, privateKey } = useSelector((state) => state.account);
  const { withdrawError } = useSelector((state) => state.withdraw);

  const [amount, setAmount] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    dispatch(setWithdrawError(''));
    dispatch(setTxConfirming(false));
  }, [dispatch]);

  async function handleWithdraw() {
    let errorMessage = validateBalanceInput(amount, balance.stakedKNC, address);

    if (errorMessage !== null) {
      setError(errorMessage);
      return;
    }

    dispatch(withdraw(amount));
  }

  function clearError() {
    setError('');
  }

  return (
    <div className="modal__withdraw modal-interact">
      <div className="modal-content">
        <div className="title">Withdraw Stake</div>
        <div className="info">Do you wish to withdraw your staked KNC from KyberDAO?</div>
        <div className="note mt-3 mb-3">
          <span>Note: Withdrawing your stake will affect your voting power and rewards for the proposals in this epoch.</span>
        </div>

        <AccountBalance
          amount={amount}
          setAmount={setAmount}
          error={error}
          clearError={clearError}
          stakedBalance
        />

        <GasOption
          txType={ACTIONS.WITHDRAW}
          txParams={{ address: address, amount: 1 }}
          defaultGasLimit={DEFAULT_GAS.WITHDRAW}
        />
      </div>

      <ModalPanel
        error={withdrawError}
        txConfirming={txConfirming}
        handleCancel={props.close}
        handleConfirm={handleWithdraw}
        showWatingMessage={!privateKey}
      />
    </div>
  )
}
