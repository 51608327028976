export const approveActionTypes = {
  APPROVE_TOKEN: 'APPROVE.APPROVE_TOKEN',
  SET_ERROR: 'APPROVE.SET_ERROR',
};

export function approveToken(isApproveToMax, token, delegator) {

  return {
    type: approveActionTypes.APPROVE_TOKEN,
    payload: { isApproveToMax, token, delegator }
  }
}

export function setApproveError(error) {
  return {
    type: approveActionTypes.SET_ERROR,
    payload: error
  }
}
