import React from "react";
import AccountImport from "src/app/components/Accounts/AccountImport";
import Modal from "src/app/components/Modals/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toggleAccountModal } from "src/app/actions/globalAction";

export default function AccountImportModal() {
  const dispatch = useDispatch();
  const { modals } = useSelector((state) => state.global);

  function toggleModal() {
    dispatch(toggleAccountModal());
  }

  return (
    <Modal isActive={modals.account} onClose={toggleModal}>
      <div className={`modal-interact`}>
        <div className="modal-content">
          <div className="title">Connect Wallet</div>
          <AccountImport />
        </div>
        <div className="panel">
          <div className="common__button common__button--hollow" onClick={toggleModal}>Cancel</div>
        </div>
      </div>
    </Modal>
  )
}