import React from "react";
import { useSelector } from "react-redux";
import Loading from "src/app/components/Commons/Loading";
import { readableTime } from "src/app/utils/fortmaters";
import { getCurrentTimestamp } from "src/app/utils/converters";
import useCountDown from "src/app/components/Hooks/useCountDown";

export default function EpochProgress() {
  const { daoInfo } = useSelector((state) => state.global);

  const currentTime = getCurrentTimestamp();
  const timeLeft = useCountDown(daoInfo.endTime - currentTime);

  function withLoading(content) {
    return content && content.toString().trim() !== "Invalid date" ? content : <Loading />;
  }

  return (
    <div className="epoch-progress">
      <div className="epoch-progress__ongoing">In Progress: Epoch {daoInfo.currentEpoch}</div>
      <div className="common__timer epoch-progress__timer">
        {withLoading(timeLeft && timeLeft > 0 ? `${readableTime(timeLeft)} left` : null)}
      </div>
      <div className="epoch-progress__info">Vote on all current epoch proposals to get your full reward</div>
    </div>
  );
}
