import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { formatAddress } from "src/app/utils/fortmaters";
import { delegate, setDelegateError } from "src/app/actions/delegateAction";
import { ACTIONS, DEFAULT_GAS } from "src/app/configs/constants";
import ModalPanel from "src/app/components/Modals/ModalPanel";
import { setTxConfirming } from "src/app/actions/accountAction";
import ENV from "src/app/configs/env";
import GasOption from "src/app/components/Commons/GasOption";

export default function DelegateConfirmModal({ close, delegatedAddress, delegatedPartner }) {
  const dispatch = useDispatch();
  const { address, txConfirming, privateKey } = useSelector(state => state.account);
  const { delegateError } = useSelector((state) => state.delegate);
  const formattedAddress = formatAddress(delegatedAddress, 7, -4);

  useEffect(() => {
    dispatch(setDelegateError(''));
    dispatch(setTxConfirming(false));
  }, [dispatch]);

  function delegateSubmit() {
    dispatch(delegate(delegatedAddress));
  }

  return (
    <div className="modal__delegate modal-interact">
      <div className="modal-content">
        <div className="title">Delegate</div>
        {delegatedPartner.image && (
          <img className="modal__image" src={delegatedPartner.image} alt={delegatedPartner.name}/>
        )}
        <div className="mt-3 mb-3">
          <span>You are delegating your voting power to&nbsp;</span>
          <a className="font-weight-bold common__link-text common__link-text--black" href={`${ENV.URLS.ETHERSCAN}/address/${delegatedAddress}`} target="_blank" rel="noreferrer noopener">
            {delegatedPartner.name ? `${delegatedPartner.name} (${formattedAddress})` : formattedAddress}
          </a>
          <span>&nbsp;to vote on your behalf.</span>
        </div>
        <div className="note mt-2">
          <span>
            <b>Important:&nbsp;</b>
            It is up to your representative or staking pool to distribute rewards to you. You are advised to read their T&Cs. Kyber does not hold your rewards.
          </span>
        </div>

        <GasOption
          txType={ACTIONS.DELEGATE}
          txParams={{ address, delegatedAddress }}
          defaultGasLimit={DEFAULT_GAS.DELEGATE}
        />
      </div>

      <ModalPanel
        error={delegateError}
        txConfirming={txConfirming}
        handleCancel={close}
        handleConfirm={delegateSubmit}
        showWatingMessage={!privateKey}
      />
    </div>
  )
}
