import React from 'react';
import AccountBalance from "src/app/components/Accounts/AccountBalance";
import { ACTIONS, DEFAULT_GAS, TERMS_LINK } from "src/app/configs/constants";
import GasOption from "src/app/components/Commons/GasOption";

export default function StakingFormModal(props) {
  function clearError() {
    props.setError('');
  }

  return (
    <div className="staking">
      <div className="staking__title common__block-title">Stake KNC</div>

      <AccountBalance
        amount={props.stakingAmount}
        setAmount={props.setStakingAmount}
        error={props.error}
        clearError={clearError}
      />

      <GasOption
        txType={ACTIONS.STAKE}
        txParams={{ address: props.address, stakingAmount: 1 }}
        defaultGasLimit={DEFAULT_GAS.STAKE}
      />

      <div className="staking__button-container">
        <div className="staking__button common__button" onClick={props.openStakingModal}>Stake</div>
      </div>

      <div className={"epoch-progress__info mt-2 text-center"}>By using kyber.org, you agree to the
        <a className={"common__link--no-upercase font-weight-medium"} href={TERMS_LINK} target="_blank" rel="noopener noreferrer"> Terms of Use</a>
      </div>
    </div>
  )
}
