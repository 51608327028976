import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import BigNumber from "bignumber.js";
import ENV from "src/app/configs/env";
import Web3Service from "src/app/services/web3/Web3Service";

export async function fetchTokenPrices() {
  try {
    const response = await fetch(`${ENV.APIS.KN}/change24h`);
    const result = await response.json();

    const knc = extractRateAndChange24h(result.ETH_KNC);
    const eth = extractRateAndChange24h(result.ETH_ETH);

    return { knc, eth };
  } catch (e) {
    return false;
  }
}

function extractRateAndChange24h(token) {
  return {
    rate: token && token.rate_usd_now ? token.rate_usd_now : 0,
    change24h: token && token.change_usd_24h ? token.change_usd_24h : 0,
  };
}

const mainnetExchangeClient = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/dynamic-amm/dynamic-amm",
  cache: new InMemoryCache(),
});

const maticExchangeClient = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/dynamic-amm/dmm-exchange-matic",
  cache: new InMemoryCache(),
});

const POOLS_BULK = gql`
  ${`fragment PoolFields on Pool { id, totalSupply, reserveUSD }`}
  query pools($allPools: [Bytes]!) {
    pools(
      where: { id_in: $allPools }
      orderBy: trackedReserveETH
      orderDirection: desc
    ) {
      ...PoolFields
    }
  }
`;

const ETH_PRICE = gql`
  query bundles {
    bundles(where: { id: 1 }) {
      id
      ethPrice
    }
  }
`;

const TOKEN_DERIVED_ETH = (tokenAddress) => {
  const queryString = `
    query tokens {
      tokens(where: { id: "${tokenAddress.toLowerCase()}"} ) {
        derivedETH
      }
    }
    `;

  return gql(queryString);
};

export async function fetchVotingReward() {
  const KNC_ADDRESS = "0xdeFA4e8a7bcBA345F687a2f1456F5Edd9CE97202";
  const web3Service = new Web3Service();
  let totalReward = new BigNumber(0);

  const TRESUARY_ADDRESS = "0x0e590bb5f02a0c38888bffb45dee050b8fb60bda";
  const LP_ADDRESSES = [
    "0x61639d6ec06c13a96b5eb9560b359d7c648c7759", // eth-knc
    "0x1cf68bbc2b6d3c6cfe1bd3590cf0e10b06a05f17", // eth-wbtc
    "0xce9874c42dce7fffbe5e48b026ff1182733266cb", //eth-usdt
    "0x306121f1344ac5f84760998484c0176d7bfb7134", //usdc-usdt
    "0xd343d5dba2fba55eef58189619c05e33cab95ca1", //wbtc-usdt

    "0x9a56f30ff04884cb06da80cb3aef09c6132f5e77", //eth-sipher
    "0xe0ca51b6cfac04b215c3b6e473e3ec1412c93fc7", //usdc-und
    "0x38ff2ea1a930478f002af766e63774fc02f04fdf", //knc-und
    "0xa97642500517c728ce1339a466de0f10c19034cd", //eth-req
    "0x5ba740fcc020d5b9e39760cbd2fe236586b9dc0a", //eth-sand
  ];
  const pools = await mainnetExchangeClient.query({
    query: POOLS_BULK,
    variables: {
      allPools: LP_ADDRESSES,
    },
    fetchPolicy: "network-only",
  });

  const balances = await Promise.all(
    LP_ADDRESSES.map((lp) =>
      web3Service
        .fetchTokenBalance(TRESUARY_ADDRESS, lp)
        .then((res) => ({ id: lp, balance: res }))
    )
  );

  balances.forEach((item) => {
    const pool = pools.data.pools.find((pool) => pool.id === item.id);
    totalReward = totalReward.plus(
      new BigNumber(item.balance)
        .div(10 ** 18)
        .times(new BigNumber(pool.reserveUSD))
        .div(new BigNumber(pool.totalSupply))
    );
  });

  const MATIC_TRESUARY_ADDRESS = "0x91c9D4373B077eF8082F468C7c97f2c499e36F5b";
  const MATIC_LP_ADDRESS = [
    "0x45963db838a070cf7be8e7046fd63e23d376c665", //matic-dai
    "0x37e6449b0e99befd2a708ea048d970f4ff4dc65d", //matic-knc
    "0x3904ac366d348636694cb6720aa1540e76441b1b", //usdc-usdt
    "0x7018c0bd73255c8966d0b26634e0bc0c7595d255", //usdc-dai
    "0x95d708e9ee04b0136b98579141624d19c89b9d68", //usdc-eth
    "0xd8b9e9444fcbf26bea4badd6142dd6a962bca86a", //knc-eth

    "0xecf185d8114664e42dae0701eaff1a50a3613a05", //usdt-vis
    "0x3f1f398887525d2d9acd154ec5e4a3979adffae6", //usdt-pgx
    "0xa4653d9614057daa5b3ec04a7289337e56746e8c", //pgx-vis
    "0xa1219dbe76eecbf7571fed6b020dd9154396b70e", //usdc-jeur
    "0xbb2d00675b775e0f8acd590e08da081b2a36d3a6", //usdc-jGBP
  ];

  const poolsMATIC = await maticExchangeClient.query({
    query: POOLS_BULK,
    variables: {
      allPools: MATIC_LP_ADDRESS,
    },
    fetchPolicy: "network-only",
  });

  console.log("====[MATIC]POOLS_BULK: ", poolsMATIC);
  const balancesMATIC = await Promise.all(
    MATIC_LP_ADDRESS.map((lp) =>
      web3Service
        .fetchTokenBalanceMATIC(MATIC_TRESUARY_ADDRESS, lp)
        .then((res) => ({ id: lp, balance: res }))
    )
  );

  balancesMATIC.forEach((item) => {
    const pool = poolsMATIC.data.pools.find((pool) => pool.id === item.id);
    totalReward = totalReward.plus(
      new BigNumber(item.balance)
        .div(10 ** 18)
        .times(new BigNumber(pool.reserveUSD))
        .div(new BigNumber(pool.totalSupply))
    );
  });
  const ethBalance = await web3Service.fetchETHBalance(TRESUARY_ADDRESS);
  const ethPrice = await mainnetExchangeClient.query({
    query: ETH_PRICE,
    fetchPolicy: "network-only",
  });
  const kncPriceResult = await mainnetExchangeClient.query({
    query: TOKEN_DERIVED_ETH(KNC_ADDRESS),
    fetchPolicy: "no-cache",
  });
  const derivedETH = kncPriceResult?.data?.tokens[0]?.derivedETH;

  const kncPriceByETH = new BigNumber(derivedETH) || new BigNumber(0);
  const kncPrice =
    ethPrice.data.bundles[0].ethPrice &&
    kncPriceByETH.times(new BigNumber(ethPrice.data.bundles[0].ethPrice));
  const maticBalance = await web3Service.fetchMATICBalance(
    MATIC_TRESUARY_ADDRESS
  );
  const maticPrice = await maticExchangeClient.query({
    query: ETH_PRICE,
    fetchPolicy: "network-only",
  });
  totalReward = totalReward
    .plus(
      new BigNumber(ethBalance)
        .div(10 ** 18)
        .times(new BigNumber(ethPrice.data.bundles[0].ethPrice))
    )
    .plus(
      new BigNumber(maticBalance)
        .div(10 ** 18)
        .times(new BigNumber(maticPrice.data.bundles[0].ethPrice))
    );

  //reward pool
  const REWARD_POOL_ADDRESS = "0xd2d0a0557e5b78e29542d440ec968f9253daa2e2";
  const kncInRewardPool = await web3Service
    .fetchTokenBalance(REWARD_POOL_ADDRESS, KNC_ADDRESS)
    .then((res) => ({ id: KNC_ADDRESS, balance: res }));
  totalReward = totalReward.plus(
    new BigNumber(kncInRewardPool.balance).div(10 ** 18).times(kncPrice)
  );
  return {
    usd: totalReward.toFixed(0),
    knc: totalReward.div(kncPrice).toFixed(0),
  };
}

export async function fetchClaimProof(address) {
  try {
    const res = await fetch(`${ENV.APIS.DAO}/stakers/${address}/votes`).then(
      (res) => res.json()
    );
    return {
      address: "0xD4cF9b9bDe051e1162fea423Ac4C444B83a2d301",
      cycle: 3,
      index: 0,
      tokens: ["0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE"],
      cumulativeAmounts: ["0x2710"], // 10000 = 0x2710
      proof: [
        "0x97e6893721398bbf5811dbc62b7ef47bc4c49f64555a459210184bb1d36088fa",
      ],
    };
  } catch (e) {
    return [];
  }
}
