import React, { useState } from "react";
import { useSelector } from "react-redux";
import AccountImport from "src/app/components/Accounts/AccountImport";
import AccountData from "src/app/components/Accounts/AccountData";

export default function Account(props) {
  const { wallet } = useSelector(state => state.account);

  const [isClosed, setIsClosed] = useState(false);

  function closeAccount() {
    setIsClosed(true);
  }

  return (
    <div className={`account ${isClosed ? 'account--closed' : ''} ${wallet ? 'imported' : 'not-imported'} ${props.className ? props.className : ''}`}>
      {!wallet && (
        <AccountImport closeAccount={closeAccount}/>
      )}
      {wallet && <AccountData onChangeAccount={props.onChangeAccount}/>}
    </div>
  );
}
