import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { roundNumber } from "src/app/utils/fortmaters";
import useGasAndTxFee from "src/app/components/Hooks/useGasAndTxFee";
import { setStakeError, stake } from "src/app/actions/stakeAction";
import { ACTIONS } from "src/app/configs/constants";
import Loading from "src/app/components/Commons/Loading";
import ModalPanel from "src/app/components/Modals/ModalPanel";
import { setTxConfirming } from "src/app/actions/accountAction";

export default function StakingConfirmModal(props) {
  const dispatch = useDispatch();
  const { address, txConfirming, privateKey } = useSelector((state) => state.account);
  const { stakeError } = useSelector((state) => state.stake);

  const { gasPrice, gas, txFee, isGasLoading } = useGasAndTxFee(ACTIONS.STAKE, {
    address: address,
    stakingAmount: props.stakingAmount
  });

  useEffect(() => {
    dispatch(setStakeError(''));
    dispatch(setTxConfirming(false));
  }, [dispatch]);

  function handleStakeToken() {
    dispatch(stake(props.stakingAmount, gasPrice, gas));
  }

  return (
    <div className="modal__staking modal-interact">
      <div className="modal-content">
        <div className="title">Confirm Stake</div>
        <div className="ins">You are staking {roundNumber(props.stakingAmount, 6, true)} KNC to KyberDAO</div>
        <div className="info mt-4 mb-4">Tx Fee: {isGasLoading ? <Loading/> : txFee} ETH</div>
        <div className="note">
          <span>Note: KNC staked in this current epoch will only be active for voting from the next epoch onwards</span>
        </div>
      </div>

      <ModalPanel
        error={stakeError}
        isGasLoading={isGasLoading}
        txConfirming={txConfirming}
        txFee={txFee}
        handleCancel={props.close}
        handleConfirm={handleStakeToken}
        showWatingMessage={!privateKey}
      />
    </div>
  )
}
