import React from "react";
import ENV from "src/app/configs/env";
import etherscanLogo from "src/assets/images/logos/etherscan.png";
import kyberLogo from "src/assets/images/logos/kyber-green.svg";

export default function ViewOn(props) {
  return (
    <div className={"view-on common__flex"}>
      <span>View on:</span>
      <a href={`${ENV.URLS.ETHERSCAN}/tx/${props.txHash}`} target="_blank" rel="noreferrer noopener">
        <img alt="icon" src={etherscanLogo}/>
      </a>
      <a href={`${ENV.URLS.ENJINX}/eth/transaction/${props.txHash}`} target="_blank" rel="noreferrer noopener">
        <img alt="icon" src={kyberLogo}/>
      </a>
    </div>
  )
}