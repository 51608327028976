import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS } from "src/app/configs/constants";
import { claimRewards, setRewardError } from "src/app/actions/rewardAction";
import ModalPanel from "src/app/components/Modals/ModalPanel";
import { setTxConfirming } from "src/app/actions/accountAction";
import useGasAndTxFee from "src/app/components/Hooks/useGasAndTxFee";
import Loading from "src/app/components/Commons/Loading";
import rewardConfirm from "src/assets/images/reward/reward-confirm.svg";
import { roundNumber } from "src/app/utils/fortmaters";

export default function ClaimRewardsConfirmModal({ claimingAmount, close }) {
  const dispatch = useDispatch();
  const { address, txConfirming, privateKey } = useSelector((state) => state.account);
  const { rewards } = useSelector((state) => state.reward);
  const { rewardError } = useSelector((state) => state.reward);

  const { txFee, isGasLoading } = useGasAndTxFee(ACTIONS.CLAIM_REWARDS, {
    cycle: rewards.cycle,
    index: rewards.index,
    user: address,
    tokens: rewards.tokens,
    cumulativeAmounts: rewards.cumulativeAmounts,
    merkleProof: rewards.merkleProof,
  });

  useEffect(() => {
    dispatch(setRewardError(''));
    dispatch(setTxConfirming(false));
  }, [dispatch]);

  async function handleClaimRewards() {
    dispatch(claimRewards(rewards));
  }

  return (
    <div className="modal__claim modal-interact">
      <div className="modal-content">
        <div className="image">
          <img src={rewardConfirm} width="120" alt="Reward Confirm" />
        </div>
        <div className="ins">You are claiming a reward of</div>
        <div className="amount">
          <span className="amount__value">{roundNumber(claimingAmount, 6)}</span>
          <span className="amount__token">KNC</span>
        </div>
        <div className="info mt-4 mb-2">Tx Fee: {isGasLoading ? <Loading /> : txFee} ETH</div>
      </div>

      <ModalPanel
        error={rewardError}
        isGasLoading={isGasLoading}
        txConfirming={txConfirming}
        txFee={txFee}
        handleCancel={close}
        handleConfirm={handleClaimRewards}
        showWatingMessage={!privateKey}
      />
    </div>
  )
}
