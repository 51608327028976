import { call, put, select, takeLatest } from "redux-saga/effects";
import { setQueueError, queueActionTypes } from "src/app/actions/queueAction";
import Web3Service from "src/app/services/web3/Web3Service";
import { setLastTx, setTxConfirming } from "src/app/actions/accountAction";
import { ACTIONS } from "src/app/configs/constants";

const getAccountState = (state) => state.account;
const getGlobalState = (state) => state.global;

function* queue(action) {
  const { address, wallet, lastTx, privateKey, devicePath } = yield select(getAccountState);
  const { gasPrices, selectedGasPrice, estimatedGas } = yield select(getGlobalState);
  const { campID } = action.payload;
  
  yield put(setQueueError(''));
  yield put(setTxConfirming(true));

  try {
    const web3Service = new Web3Service();
    const nonce = yield call(web3Service.fetchUsableNonce, address, lastTx.nonce);
    const queueTxObject = web3Service.getQueueTxObject(address, campID, nonce, gasPrices[selectedGasPrice], estimatedGas);
    const txHash = yield call(wallet.makeTransaction, queueTxObject, privateKey, devicePath);

    yield put(setLastTx(txHash, nonce, ACTIONS.QUEUE));
  } catch(e) {
    yield put(setQueueError(typeof e === 'string' ? e : e.message));
  }

  yield put(setTxConfirming(false));
}

export default function* queueWatcher() {
  yield takeLatest(queueActionTypes.QUEUE, queue);
}
