import React, { useEffect, useState } from "react";
import Loading from "src/app/components/Commons/Loading";

export default function DeviceLoading(props) {
  const [showInfo, setShowInfo] = useState(false);

  useEffect(() => {
    if (!props.loadingContent) return;

    const showInfoTimeout = setTimeout(() => {
      setShowInfo(true);
    }, 6000);

    return () => {
      clearTimeout(showInfoTimeout);
    }
  }, [props.loadingContent]);

  return (
    <div className="loading-modal">
      <Loading />
      <div>Processing</div>

      {(props.loadingContent && showInfo) && (
        <div className="loading-modal__info common__fade-in">
          {props.loadingContent}
        </div>
      )}
    </div>
  )
}
