import React from 'react';
import {
  WalletLinkAccount,
  KeystoreAccount,
  LedgerAccount,
  MetamaskAccount,
  TrezorAccount,
  WalletConnectAccount
} from "src/app/components/Accounts/Types";

export default function AccountImport(props) {
  return (
    <div>
      <div className="account__close" onClick={props.closeAccount}>×</div>
      <div className="account__main-title">Connect Wallet</div>
      <div className="account__container">
        <MetamaskAccount className="d-none d-md-flex" />
        <LedgerAccount className="d-none d-md-flex" />
        <KeystoreAccount />
        <TrezorAccount className="d-none d-md-flex" />
        <WalletLinkAccount className="d-none d-md-flex" />
        <WalletConnectAccount />
      </div>
    </div>
  )
};
