const envConfig = {
  NETWORK_ID: 3,
  CHAIN_NAME: "ropsten",
  URLS: {
    ETHERSCAN: "https://ropsten.etherscan.io",
    ENJINX: "https://kyber.enjinx.io",
    DAOV1: "https://dev-dao.knstats.com",
  },
  NODE: {
    URL: "https://ethereum.kyber.org/v1/ropsten/node",
    CONNECTION_TIMEOUT: 10000,
  },
  NODE_MATIC: {
    URL: "https://polygon.dmm.exchange/v1/mainnet/geth?appId=prod-dmm",
    CONNECTION_TIMEOUT: 6000,
  },
  CONTRACTS: {
    PROXY: "0xd719c34261e099fdb33030ac8909d5788d3039c4",
    STAKING: "0x6a345cdaba1b34cc74b877530cf28ad43b2bf2c7",
    DAO: "0xef5a1404e312078cd16b7139a2257ed3bb42f787",
    REWARDS_DISTRIBUTOR: "0x3c25D80F41c41daa574f4dCD3Eaf3C9851962C1a",
    FEE_HANDLER: "0xfF456D9A8cbB5352eF77dEc2337bAC8dEC63bEAC",
    REWARDS_CLAIMER: "0x06ec079a9adc88db708e04e8cf99ae0d629d6541",
  },
  KNC_ADDRESS: "0xbe87E5634f9FC7cD3ED88ad58b1462F3C5A7EB5b",
  OLD_KNC_ADDRESS: "0x7b2810576aa1cce68f2b118cef1f36467c648f92",
  KNC_TOTAL_SUPPLY: 210939347,
  APIS: {
    DAO: "https://dev-dao-stats-v2.knstats.com",
    CACHE: "https://ropsten-cache.knstats.com",
    KN: "https://ropsten-api.kyber.network",
    THE_GRAPH:
      "https://api.thegraph.com/subgraphs/name/namnm1991/kyber-dao-sub-graph",
  },
};

export default envConfig;
