export const historyActionTypes = {
  SET_HISTORIES: "HISTORIES.SET_HISTORIES",
  SET_PAGE: "HISTORIES.SET_PAGE"
};

export function setHistories(histories){
  return {
    type: historyActionTypes.SET_HISTORIES,
    payload: histories
  }
}
export function setPage(page){
  return {
    type: historyActionTypes.SET_PAGE,
    payload: page
  }
}