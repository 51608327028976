import React from 'react';
import { useDispatch } from "react-redux";
import MetamaskService from "src/app/services/accounts/MetamaskService";
import ENV from "src/app/configs/env";
import { clearAccount, importAccount } from "src/app/actions/accountAction";
import { modal } from "src/app/components/Modals/Modal";
import BasicModal from "src/app/components/Modals/BasicModal";
import { fromNetworkIdToName } from "src/app/utils/converters";
import { WALLET_TYPES } from "src/app/configs/constants";

export default function MetamaskAccount(props) {
  const dispatch = useDispatch();

  async function connect() {
    const wallet = new MetamaskService();
    const address = await wallet.connect(openConnectErrorModal, openNetworkErrorModal);

    if (!address) return;

    wallet.getDisconnected(() => dispatch(clearAccount()));

    dispatch(importAccount(address, wallet, WALLET_TYPES.METAMASK));
    modal.close();
  }

  function openConnectErrorModal() {
    modal.show(BasicModal, {
      title: "Error",
      content: <div>Cannot connect to Metamask. Please make sure you have Metamask installed.</div>
    });
  }

  function openNetworkErrorModal(currentNetworkId) {
    modal.show(BasicModal, {
      title: "Error",
      content: (
        <div className="text-center">
          <p className="mb-2">Metamask should be on <b>{fromNetworkIdToName(ENV.NETWORK_ID)}</b>.</p>
          <p>Currently it is on <b>{fromNetworkIdToName(currentNetworkId)}</b> instead.</p>
        </div>
      )
    });
  }

  return (
    <div className={`account__item ${props.className}`} onClick={connect}>
      <div className="account__item-icon metamask"/>
      <div className="account__item-name">Metamask</div>
    </div>
  )
};
