import React, { useState, useEffect } from "react";
import FaqItem from "src/app/components/Faq/FaqItem";
import faqImage1 from "src/assets/images/faq/faq1.png";
import faqImage2 from "src/assets/images/faq/faq2.png";
import faqImage3 from "src/assets/images/faq/faq3.png";
import faqImage4 from "src/assets/images/faq/faq4.png";
import faqImage5 from "src/assets/images/faq/faq5.png";
import faqImage6 from "src/assets/images/faq/faq6.png";
import faqImage7 from "src/assets/images/faq/faq7.png";
import faqImage8 from "src/assets/images/faq/faq8.png";
import faqImage9 from "src/assets/images/faq/faq9.png";
import { modal } from "src/app/components/Modals/Modal";
import BasicModal from "src/app/components/Modals/BasicModal";
import { TUTORIAL_VIDEO_ID } from "src/app/configs/constants";

export default function Faq() {
  const [activeMenu, setActiveMenu] = useState("kyber-dao");
  const [withdrawQuestionOpen, setWithdrawQuestionOpen] = useState(false);

  useEffect(() => {
    function highlightMenu() {
      const stakingOffset = document.getElementById("staking").offsetTop;
      const votingOffset = document.getElementById("voting").offsetTop;
      const otherOffset = document.getElementById("others").offsetTop;
      const currentOffset = window.scrollY + 20;

      if (currentOffset >= otherOffset) {
        setActiveMenu("others");
      } else if (currentOffset >= votingOffset) {
        setActiveMenu("voting");
      } else if (currentOffset >= stakingOffset) {
        setActiveMenu("staking");
      } else {
        setActiveMenu("kyber-dao");
      }
    }

    document.addEventListener("scroll", highlightMenu);

    return () => {
      document.removeEventListener("scroll", highlightMenu);
    };
  }, []);

  function openTutorialVideo() {
    modal.show(BasicModal, {
      title: "KyberDAO Tutorial",
      content: (
        <iframe
          className="mt-3 w-100"
          height="320"
          title="KyberDAO Tutorial"
          src={`https://www.youtube.com/embed/${TUTORIAL_VIDEO_ID}?autoplay=1`}
          frameborder="0"
          allowFullScreen
        />
      ),
      className: "large",
    });
  }

  return (
    <div className="faq container">
      <div className="faq__left">
        <a className={`faq__glossary ${activeMenu === "kyber-dao" ? "active" : ""}`} href="#kyber-dao">
          KyberDAO
        </a>
        <a className={`faq__glossary ${activeMenu === "staking" ? "active" : ""}`} href="#staking">
          Staking
        </a>
        <a className={`faq__glossary ${activeMenu === "voting" ? "active" : ""}`} href="#voting">
          Voting
        </a>
        <a className={`faq__glossary ${activeMenu === "others" ? "active" : ""}`} href="#others">
          Others
        </a>
      </div>

      <div className="faq__right" id="kyber-dao">
        <div className="faq__type">
          <div className="faq__title">KyberDAO</div>

          <FaqItem title="What is KyberDAO?">
            <span className="faq__bold">KyberDAO</span> stands for Kyber Decentralized Autonomous Organization.{" "}
            <span className="faq__bold">KyberDAO</span> is a community platform that empowers KNC (Kyber Network
            Crystal) token holders to participate in the governance of Kyber Network and the{" "}
            <span className="faq__bold">KyberSwap</span> trading platform. KNC holders can stake their tokens to vote on
            important proposals. In return, they receive rewards in KNC from part of the trading fees collected through{" "}
            <span className="faq__bold">KyberSwap</span> trading activities. Voting is done in a fully open and
            transparent manner.
            <br />
            <br />
            KNC is dynamic and can be upgraded, minted, or burned by <span className="faq__bold">KyberDAO</span> to
            better support liquidity and growth. Holding KNC means having a stake in all the important innovation and
            liquidity protocols created for DeFi.
            <img className="faq__item-img" src={faqImage1} alt="What is KyberDAO?" />
          </FaqItem>
          <FaqItem title="Why should I participate in KyberDAO?">
            <span className="faq__bold">KyberDAO</span> allows KNC token holders to play a critical role in determining
            the incentive system, building a wide base of stakeholders, and facilitating economic flow on{" "}
            <span className="faq__bold">KyberSwap</span>. Together, KNC holders can chart the future direction of Kyber.
            <br />
            <br />
            We aim for <span className="faq__bold">KyberDAO</span> to serve 3 key groups of Kyber stakeholders — <br />
            1. <span className="faq__bold">KyberSwap</span> liquidity providers and partners <br />
            2. Dapps/traders who connect to Kyber to use its liquidity <br />
            3. KNC holders who are at the heart of Kyber
            <br />
            <br />
            Participating in the <span className="faq__bold">KyberDAO</span> allows you, a Kyber community member, to
            help contribute towards the success of the project.
            <br />
            <br />
            In return, you are able to earn KNC rewards for your efforts, with rewards coming from a portion of network
            fees collected through trading activities on <span className="faq__bold">KyberSwap</span>. As more trades
            are executed and new protocols added, more rewards are generated.
          </FaqItem>

          <FaqItem title="How do I participate?">
            All you need is an Ethereum wallet and KNC tokens to participate. Simply follow these steps:
            <br />
            1. Open kyber.org
            <br />
            2. Connect your Ethereum wallet
            <br />
            3. Stake KNC to get voting power (or delegate your voting power to someone else e.g. a staking service)
            <br />
            4. Wait for the next voting epoch to start
            <br />
            5. Vote on all proposals
            <br />
            6. After voting, claim your rewards in next epoch
            <br />
            7. Join Kyber’s official{" "}
            <a
              className="faq__item-link"
              href="https://discord.com/invite/NB3vc8J9uv"
              target="_blank"
              rel="noreferrer noopener"
            >
              Discord Server
            </a>
            &nbsp;and the{" "}
            <a className="faq__item-link" href="https://twitter.com/KyberDAO" target="_blank" rel="noreferrer noopener">
              KyberDAO Twitter
            </a>
            &nbsp;account for the latest updates
            {/* <br />
            8. Watch the KyberDAO{" "}
            <span className="faq__item-link" onClick={openTutorialVideo}>
              video tutorial
            </span> */}
          </FaqItem>

          <FaqItem title="Which Ethereum wallet connections are supported?">
            Metamask, Ledger, Trezor, JSON keystore file, Coinbase Link, and WalletConnect are supported.
          </FaqItem>
        </div>

        <div className="faq__type" id="staking">
          <div className="faq__title">Staking</div>

          <FaqItem title="What are the requirements for staking?">
            You just need an Ethereum wallet that holds KNC tokens in order to stake your tokens on kyber.org
          </FaqItem>

          <FaqItem title="How do I stake KNC?">
            Step 1: Visit Kyber.org
            <br />
            Step 2: Click Get Started and Connect your wallet
            <br />
            Step 3: Stake KNC
            <br />
            <img className="faq__item-img" src={faqImage9} alt="Stake KNC?" />
            <br />
            If you stake KNC in epoch “n”, you or your delegate will only be eligible to vote in epoch “n+1” (next epoch
            onwards).
            <br />
            <br />
            <b>Example:</b>
            <img className="faq__item-img" src={faqImage2} alt="How do I stake KNC?" />
          </FaqItem>

          <FaqItem title="Where can I stake KNC?">
            Anyone can stake KNC, vote on proposals, and claim rewards at the official web platform kyber.org. Kyber.org
            is the only official platform managed by the Kyber team. The platform is optimized for mobile and works on
            any web3 and Dapp browser. This means you can indirectly stake KNC on crypto wallets such as Trust, Enjin,
            imToken, Status, and many others etc.
            <br />
            <br />
            In addition, there are other 3rd party staking service providers that you can use to stake KNC. These
            include both non-custodial (e.g. Unagii) and custodial (e.g. centralized exchanges like Binance) options,
            and they have their own trade-offs and requirements.
          </FaqItem>

          <FaqItem title="Is there any minimum / maximum amount I can stake?">No, there is no such limit.</FaqItem>

          <FaqItem title="Is my KNC secure? Are there any risks?">
            The KNC tokens that you stake through the Kyber.org interface will reside in a non-custodial smart contract
            developed by the Kyber Network team, which has been audited by multiple 3rd party professional agencies.
            Additionally, the smart contract has been tested multiple times for various attack scenarios to ensure its
            security.
            <br />
            <br />
            Kyber Network DOES NOT hold your funds at any point in time. As a user, you will always have full control
            over your funds, and you are free to deposit and withdraw your KNC tokens anytime.
            <br />
            <br />
            Important: Staking carries its own set of risks, just like any other decentralized application and new
            technology. Be wary of scammers, phishing attempts, and fake websites. Users should always make sure that
            they visit https://kyber.org/ if they want to stake and vote on their own. Please only stake an amount you
            are comfortable with.
          </FaqItem>

          <FaqItem title="Suppose I stake 10,000 KNC in epoch n, can I stake more in n+1?">
            Yes, you can stake more KNC tokens in epoch n+1, but you will get voting power and rewards based on your
            total KNC staked only in epoch n+2 in this example. In epoch n+1, you will only get the voting power and
            rewards based on 10,000 KNC.
            <br />
            <br />
            <b>Example:</b>
            <br />
            Epoch 12: Tom originally had 10,000 KNC in Epoch 12.
            <br />
            Epoch 13: Tom can vote with 10,000 KNC worth of voting power in this epoch. Tom stakes another 5000 KNC.
            <br />
            Now he has staked 15,000 KNC in total.
            <br />
            Epoch 14: Claims rewards for epoch 13. Votes with 15,000 KNC in voting power.
            <br />
            Epoch 15: Claims rewards for epoch 14. Continue voting with 15,000 KNC in voting power.
            <br />
            <img className="faq__item-img" src={faqImage3} alt="" />
          </FaqItem>

          <FaqItem
            title="What if I withdraw some KNC during the current epoch? How does my voting power change?"
            id="question-withdraw-1"
            isOpened={withdrawQuestionOpen}
            setIsOpened={setWithdrawQuestionOpen}
          >
            You can stake and withdraw within 1 epoch: Suppose in epoch n, You stake X KNC and withdraw Y KNC
            <br />
            - If X &gt; Y → Voting power equivalent to amount (X-Y) KNC will be in effect for the next epoch.
            <br />
            - If X &lt; Y → Your staking amount and voting power will be reduced for the next epoch.
            <br />
            <br />
            <b>Example:</b>
            <br />
            Epoch 15: Tom staked 15,000 KNC.
            <br />
            Epoch 16: Tom voted with 15,000 KNC then withdrew 3000 KNC → Total staked is now 15,000 - 3000 = 12,000 KNC
            <br />
            Epoch 17: Tom can only claim his reward based on 12,000 KNC (not 15,000 KNC). His new voting power will now
            be equivalent to 12,000 KNC.
            <br />
            <img className="faq__item-img" src={faqImage4} alt="" />
          </FaqItem>

          <FaqItem title="Is there any forced lockup period?">
            No. Kyber Network DOES NOT hold your funds at any point in time. As a user, you always have full control
            over your funds, and you are free to deposit and withdraw your KNC tokens anytime. But do note that in order
            to ensure that you receive your full voting power and rewards, you will have to voluntarily stake KNC for
            the entire epoch (voting period).
          </FaqItem>

          <FaqItem title="How much can I earn for staking KNC?">
            It depends. After every epoch, there will be trading fees set aside for voting rewards (from{" "}
            <span className="faq__bold">KyberSwap</span> trading activities). The total amount of rewards is decided by
            two main factors: trade volume and the proportion of trading fees decided by{" "}
            <span className="faq__bold">KyberDAO</span> for voting rewards.
            <br />
            <br />
            The network fee percentage and fee allocation ratio are decided by the{" "}
            <span className="faq__bold">KyberDAO</span>. As an individual KNC staker, your share of the rewards received
            after the epoch will be determined by your voting points (the amount of KNC you have staked during the epoch
            x the number of campaigns you voted on), in proportion to the total voting points of all KNC stakers.
            <br />
            <img className="faq__item-img" src={faqImage6} alt="" />
            You MUST VOTE on all ongoing proposal campaigns in order to receive your full reward in the next epoch.
            Assuming you (and all the other stakers) voted for all the proposal campaigns in that epoch, your share will
            be proportional to your KNC staked vs. the total amount of KNC staked by all voters.
            <br />
            <br />
            If you did not vote, you would not receive any rewards. If you only voted for one but not all the campaigns
            in that epoch, you would receive less than what you actually could.
          </FaqItem>

          <FaqItem title="How do I unstake / withdraw KNC from Kyber.org?">
            Withdrawing of KNC is as simple as staking KNC. Please follow these steps to withdraw KNC:
            <br />
            <br />
            Step 1: Open Kyber.org
            <br />
            Step 2: Connect your wallet
            <br />
            Step 3: Click on your wallet address at top right corner
            <br />
            Step 4: Click on Withdraw
            <br />
            <br />
            Kindly note that withdrawal is an on-chain process so you need to pay for gas fees. Please also note that
            withdrawal will impact your voting power and rewards. Please refer to this section for an example&nbsp;
            <a className="faq__item-link" href="#question-withdraw-1" onClick={() => setWithdrawQuestionOpen(true)}>
              What if I withdraw some KNC during the current epoch? How does my voting power change?
            </a>
          </FaqItem>
        </div>

        <div className="faq__type" id="voting">
          <div className="faq__title">Voting</div>

          <FaqItem title="How do I vote?">
            Voting in <span className="faq__bold">KyberDAO</span> happens fully on the Ethereum blockchain. If you stake
            KNC in epoch “n”, you will be eligible to vote in epoch “n+1” (next epoch onwards).
            <br />
            <br />
            <b>Example:</b>
            <br />
            <img className="faq__item-img" src={faqImage7} alt="" />
            On kyber.org, connect your Ethereum wallet, and go to the Vote tab. You will see the current proposals which
            you can vote on.
            <br />
            <br />
            Weigh your given options, make your choice, and click “Submit vote“. Kindly note that once voting is
            successful, you may CHANGE your vote, but you won’t be able to REMOVE your vote.
          </FaqItem>

          <FaqItem title="How long does a proposal last?">
            It depends, for less critical (short timelock) proposals, it takes approximately 4 days of voting, while for
            more critical (long timelock) proposals, it takes approximately 7 days. Proposals have to start and end
            within the same epoch.
          </FaqItem>

          <FaqItem title="Do I need ETH / gas to vote?">
            Yes, vote submission is done on-chain. As such, you have to pay for the cost of making that on-chain
            transaction.
          </FaqItem>

          <FaqItem title="Can I appoint someone to vote for me?">
            Yes, you can delegate your voting power to someone else. Once you have staked KNC, you have an option for
            delegation. You have to delegate your full KNC stake (no partial delegation) and can only delegate your KNC
            to one voting pool (Ethereum address) at any time. Stakers who delegate their KNC stake are also known as
            pool members.
            <br />
            <br />
            <b>Reminder:</b> If you stake KNC in epoch “n”, you or your delegate will only be eligible to vote in epoch
            “n+1” (next epoch onwards).
            <br />
            <br />
            Go to <b>Stake</b> → <b>Connect your wallet</b> → Paste the Ethereum address that you want to delegate
            voting power to → Click <b>‘Delegate’</b>
            <br />
            <br />
            <b>Important:</b> In this default delegation method, your delegate is responsible for voting on your behalf
            and distributing your KNC rewards to you (though your delegate can’t touch your own staked KNC). Kyber
            Network does not hold your funds or manage this process. <span className="faq__bold">KyberDAO</span> voting
            is operated using the blockchain and is fully transparent and verifiable.
            <img className="faq__item-img" src={faqImage5} alt="" />
          </FaqItem>

          <FaqItem title="How does KyberDAO determine the power of each vote?">
            Your voting power is determined by the amount of KNC you have staked, in proportion to the total amount of
            KNC staked in the <span className="faq__bold">KyberDAO</span> and used for voting on proposals.
          </FaqItem>

          <FaqItem title="Is voting transparent? How do we know the result is accurate and fair?">
            Yes, the voting process is fully transparent. Since voting is an on-chain operation, it will have a
            transaction hash and other details that will be available for anyone to review on etherscan. Results are
            achieved through a democratic process - proposal options with the highest voting points win.
            <br />
            <br />
            Once the proposal campaign period is over, votes submitted are considered final. But there is a delay period
            prior to the execution of the proposal to provide time for the Kyber community and DAO maintainer to
            highlight any major issues. For less critical (short timelock) proposals, the delay is 12 hours, while for
            more critical (long timelock) proposals, the delay is 7 days.
            <br />
            <br />
            For the latest governance parameters, please read
            https://github.com/KyberNetwork/KIPs/blob/master/KIPs/kip-19.md.
          </FaqItem>

          <FaqItem title="How are voting and rewards linked?">
            2 simple rules:
            <br />
            (A) You MUST VOTE to get the reward, and
            <br />
            (B) You must vote in ALL the ongoing proposals to get your full reward for that particular epoch.
            <br />
            <br />
            If the epoch has 2 proposals, but you voted in only 1 proposal, you will get only half of your rewards.
          </FaqItem>

          <FaqItem title="How do I claim rewards?">
            On kyber.org, connect your Ethereum wallet, and go to the Reward tab. You are able to view your unclaimed
            rewards or all your (claimed + unclaimed) rewards.
            <br />
            <br />
            To be eligible for 100% of your share of the rewards, you need to have staked KNC, participate in every DAO
            vote, and have your KNC staked for the entire epoch. If you have delegated your voting power to someone
            else, your delegate must have voted on your behalf.
          </FaqItem>

          <FaqItem title="What currency are rewards distributed in?">
            Rewards are distributed in KNC. Trading fees collected from <span className="faq__bold">KyberSwap</span>{" "}
            trading activity are in the form of LP (liquidity provider) tokens which are subsequently converted to KNC
            at an appropriate time to be distributed to voters.
          </FaqItem>

          <FaqItem title="When can I claim my rewards?">
            Stakers can claim their eligible rewards from previous epochs whenever they want. In general, rewards are
            generated after every 1-2 epochs (2-4 weeks), assuming you have voted in the previous epochs. If you
            delegate your vote to someone else, it is up to them to decide how often they want to distribute rewards to
            you.
          </FaqItem>

          <FaqItem title="Do I get rewards if I don’t vote?">
            No. Consider delegating your vote to a 3rd party <span className="faq__bold">KyberDAO</span> pool operator
            (e.g. Unagii or Binance) who will vote on your behalf, so that you are able to get rewards!
          </FaqItem>
        </div>

        <div className="faq__type" id="others">
          <div className="faq__title">Others</div>

          <FaqItem title="What is a delegation/staking service?">
            Delegation or Staking services are 3rd party entities that help KNC stakers cast votes on their behalf and
            help them earn staking rewards.
            <br />
            <br />
            Default delegation on Kyber.org
            <br />
            <br />
            <b>Example:</b>
            <br />
            Alice staked 1000 KNC and delegated her voting power to Bob’s Ethereum address. Bob cast votes on Alice’s
            behalf. KNC rewards will be distributed to Bob’s Ethereum address. Bob takes his commission and transfers
            the remaining rewards to Alice. Bob can’t withdraw Alice’s staked KNC but KNC rewards will be given to Bob
            after he votes.
            <br />
            <br />
            <b>Important:</b> In this default delegation method, your delegate is responsible for voting on your behalf
            and distributing your KNC rewards to you, though only you can withdraw/unstake your own KNC. Kyber Network
            does not hold your funds or manage this process.
          </FaqItem>

          <FaqItem title="What is the delegation fee?">
            The percentage of KNC voting rewards that 3rd-party delegation/staking services can choose to keep in
            exchange for voting on your behalf or providing other delegation services. This is up to the delegation
            service.
            <br />
            <br />
            There is no charge when you manually stake and vote on your own (without using a 3rd-party service) on the
            default kyber.org interface.
          </FaqItem>

          <FaqItem title="Is there any ‘slashing’ involved like other staking systems?">
            No. You will always be able to withdraw the full KNC capital deposited.
          </FaqItem>

          <FaqItem title="Do I need to run a node to stake?">
            No. Just connect your Ethereum wallet to kyber.org and stake KNC there.
          </FaqItem>

          <FaqItem title='What is "block time"?'>
            The time between two blocks of the validated chain (Ethereum in the case of KyberDAO). It’s approximately
            15-16 seconds for Ethereum blockchain.
          </FaqItem>

          <FaqItem title='What is an "epoch"?'>
            An epoch is a time period. <span className="faq__bold">KyberDAO</span> operations are divided into epochs.
            For example: proposals for voting can be submitted within a duration of 1 epoch, which is about 2 weeks.
            <br />
            <br />
            Within an epoch, for less critical (short timelock) proposals, it requires approximately 4 days of voting,
            while for more critical (long timelock) proposals, it takes approximately 7 days. Proposals have to start
            and end within the same epoch.
          </FaqItem>

          <FaqItem title='What is "Total Staked" and "Current Epoch Votes"?'>
            ‘Total staked’ means how much of total KNC supply is currently staked in the{" "}
            <span className="faq__bold">KyberDAO</span>. Currently, the new version of KNC has a total supply of about
            ~200M. If total KNC staked is at 20%, it means ~40M KNC are staked.
            <br />
            <br />
            ‘Current epoch votes’ represents the % of the total staked KNC that has been used to vote on proposals.
          </FaqItem>

          <FaqItem title="What if I have more questions?">
            Join Kyber’s official{" "}
            <a
              className="faq__item-link"
              href="https://discord.com/invite/NB3vc8J9uv"
              target="_blank"
              rel="noreferrer noopener"
            >
              Discord Server
            </a>
            &nbsp;and the{" "}
            <a className="faq__item-link" href="https://twitter.com/KyberDAO" target="_blank" rel="noreferrer noopener">
              KyberDAO Twitter
            </a>
            &nbsp;account for the latest updates.
          </FaqItem>
        </div>
      </div>
    </div>
  );
}
