import { useState, useEffect } from 'react';

export default function useCountDown(timeLeft) {
  const [secondLeft, setSecondLeft] = useState(null);
  const calculatedTimeLeft = secondLeft !== null ? secondLeft : timeLeft + 60;

  useEffect(() => {
    if (timeLeft > 0 && timeLeft <= 60 && secondLeft === null) {
      setSecondLeft(timeLeft);
    } else if (timeLeft > 60) {
      setSecondLeft(null);
    }
  }, [timeLeft]); // eslint-disable-line

  useEffect(() => {
    const countDownTimeout = setTimeout(() => {
      if (secondLeft <= 0) return;
      setSecondLeft(secondLeft - 1);
    }, 1000);

    return () => clearTimeout(countDownTimeout);
  }, [secondLeft]); // eslint-disable-line

  return calculatedTimeLeft < 0 ? 0 : calculatedTimeLeft;
}
