import { call, put, select, takeLatest } from "redux-saga/effects";
import { withdrawActionTypes, setWithdrawError } from "src/app/actions/withdrawAction";
import { setLastTx, setTxConfirming } from "src/app/actions/accountAction";
import Web3Service from "src/app/services/web3/Web3Service";
import { ACTIONS } from "src/app/configs/constants";

const getAccountState = (state) => state.account;
const getGlobalState = (state) => state.global;

function* withdraw(action) {
  const { address, wallet, lastTx, privateKey, devicePath } = yield select(getAccountState);
  const { gasPrices, selectedGasPrice, estimatedGas } = yield select(getGlobalState);
  const { amount } = action.payload;

  yield put(setWithdrawError(''));
  yield put(setTxConfirming(true));

  try {
    const web3Service = new Web3Service();

    const nonce = yield call(web3Service.fetchUsableNonce, address, lastTx.nonce);
    let txObject = web3Service.getWithdrawTxObject(address, amount, nonce, gasPrices[selectedGasPrice], estimatedGas);
    const txHash = yield call(wallet.makeTransaction, txObject, privateKey, devicePath);

    yield put(setLastTx(txHash, nonce, ACTIONS.WITHDRAW, amount));
  } catch(e) {
    yield put(setWithdrawError(typeof e === 'string' ? e : e.message));
  }

  yield put(setTxConfirming(false));
}

export default function* withdrawWatcher() {
  yield takeLatest(withdrawActionTypes.WITHDRAW, withdraw);
}
