import React, { useState, useEffect, Fragment } from "react";
import Loading from "src/app/components/Commons/Loading";
import SlideDown, { SlideDownContent, SlideDownTrigger } from "src/app/components/Commons/SlideDown";
import { formatAddress, roundNumber } from "src/app/utils/fortmaters";
import { LIMIT } from "src/app/configs/constants";

export default function DeviceSelectAddress(props) {
  const [addressIndex, setAddressIndex] = useState(0);
  const [addressList, setAddressList] = useState([]);
  const [addressDropdownStatus, setAddressDropdownStatus] = useState(false);
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const [customPath, setCustomPath] = useState('');
  const isLoading = addressList.length === 0 || isBalanceLoading;

  useEffect(() => {
    async function getAddressFromPath() {
      const addresses = await props.wallet.getAddresses(addressIndex, props.selectedPath);
      setAddressList(addresses);
      setAddressBalances(addresses);
    }

    getAddressFromPath();
  }, [addressIndex]); // eslint-disable-line

  async function setAddressBalances(addresses) {
    setIsBalanceLoading(true);

    const addressesWithBalance = await props.wallet.getBalances(addresses);

    setAddressList(addressesWithBalance);
    setIsBalanceLoading(false);
  }

  function handleSetAddressIndex(direction) {
    let index = addressIndex;

    if (direction === 'next') {
      index += LIMIT.DEVICE_ADDRESS;
    } else {
      index -= LIMIT.DEVICE_ADDRESS;
    }

    if (props.selectedPath.bip44) {
      setAddressList([]);
    }

    setAddressIndex(index);
  }

  function handleToggleDropdown() {
    setAddressDropdownStatus(!addressDropdownStatus);
  }

  function handleCloseDropdown() {
    setAddressDropdownStatus(false);
  }

  function handleSelectPath(path) {
    props.onSelectPath(path);
    handleCloseDropdown();
  }

  return (
    <div className="device-modal">
      <div className="device-modal__content">
        <div className="device-modal__title">Select {props.deviceType} address</div>
        <div className="device-modal__sub-title">Select HD derivation path</div>

        <SlideDown active={addressDropdownStatus}>
          <SlideDownTrigger toggleContent={handleToggleDropdown}>
            <div className="device-modal__dropdown-trigger">{props.selectedPath.value} - {props.selectedPath.desc}</div>
          </SlideDownTrigger>

          <SlideDownContent className="device-modal__dropdown-content" status={addressDropdownStatus} close={handleCloseDropdown}>
            {props.paths.map((path, index) => {
              const selectedClass = props.selectedPath.value === path.value ? 'selected' : '';
              return (
                <Fragment key={index}>
                  {!path.custom && (
                    <div className={`device-modal__dropdown-item ${selectedClass}`} onClick={() => handleSelectPath(path)}>
                      <div className="mb-1">{path.value}</div>
                      <div>{path.desc}</div>
                    </div>
                  )}

                  {path.custom && (
                    <div className="device-modal__dropdown-item">
                      <input type="text" value={customPath} placeholder={path.defaultValue} onChange={(e) => setCustomPath(e.target.value)}/>
                      <div
                        className="device-modal__dropdown-button"
                        onClick={() => handleSelectPath({ ...path, value: customPath ? customPath : path.defaultValue })}
                      />
                    </div>
                  )}
                </Fragment>
              )
            })}
          </SlideDownContent>
        </SlideDown>

        {addressList.length === 0 && (
          <div className="text-center">
            <Loading/>
          </div>
        )}

        {addressList.length > 0 && (
          <>
            <div className="device-modal__select">Select the address you would like to use</div>
            <div className="device-modal__item-container">
              {addressList.map((address, index) => {
                return (
                  <div className="device-modal__item" key={index}>
                    <div className="device-modal__item-address">{formatAddress(address.address)}</div>
                    <div className="device-modal__item-data">
                      <div className="device-modal__item-value">
                        {address.balance ? `${roundNumber(address.balance, 4)} KNC` : <Loading/>}
                      </div>
                      <div
                        className={`device-modal__item-button common__button common__button--hollow ${isBalanceLoading ? 'common__disabled' : ''}`}
                        onClick={() => props.onSelectAddress(address, props.wallet)}
                      >
                        Import
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
      <div className="device-modal__panel">
        <div
          className={`device-modal__panel-button back ${isLoading || addressIndex === 0 ? 'common__disabled' : ''}`}
          onClick={() => handleSetAddressIndex('back')}
        />
        <div
          className={`device-modal__panel-button next ${isLoading ? 'common__disabled' : ''}`}
          onClick={() => handleSetAddressIndex('next')}
        />
      </div>
    </div>
  )
}
