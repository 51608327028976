import { REHYDRATE } from 'redux-persist/lib/constants';
import { accountActionTypes } from 'src/app/actions/accountAction';
import { getWalletByType } from "src/app/utils/converters";

const initialState = {
  init: true,
  address: null,
  privateKey: null,
  devicePath: null,
  wallet: null,
  type: null,
  keyString: null,
  balance: {
    ETH: 0,
    KNC: 0,
    stakedKNC: 0,
    isLoading: false
  },
  lastTx: {
    hash: null,
    nonce: null,
    type: null
  },
  txConfirming: false,
  accountInfo: null
};

export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case REHYDRATE: {
      const lastData = action.payload;

      if (action.key === 'account' && lastData && lastData.type) {
        const wallet = getWalletByType(lastData.address, lastData.type);
        if (wallet) return { ...state, wallet };
      }

      return { ...state }
    }
    case accountActionTypes.IMPORT_ACCOUNT: {
      const { address, wallet, type, accountParams } = action.payload;

      return {
        ...state,
        address: address,
        wallet: wallet,
        privateKey: accountParams.privateKey,
        devicePath: accountParams.devicePath,
        keyString: accountParams.keyString,
        type: type
      }
    }
    case accountActionTypes.CLEAR_ACCOUNT: {
      return initialState;
    }
    case accountActionTypes.CLEAR_PRIVATEKEY: {
      return {...state, privateKey: null};
    }
    case accountActionTypes.SET_INIT_ACCOUNT_STATE: {
      const { initState } = action.payload;

      return {
        ...state,
        init: initState,
      }
    }
    case accountActionTypes.SET_BALANCE_LOADING: {
      return {
        ...state,
        balance: {
          ...state.balance,
          isLoading: action.payload
        }
      }
    }
    case accountActionTypes.SET_ACCOUNT_BALANCE: {
      const { ETHBalance, KNCBalance, KNCBalanceOld, stakedKNCBalance } = action.payload;

      return {
        ...state,
        balance: {
          ETH: ETHBalance,
          KNC: KNCBalance,
          KNCOld: KNCBalanceOld,
          stakedKNC: stakedKNCBalance
        }
      }
    }
    case accountActionTypes.SET_LAST_TX: {
      const { hash, nonce, type} = action.payload;

      return {
        ...state,
        lastTx: { hash, nonce, type }
      }
    }
    case accountActionTypes.SET_TX_CONFIRMING: {
      return {
        ...state,
        txConfirming: action.payload
      }
    }
    case accountActionTypes.SET_ACCOUNT_INFO: {
      return {
        ...state,
        accountInfo: action.payload
      }
    }
    case accountActionTypes.SET_PENDING_STAKE_AMOUNT: {
      return {
        ...state,
        accountInfo: {
          ...state.accountInfo,
          pending_stake_amount: +action.payload
        }
      }
    }
    default:
      return state;
  }
}
