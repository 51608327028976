import { campaignActionTypes } from "src/app/actions/campaignAction";

const initialState = {
  activeCampaigns: [],
  upcomingCampaigns: [],
  endedCampaigns: [],
  unvotedCampaigns: 0,
  proposalMessage: null,
};

export default function campaignReducer(state = initialState, action) {
  switch (action.type) {
    case campaignActionTypes.SET_ACTIVE_CAMPAIGNS: {
      return {
        ...state,
        activeCampaigns: action.payload,
      }
    }
    case campaignActionTypes.SET_UPCOMING_CAMPAIGNS: {
      return {
        ...state,
        upcomingCampaigns: action.payload,
      }
    }
    case campaignActionTypes.SET_ENDED_CAMPAIGNS: {
      return {
        ...state,
        endedCampaigns: action.payload,
      }
    }
    case campaignActionTypes.SET_UNVOTED_CAMPAIGNS: {
      return {
        ...state,
        unvotedCampaigns: action.payload,
      }
    }
    case campaignActionTypes.SET_PROPOSAL_MESSAGE: {
      return {
        ...state,
        proposalMessage: action.payload,
      }
    }
    default:
      return state;
  }
}
