import { takeLatest, call, put, select, delay } from "redux-saga/effects";
import {
  accountActionTypes,
  setAccountBalance,
  setBalanceLoading,
  setInitAccountState,
} from "src/app/actions/accountAction";
import Web3Service from "src/app/services/web3/Web3Service";
import ENV from "src/app/configs/env";
import { formatBigNumber } from "src/app/utils/fortmaters";

const getAccountState = (state) => state.account;

function* fetchAccountBalance(action) {
  yield put(setBalanceLoading(true));

  try {
    const addressParam = action.payload;
    const web3Service = new Web3Service();

    const ETHBalance = yield call(web3Service.fetchETHBalance, addressParam);
    const KNCBalance = yield call( web3Service.fetchTokenBalance, addressParam, ENV.KNC_ADDRESS );
    const KNCBalanceOld = yield call( web3Service.fetchTokenBalance, addressParam, ENV.OLD_KNC_ADDRESS );
    const stakedKNCBalance = yield call(
      web3Service.fetchStakedBalance,
      addressParam
    );
    const { address } = yield select(getAccountState);

    if (!address) return;

    yield put(
      setAccountBalance(
        formatBigNumber(ETHBalance),
        formatBigNumber(KNCBalance),
        formatBigNumber(KNCBalanceOld),
        formatBigNumber(stakedKNCBalance)
      )
    );

    yield put(setBalanceLoading(false));
    yield put(setInitAccountState(false));
  } catch (e) {
    yield delay(2000);
    yield call(fetchAccountBalance, action);
  }
}

export default function* accountWatcher() {
  yield takeLatest(
    accountActionTypes.FETCH_ACCOUNT_BALANCE,
    fetchAccountBalance
  );
}
