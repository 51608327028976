export const executeActionTypes = {
    EXECUTE: 'EXECUTE.SUBMIT',
    SET_EXECUTE_ERROR: 'EXECUTE.SET_EXECUTE_ERROR',
  };
  
  export function execute(campID) {
    return {
      type: executeActionTypes.EXECUTE,
      payload: { campID }
    }
  }
  
  export function setExecuteError(error) {
    return {
      type: executeActionTypes.SET_EXECUTE_ERROR,
      payload: error
    }
  }
  