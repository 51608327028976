import React from "react";

export default function IcHistory() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.25 8L2.75 9.5L1.25 8"
        stroke="#868787"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2.75012 8C2.75012 8.46267 2.80745 8.91067 2.90678 9.34333"
        stroke="#868787"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.75 8C14.75 4.686 12.064 2 8.75 2C5.436 2 2.75 4.686 2.75 8"
        stroke="#868787"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.75 14C12.064 14 14.75 11.314 14.75 8"
        stroke="#868787"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.83594 11.4368C4.9206 12.9854 6.71527 14.0001 8.74994 14.0001"
        stroke="#868787"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.9977 9.91415L8.56299 8.46549V5.32349"
        stroke="#868787"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
