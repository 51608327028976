import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { clearAccount, fetchAccountBalance } from "src/app/actions/accountAction";
import { formatAddress, roundNumber } from "src/app/utils/fortmaters";
import ENV from "src/app/configs/env";
import { modal } from "src/app/components/Modals/Modal"
import WithdrawConfirmModal from "src/app/components/Modals/Withdraw/WithdrawConfirmModal";
import Loading from 'src/app/components/Commons/Loading';
import Delegate from "src/app/components/Delegate/Delegate";
import { ACTIONS, TOPICS, WITHDRAW_STEPS, WALLET_TYPES, MOBILE_SCREEN_SIZE } from "src/app/configs/constants";
import BroadcastModal from "src/app/components/Modals/BroadcastModal";
import WithdrawSuccessModal from "src/app/components/Modals/Withdraw/WithdrawSuccessModal";
import BasicModal from 'src/app/components/Modals/BasicModal';
import copyIcon from "src/assets/images/icons/copy-2.svg";
import qrIcon from "src/assets/images/icons/qr.svg";
import QRCode from "qrcode.react";
import Tooltip from "src/app/components/Commons/Tooltip";
import StakeContainer from '../Stake/StakeContainer';
import { toggleAccountModal } from "src/app/actions/globalAction";
import useCheckAccountImported from '../Hooks/useCheckAccountImported';
import informationIcon from "src/assets/images/icons/information.svg";

export default function AccountData() {
  const dispatch = useDispatch();
  const { address, wallet, balance, init, lastTx, type, accountInfo } = useSelector((state) => state.account);
  const { openActionModal } = useCheckAccountImported();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [withdrawStep, setWithdrawStep] = useState(WITHDRAW_STEPS.DEFAULT);
  const [isAddressCopied, setIsAddressCopied] = useState(false);
  const [isOpenStakeModal, setIsOpenStakeModal] = useState(false);

  const isSendingWithdrawTx = lastTx.type === ACTIONS.WITHDRAW && withdrawStep === WITHDRAW_STEPS.CONFIRM;

  useEffect(() => {
    dispatch(fetchAccountBalance(address));
  }, [address, dispatch]);

  useEffect(() => {
    if (isOpenModal) {
      modal.show(BasicModal, {
        title: "Change Wallet",
        content: <div>Do you want to connect another Wallet?</div>,
        submit: changeWallet,
      }, () => setIsOpenModal(false));
    }
  }, [isOpenModal]); // eslint-disable-line

  useEffect(() => {
    if (!isSendingWithdrawTx) return;
    setWithdrawStep(WITHDRAW_STEPS.BROADCAST);
  }, [lastTx]); // eslint-disable-line

  useEffect(() => {
    if (withdrawStep === WITHDRAW_STEPS.CONFIRM) {
      modal.show(WithdrawConfirmModal, {}, resetWithdrawStep);
    } else if (withdrawStep === WITHDRAW_STEPS.BROADCAST) {
      modal.show(BroadcastModal, {
        txHash: lastTx.hash,
        topic: TOPICS.WITHDRAW,
        nextStep: () => setWithdrawStep(WITHDRAW_STEPS.SUCCESS)
      }, resetWithdrawStep);
    } else if (withdrawStep === WITHDRAW_STEPS.SUCCESS) {
      modal.show(WithdrawSuccessModal, {}, resetWithdrawStep);
    }
  }, [withdrawStep]); // eslint-disable-line

  function openStakeModal() {
    openActionModal(() => setIsOpenStakeModal(true));
  }

  function openWithdrawModal() {
    openActionModal(() => setWithdrawStep(WITHDRAW_STEPS.CONFIRM));
  }

  function resetWithdrawStep() {
    setWithdrawStep(WITHDRAW_STEPS.DEFAULT);
  }

  function changeWallet() {
    dispatch(clearAccount());

    if (typeof wallet.clearSession === 'function') {
      wallet.clearSession();
    }

    modal.close();

    if (window.innerWidth < MOBILE_SCREEN_SIZE) {
      setIsOpenModal(false);
    } else {
      dispatch(toggleAccountModal());
    }
  }

  function renderBalance(balanceKey, tokenSymbol = false) {
    const symbol = tokenSymbol ? tokenSymbol : balanceKey;
    return init ? <><Loading /> {symbol}</> : `${roundNumber(balance[balanceKey], 4, true)} ${symbol}`;
  }

  function copyAddress() {
    if (isAddressCopied) return;
    navigator.clipboard.writeText(address);
    setIsAddressCopied(true);
    setTimeout(function () {
      setIsAddressCopied(false);
    }, 2000);
  }

  function scanAddressQR() {
    modal.show(BasicModal, {
      title: "Scan Address QR",
      content: (
        <div className="mt-4 text-center">
          <QRCode value={address} />
        </div>
      ),
      className: 'small'
    });
  }

  return (
    <div>
      <div className="account__block">
        <div className="account__title">My Wallet</div>

        <div className="common__flex mb-2">
          <div className="account__address">
            <span className="text-capitalize">{wallet.getWalletName()}:&nbsp;</span>
            <a className="mr-2" href={`${ENV.URLS.ETHERSCAN}/address/${address}`} target="_blank" rel="noopener noreferrer">
              {formatAddress(address, 5)}
            </a>
            <div>
              <Tooltip
                trigger={<img className="account__icon" src={copyIcon} alt="Copy" />}
                content={isAddressCopied ? 'Copied' : 'Copy to Clipboard'}
                onClick={copyAddress}
              />
              <img className="account__icon ml-2" src={qrIcon} alt="QR" onClick={scanAddressQR} data-close-dropdown={true} />
            </div>
          </div>

          {type !== WALLET_TYPES.DAPP && (
            <div className="common__link common__to-bottom" data-close-dropdown={true} onClick={() => setIsOpenModal(true)}>
              Change
            </div>
          )}
        </div>
        <div className="account__balance mb-1">{renderBalance('KNC')}</div>
        <div className="account__balance">{renderBalance('ETH')}</div>
      </div>

      <div className="account__block">
        <div className="common__flex">
          <div className="account__title">My Stake</div>
          {+balance.stakedKNC !== 0 ? (
            <div className="common__link mt-1" onClick={openWithdrawModal} data-close-dropdown={true}>
              Withdraw
            </div>
          ) : (
            <div className="common__link mt-1" onClick={openStakeModal} data-close-dropdown={true}>
              Stake
            </div>
          )}
        </div>
        <div className="common__flex-align-center">
          <div className="account__balance mr-2">{renderBalance('stakedKNC', 'KNC')}</div>
        </div>

        {+balance.stakedKNC === 0 &&
          <div className="common__note">
            <span>You must stake KNC in the current epoch in order to vote in the next epoch.</span>
          </div>
        }
      </div>

      {(accountInfo && accountInfo.delegated_stake_amount > 0) && (
        <div className="account__block">
          <div className="common__flex-align-center mb-2">
            <div className="account__title mb-0">Total delegated to you</div>
            <Tooltip
              trigger={<img src={informationIcon} alt="Information" />}
              content={'Total KNC delegated to you. KNC delegated in the previous Epochs contributes to voting power in the current Epoch.'}
              longText={true}
            />
          </div>
          <div className="common__flex-align-center">
            <div className="account__balance mr-2">
              <span>{roundNumber(accountInfo.delegated_stake_amount, 4, true)} KNC</span>
            </div>
          </div>
        </div>
      )}

      <Delegate />

      <StakeContainer
        isOpenStakeModal={isOpenStakeModal}
        onCloseModal={() => setIsOpenStakeModal(false)}
      />
    </div>
  )
};
