export const migrateActionTypes = {
    MIGRATE: 'MIGRATE.MIGRATE',
    SET_ERROR: 'MIGRATE.SET_ERROR'
  };
  
  export function migrate(migrateAmount, gasPrice, gas) {
    return {
      type: migrateActionTypes.MIGRATE,
      payload: { migrateAmount, gasPrice, gas }
    }
  }
  
  export function setMigrateError(error) {
    return {
      type: migrateActionTypes.SET_ERROR,
      payload: error
    }
  }
  