import { call, put, select, takeLatest } from "redux-saga/effects";
import { approveActionTypes, setApproveError } from "src/app/actions/approveAction";
import { setLastTx, setTxConfirming } from "src/app/actions/accountAction";
import Web3Service from "src/app/services/web3/Web3Service";
import { ACTIONS } from "src/app/configs/constants";

const getAccountState = (state) => state.account;
const getGlobalState = (state) => state.global;

function* approveToken(action) {
  const { address, wallet, lastTx, privateKey, devicePath } = yield select(getAccountState);
  const { gasPrices, selectedGasPrice, estimatedGas } = yield select(getGlobalState);
  const { isApproveToMax, token, delegator } = action.payload;

  yield put(setApproveError(''));
  yield put(setTxConfirming(true));
  try {
    const web3Service = new Web3Service();
    const nonce = yield call(web3Service.fetchUsableNonce, address, lastTx.nonce);
    const approveTxObject = web3Service.getApproveTxObject(address, isApproveToMax, nonce, gasPrices[selectedGasPrice], estimatedGas, token, delegator);
    const txHash = yield call(wallet.makeTransaction, approveTxObject, privateKey, devicePath);

    yield put(setLastTx(txHash, nonce, ACTIONS.APPROVE));
  } catch(e) {
    yield put(setApproveError(typeof e === 'string' ? e : e.message));
  }

  yield put(setTxConfirming(false));
}

export default function* approveWatcher() {
  yield takeLatest(approveActionTypes.APPROVE_TOKEN, approveToken);
}
