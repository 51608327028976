import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { closeModals } from "src/app/actions/globalAction";

export default function Modal(props) {
  useEffect(() => {
    if (props.isActive) {
      document.querySelector('body').classList.add('is-modal-open');
    }
    return () => {
      document.querySelector('body').classList.remove('is-modal-open');
    }
  }, [props.isActive]);

  function handleClose(e) {
    if (e.target === e.currentTarget) {
      props.onClose();
    }
  }

  return (
    <>
      {props.type !== 'page' ? (
        <div className={"modal-overlay" + (props.isActive ? " modal-overlay--active" : "")} onClick={handleClose}>
          <div className={`modal ${props.isActive ? "modal--active" : ""}`}>
            <div className={"modal__content"}>
              {!props.hideCloseButton && (
                <div className={"modal__close-btn"} onClick={handleClose}>×</div>
              )}
              {props.children}
            </div>
          </div>
        </div>
      ) : (
        <>{props.children}</>
      )}
    </>
  )
}

const modal = {
  currentHandler: null,
  closeFn: null,
  reg: function (fn, closeFn) {
    this.currentHandler = fn;
    this.closeFn = closeFn;
  },
  show: function (Component, props = {}, onClose = null) {
    this.currentHandler(
      (close) => <Component {...{ close }} {...props} />,
      onClose,
      props,
    )
  },
  close: function () {
    this.closeFn(true)
  }
};

const ModalListener = () => {
  const dispatch = useDispatch();
  const [modalContent, setModalContent] = useState("");
  const [onClose, setOnClose] = useState(null);
  const [props, setProps] = useState({});

  const close = (closeAll = false) => {
    if (closeAll) dispatch(closeModals());
    if (onClose != null) onClose();
    setModalContent("");
    setOnClose(null);
  };

  useEffect(() => {
    modal.reg((content, onClose, modalProps) => {
      setOnClose(() => onClose);
      setProps(modalProps);
      setModalContent(typeof content === "function" ? content(() => {
        if (onClose != null) onClose();
        setModalContent("");
      }) : content);
    }, close)
  }, []); // eslint-disable-line

  return (
    <Modal isActive={!!modalContent} {...props} onClose={close}>
      {modalContent}
    </Modal>
  )
};

export { modal, ModalListener }
