import {queueActionTypes} from "src/app/actions/queueAction";

const initialState = {
  init: true,
  queueError: '',
};

export default function queueReducer(state = initialState, action) {
  switch (action.type) {
    case queueActionTypes.SET_QUEUE_ERROR: {
      return {
        ...state,
        queueError: action.payload
      }
    }
    default:
      return state
  }
}
