import {executeActionTypes} from "src/app/actions/executeAction";

const initialState = {
  init: true,
  executeError: '',
};

export default function executeReducer(state = initialState, action) {
  switch (action.type) {
    case executeActionTypes.SET_EXECUTE_ERROR: {
      return {
        ...state,
        executeError: action.payload
      }
    }
    default:
      return state
  }
}
