import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { TX_STATUS, ACTIONS } from "src/app/configs/constants";
import Loading from "src/app/components/Commons/Loading";
import { updateTxRead } from "src/app/actions/txAction";
import { formatAddress, roundNumber } from "src/app/utils/fortmaters";
import ENV from "src/app/configs/env";
import { getKeyByValue } from "src/app/utils/converters";

export default function Notification() {
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.txs);
  const { address } = useSelector(state => state.account);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate();
    }, 1000 * 60);
    return () => clearInterval(interval);
  });

  function renderTxStatus(status) {
    switch (status) {
      case TX_STATUS.PENDING:
        return <Loading />;
      case TX_STATUS.SUCCESS:
        return <span className="text-success">{status}</span>;
      case TX_STATUS.FAILED:
        return <span className="text-danger">{status}</span>;
      default:
        return null;
    }
  }

  function renderTitle(tx) {
    switch (tx.type) {
      case ACTIONS.STAKE:
      case ACTIONS.WITHDRAW:
        return <span>{roundNumber(tx.amount, 4, true)} KNC</span>;
      case ACTIONS.DELEGATE:
        if (address === tx.delegatedAddress) {
          return <span>Remove delegate</span>;
        }
        return <span>Delegate to {formatAddress(tx.delegatedAddress, 4, -4)}</span>;
      default:
        return <span>Transaction {getKeyByValue(ACTIONS, tx.type)}</span>;
    }
  }

  function handleClickNoti(tx) {
    dispatch(updateTxRead(tx.hash, 1));
    window.open(`${ENV.URLS.ETHERSCAN}/tx/${tx.hash}`);
  }

  function renderTxs() {
    return (
      <>
        {data.map((tx, i) => (
          <div
            className={`notification__item pointer ${tx.read ? "" : "active"}`}
            key={i}
            onClick={() => handleClickNoti(tx)}
          >
            <div className="d-flex justify-content-between">
              <div className="text-dark">{renderTitle(tx)}</div>
              <div className="text-uppercase">{getKeyByValue(ACTIONS, tx.type)}</div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="text-gray-bold font-sm">{formatAddress(tx.hash, 10, -10)}</div>
              <div className="font-xs text-uppercase">{renderTxStatus(tx.status)}</div>
            </div>
            <div className="font-xs text-gray-bold font-weight-normal">
              {moment(tx.timestamp).fromNow()}
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <div className="notification font-weight-medium">
      <div className="notification__title">Your Notifications</div>
      <div className="notification__content nice-scroll">
        {data.length > 0 ? (
          renderTxs()
        ) : (
          <div className="notification__item text-center font-weight-normal">Nothing here</div>
        )}
      </div>
    </div>
  );
}
