import React, { useState, useEffect } from 'react';
import SlideDown, { SlideDownContent, SlideDownTrigger } from "src/app/components/Commons/SlideDown";
import { useDispatch, useSelector } from "react-redux";
import { setEstimatedGas, setSelectedGasPrice } from "src/app/actions/globalAction";
import Web3Service from "src/app/services/web3/Web3Service";
import { calculateTxFee } from "src/app/utils/calculators";
import Tooltip from "src/app/components/Commons/Tooltip";

export default function GasOption(props) {
  const dispatch = useDispatch();
  const { gasPrices, selectedGasPrice } = useSelector(state => state.global);

  const [isOpened, setIsOpened] = useState(props.isOpened || false);
  const [gasLimit, setGasLimit] = useState(props.defaultGasLimit);

  useEffect(() => {
    async function fetchEstimatedGasLimit() {
      dispatch(setEstimatedGas(props.defaultGasLimit));

      const web3Service = new Web3Service();
      const estimatedGasLimit = await web3Service.estimatedGasByType(props.txType, props.txParams);

      setGasLimit(estimatedGasLimit);
      dispatch(setEstimatedGas(estimatedGasLimit));
    }

    fetchEstimatedGasLimit();
  }, []); // eslint-disable-line

  function toggleDropdown() {
    setIsOpened(!isOpened);
  }

  function closeDropdown() {
    setIsOpened(false);
  }

  function dispatchSetSelectedGasPrice(gasPriceKey) {
    dispatch(setSelectedGasPrice(gasPriceKey))
  }

  return (
    <div className="gas-option">
      <SlideDown active={isOpened}>
        <SlideDownTrigger toggleContent={toggleDropdown} className="gas-option__title">
          <div className="gas-option__title-text">
            <span>GAS fee (Gwei)</span>
            <Tooltip
              trigger={<span className="gas-option__info">?</span>}
              content={'Gas fee is required to facilitate transactions on the Ethereum blockchain. Generally, the higher the Gas price, the faster your transaction executes.'}
              longText={true}
            />
          </div>
        </SlideDownTrigger>

        <SlideDownContent className="gas-option__content" status={isOpened} close={closeDropdown} persistOnClickOutside={true}>
          {Object.keys(gasPrices).map(key => {
            const gasPrice = gasPrices[key];

            return (
              <label key={key} className={`common__option gas-option__item`}>
                <span className="common__option-text">
                  <span className="gas-option__value">{gasPrice}</span>
                  <span className="gas-option__text">{key}</span>
                </span>
                <input
                  className="common__option-input"
                  type="radio"
                  onChange={() => dispatchSetSelectedGasPrice(key)}
                  checked={key === selectedGasPrice}
                />
                <span className="common__option-radio" />
                <div className="gas-option__estimate">~ {calculateTxFee(gasPrice, gasLimit)} ETH</div>
              </label>
            )
          })}

          <div className="gas-option__note">Gas cost is estimated (actual gas used is usually lower).</div>

          <div className="gas-option__note">Select higher gas price to accelerate your transaction processing time.</div>
        </SlideDownContent>
      </SlideDown>
    </div>
  )
}
