import { fork, all } from 'redux-saga/effects';
import accountWatcher from 'src/app/sagas/accountSaga';
import voteWatcher from 'src/app/sagas/voteSaga.js';
import delegateWatcher from "src/app/sagas/delegateSaga";
import withdrawWatcher from 'src/app/sagas/withdrawSaga.js';
import rewardWatcher from 'src/app/sagas/rewardSaga.js';
import stakeWatcher from 'src/app/sagas/stakeSaga.js';
import migrateWatcher from 'src/app/sagas/migrateSaga.js';
import approveWatcher from 'src/app/sagas/approveSaga.js';
import txWatcher from 'src/app/sagas/txSaga.js';
import queueWatcher from 'src/app/sagas/queueSaga.js';
import executeWatcher from 'src/app/sagas/executeSaga.js';

export default function* rootSaga() {
  yield all([
    fork(accountWatcher),
    fork(voteWatcher),
    fork(delegateWatcher),
    fork(withdrawWatcher),
    fork(rewardWatcher),
    fork(stakeWatcher),
    fork(migrateWatcher),
    fork(approveWatcher),
    fork(txWatcher),
    fork(queueWatcher),
    fork(executeWatcher),
  ]);
}
