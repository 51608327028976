import React from "react";
import { readableTime } from "src/app/utils/fortmaters";
import moment from "moment";
import { getCurrentTimestamp, getTagColorAndStatus } from "src/app/utils/converters";
import { PROPOSAL_STATUSES } from "src/app/configs/constants";
import useCountDown from "src/app/components/Hooks/useCountDown";
import Loading from "src/app/components/Commons/Loading";

export default function ProposalStatus({ proposal }) {
  const isProposalEnded =
    proposal.status !== PROPOSAL_STATUSES.ACTIVE && proposal.status !== PROPOSAL_STATUSES.UPCOMING;
  const timeLeft = useCountDown(getTimeLeft());

  function getTimeLeft() {
    const currentTime = getCurrentTimestamp();
    let timeLeft =
      (proposal.status === PROPOSAL_STATUSES.UPCOMING ? proposal.start_timestamp : proposal.end_timestamp) -
      currentTime;
    if (isProposalEnded) timeLeft = proposal.end_timestamp;

    return timeLeft;
  }

  function renderTimeLeft(time) {
    if (time > 0) {
      const formattedTime = readableTime(time);
      switch (proposal.status) {
        case PROPOSAL_STATUSES.ACTIVE:
          return `${formattedTime} left`;
        case PROPOSAL_STATUSES.UPCOMING:
          return `Start in ${formattedTime}`;
        case PROPOSAL_STATUSES.FAILED:
        case PROPOSAL_STATUSES.FINALIZED:
        case PROPOSAL_STATUSES.SUCCEEDED:
        case PROPOSAL_STATUSES.EXECUTED:
        case PROPOSAL_STATUSES.EXPIRED:
          return `Ended ${moment.unix(time).format("DD MMM YYYY")}, ${proposal.status}`;
        case PROPOSAL_STATUSES.CANCELED:
          return `${proposal.status}`;
        default:
          return `Ended ${moment.unix(time).format("DD MMM YYYY")}`;
      }
    } else {
      return <Loading />;
    }
  }

  function renderTag(tag) {
    const { tagColor } = getTagColorAndStatus(tag);

    return <div className={`common__tag-item ${tagColor}`}>{tag}</div>;
  }

  return (
    <div className="proposal__item-status">
      <div className={`common__timer ${isProposalEnded ? "common__timer--grey" : ""}`}>{renderTimeLeft(timeLeft)}</div>
      {/* <div className="common__tag"></div> */}
      {renderTag(proposal.proposal_type)}
      {renderTag(`ID #${proposal.proposal_id}`)}
    </div>
  );
}
