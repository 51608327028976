import React, { useState, useEffect } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LogoKyber from "src/assets/icons/LogoKyber";
import LogoKyberDAO from "src/assets/icons/LogoKyberDAO";
import { routes } from "src/app/configs/routes";
import Notification from "src/app/components/Commons/Notification";
import DropDown from "src/app/components/Commons/DropDown";
import { markAllTxRead } from "src/app/actions/txAction";
import { modal } from "src/app/components/Modals/Modal";
import PageModal from "src/app/components/Modals/PageModal";
import Account from "src/app/components/Accounts/Account";
import { setUnvotedCampaigns } from "src/app/actions/campaignAction";
import { fetchAccountRewards } from "src/app/actions/rewardAction";
import RewardContainer from "src/app/components/Reward/RewardContainer";
import { displayFormattedNumber, formatAddress, formatBigNumber } from "src/app/utils/fortmaters";
import BasicModal from "../Modals/BasicModal";
import Loading from "src/app/components/Commons/Loading";
import StakeContainer from "../Stake/StakeContainer";
import { TUTORIAL_VIDEO_ID } from "src/app/configs/constants";
import { toggleAccountModal } from "src/app/actions/globalAction";
import useCheckAccountImported from "../Hooks/useCheckAccountImported";
import useScreenName from "../Hooks/useScreenName";
import { compareTwoNumber } from "src/app/utils/calculators";
import IcMenu from "src/assets/icons/IcMenu";
import IcBell from "src/assets/icons/IcBell";
import IcHistory from "src/assets/icons/IcHistory";
import IcMigrate from "src/assets/icons/IcMigrate";
import IcV1 from "src/assets/icons/IcV1";

export default function Header(props) {
  const dispatch = useDispatch();
  const { address, balance, init } = useSelector((state) => state.account);
  const { rewards } = useSelector((state) => state.reward);
  const txs = useSelector((state) => state.txs.data);
  // const { rewards } = useSelector((state) => state.reward);
  const { unvotedCampaigns } = useSelector((state) => state.campaign);
  const votes = useSelector((state) => state.vote.votes);
  const activeCampaigns = useSelector((state) => state.campaign.activeCampaigns);
  const { openActionModal } = useCheckAccountImported();

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenStakeModal, setIsOpenStakeModal] = useState(false);
  const [isOpenClaimModal, setIsOpenClaimModal] = useState(false);
  const [isOpenMobileAccount, setIsOpenMobileAccount] = useState(false);
  const [unreadTxs, setUnreadTxs] = useState(0);
  // const [totalReward, setTotalReward] = useState(0);

  const history = useHistory();
  const isTutorialMode = useScreenName(routes.learn);

  // Check if we should enable Claim button
  const enableClaim =
    rewards && rewards.remainingCumulativeAmounts && compareTwoNumber(rewards.remainingCumulativeAmounts[0], 0) === 1;

  useEffect(() => {
    if (isTutorialMode) {
      setTimeout(() => {
        openTutorialVideo();
      }, 0);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    return history.listen(() => {
      if (isOpenMobileAccount) {
        closeMobileAccount();
      }
    });
  }, [history, isOpenMobileAccount]);

  useEffect(() => {
    let count = txs.reduce((total, tx) => {
      if (tx.read === 0) return ++total;
      return total;
    }, 0);

    setUnreadTxs(count);
  }, [txs]);

  useEffect(() => {
    function updateUnvotedCampaignNotification() {
      let unvotedCampaigns = activeCampaigns.length;

      if (unvotedCampaigns && votes.length) {
        activeCampaigns.forEach((campaign) => {
          votes.forEach((vote) => {
            if (vote.proposal_id === campaign.proposal_id) unvotedCampaigns -= 1;
          });
        });
      }

      dispatch(setUnvotedCampaigns(unvotedCampaigns));
    }

    if (address) updateUnvotedCampaignNotification();
  }, [address, activeCampaigns.length, votes]); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchAccountRewards(address));
  }, [address, dispatch]);

  // useEffect(() => {
  //   if (!rewards) return;

  //   setTotalReward(calculateTotalReward(rewards));
  // }, [rewards]); // eslint-disable-line

  function openStakeModal() {
    openActionModal(() => setIsOpenStakeModal(true));
  }

  function openClaimModal() {
    openActionModal(() => setIsOpenClaimModal(true));
  }

  function openTutorialVideo() {
    modal.show(BasicModal, {
      title: "KyberDAO Tutorial",
      content: (
        <iframe
          className="mt-3 w-100"
          height="350"
          title="KyberDAO Tutorial"
          src={`https://www.youtube.com/embed/${TUTORIAL_VIDEO_ID}?autoplay=1`}
          frameborder="0"
          allowFullScreen
        />
      ),
      className: "large",
    });
  }

  function closeMenu() {
    setIsOpenMenu(false);
  }

  function openImportModal() {
    dispatch(toggleAccountModal());
  }

  function closeMobileAccount() {
    setIsOpenMobileAccount(false);
  }

  function markAllRead() {
    dispatch(markAllTxRead());
  }

  function renderCountNoti() {
    return <>{unreadTxs > 0 && <div className="common__badge">{unreadTxs}</div>}</>;
  }

  function showNotiModalOnMobile() {
    modal.show(PageModal, { type: "page", title: "Tx", content: <Notification /> });
    closeMenu();
  }

  function showAccountOnMobile() {
    setIsOpenMobileAccount(true);
    closeMenu();
  }

  function renderNotification(number) {
    if (address && !!number) {
      return <div className="common__badge">{number}</div>;
    }
    return <></>;
  }

  function renderStakedBalance() {
    if (init && balance.isLoading) {
      return <Loading />;
    }

    return displayFormattedNumber(balance.stakedKNC);
  }

  function renderAccountRewards() {
    if (init || !rewards || !rewards.remainingCumulativeAmounts) {
      return <Loading />;
    }

    return displayFormattedNumber(formatBigNumber(rewards.remainingCumulativeAmounts[0]));
  }

  return (
    <>
      <div className="header">
        <div className="left">
          <Link to={routes.vote} className="header__logo">
            <LogoKyberDAO />
          </Link>
          <ul className="header__menu mr-auto">
            <li>
              <NavLink to={routes.vote}>
                <span>Vote</span>
                {renderNotification(unvotedCampaigns)}
              </NavLink>
            </li>
            <li className="d-none d-lg-inline-block">
              <NavLink to={routes.about}>About</NavLink>
            </li>
            <li className="d-lg-none">
              <NavLink to={routes.reward}>My Reward</NavLink>
            </li>
            {address ? (
              <>
                <li className="d-none d-lg-none pointer ml-4">
                  <div className="header__menu-wallet" onClick={showAccountOnMobile}>
                    <div className="header__menu-wallet__value">{formatAddress(address, 4)}</div>
                  </div>
                </li>
              </>
            ) : (
              <>
                <li className="mr-3 d-none d-md-inline-block d-lg-none">
                  <button
                    className="common__button common__button--xsm common__button--border-white"
                    onClick={showAccountOnMobile}
                  >
                    Get Started
                  </button>
                </li>
              </>
            )}
            <li className="d-none d-lg-inline-block">
              <a href="https://gov.kyber.org/" onClick={closeMenu} target="_blank" rel="noopener noreferrer">
                Forum
              </a>
            </li>
            <li className="d-none d-lg-inline-block">
              <a
                href="https://kyberswap.com/?utm_source=KyberDAO&utm_medium=KyberDAO-KyberSwap+menu&utm_campaign=KyberDAO-KyberSwap&utm_id=KyberDAO-KyberSwap&utm_content=KyberDAO-KyberSwap+menu"
                onClick={closeMenu}
                target="_blank"
                rel="noopener noreferrer"
              >
                KyberSwap
              </a>
            </li>
          </ul>
        </div>
        <div className="right">
          <div className="header__bars d-md-none" onClick={() => setIsOpenMenu(true)}>
            <IcMenu />
            {renderCountNoti()}
          </div>
          <div
            className={`header__menu-overlay d-md-none ${isOpenMenu ? "active" : ""}`}
            onClick={() => setIsOpenMenu(false)}
          />
          <ul className={`header__menu header__menu--right ${isOpenMenu ? "active" : ""}`}>
            <li className="header__menu__top">
              <Link to={routes.vote} className="d-inline-block mr-auto p-0">
                <LogoKyber fill="white" />
              </Link>
              <span className="close d-md-none" onClick={closeMenu}>
                &times;
              </span>
            </li>
            <li className="d-md-none">
              <div className="wrapper" onClick={showAccountOnMobile}>
                {address ? "My Account" : "Connect Wallet"}
              </div>
            </li>
            {address ? (
              <>
                <li className="d-md-none">
                  <div className="wrapper" onClick={openStakeModal}>
                    Stake
                  </div>
                </li>
                <li className="d-none d-lg-inline-block">
                  <div className="header__menu-reward">
                    <span className="header__menu-reward__text">
                      My Reward: <span className="header__menu-reward__value">{renderAccountRewards()} KNC</span>
                    </span>
                    <button
                      className="common__button common__button--claim"
                      disabled={!enableClaim}
                      onClick={openClaimModal}
                    >
                      Claim
                    </button>
                  </div>
                </li>

                <li className="d-none d-lg-inline-block ml-2">
                  <DropDown
                    title={
                      <div className="header__menu-wallet">
                        <span>My Stake: {renderStakedBalance()} KNC</span>
                        <div className="header__menu-wallet__value font-sm">{formatAddress(address, 6)}</div>
                      </div>
                    }
                    content={<Account />}
                  />
                </li>
                <li className="ml-2 d-none d-lg-inline-block">
                  <button className="common__button common__button--stake" onClick={openStakeModal}>
                    Stake
                  </button>
                </li>
              </>
            ) : (
              <>
                <li className="mr-3 d-none d-lg-inline-block">
                  <button className="common__button common__button--border-white" onClick={openImportModal}>
                    Get Started
                  </button>
                </li>
              </>
            )}
            <li className="d-block d-md-none">
              <NavLink to={routes.about} onClick={closeMenu}>
                About
              </NavLink>
            </li>
            <li className="d-block d-md-none">
              <NavLink to={routes.history} onClick={closeMenu}>
                History
              </NavLink>
            </li>
            <li className="d-block d-md-none">
              <NavLink to={routes.migrate} onClick={closeMenu}>
                Migrate
              </NavLink>
            </li>
            <li className="d-block d-md-none">
              <NavLink to={routes.faq} onClick={closeMenu}>
                FAQs
              </NavLink>
            </li>
            <li className="d-block d-md-none hide">
              <NavLink to={routes.report} onClick={closeMenu}>
                Report
              </NavLink>
            </li>
            <li className="d-block d-md-none">
              <a href={routes.v1} onClick={closeMenu} target="_blank" rel="noopener noreferrer">
                DAO V1
              </a>
            </li>
            {address && (
              <li className="d-md-none">
                <div className="wrapper" onClick={openTutorialVideo}>
                  Tutorial
                </div>
              </li>
            )}
            <li>
              <div className="wrapper d-block d-lg-none" onClick={showNotiModalOnMobile}>
                <span>
                  <IcBell fill="#fff" />
                  {renderCountNoti()}
                </span>
              </div>
            </li>
          </ul>
          {address && (
            <div className="header__notification d-none d-lg-block mr-2">
              <DropDown title={<IcBell />} content={<Notification />} onClose={markAllRead} />
              {renderCountNoti()}
            </div>
          )}
          <DropDown
            className="header__bars d-none d-md-block mr-2"
            title={<IcMenu />}
            content={
              <span className="header__bars__dropdown">
                <NavLink to={routes.history}>
                  <IcHistory />
                  History
                </NavLink>
                <NavLink to={routes.migrate}>
                  <IcMigrate />
                  Migrate
                </NavLink>
                <a href={routes.v1} onClick={closeMenu} target="_blank" rel="noopener noreferrer">
                  <IcV1 />
                  V1
                </a>
              </span>
            }
          />
        </div>
      </div>

      {isOpenMobileAccount && (
        <PageModal type="page" title="Account" close={closeMobileAccount} content={<Account />} />
      )}

      {props.children}

      <StakeContainer isOpenStakeModal={isOpenStakeModal} onCloseModal={() => setIsOpenStakeModal(false)} />

      <RewardContainer isOpenClaimModal={isOpenClaimModal} onCloseModal={() => setIsOpenClaimModal(false)} />

      <Account className="account--fixed" />
    </>
  );
}
