import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { validateProposalVoting } from "src/app/utils/validators";
import { calculatePercent } from "src/app/utils/calculators";
import { PROPOSAL_STATUSES, PROPOSAL_TYPES } from "src/app/configs/constants";

export default function useSelectedOption(proposal, submit = null) {
  const { address, accountInfo } = useSelector(state => state.account);
  const { proposalMessage } = useSelector(state => state.campaign);
  const { votes } = useSelector(state => state.vote);

  const [alreadyVotedOption, setAlreadyVotedOption] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [voteInfo, setVoteInfo] = useState({});
  const [voteError, setVoteError] = useState('');
  const [percentageOptions, setPercentageOptions] = useState([]);
  const [votingPower, setVotingPower] = useState(0);
  const [highestOption, setHighestOption] = useState(null);

  const isProposalUpcoming = proposal && proposal.status === PROPOSAL_STATUSES.UPCOMING;
  const isProposalEnded = proposal && (proposal.status === PROPOSAL_STATUSES.FINALIZED || proposal.status === PROPOSAL_STATUSES.EXECUTED);
  const isStaked = accountInfo && (accountInfo.stake_amount || accountInfo.pending_stake_amount || accountInfo.delegated_stake_amount);
  const upcomingMessage = isProposalUpcoming ? 'This proposal has not started yet. Please come back later when the proposal is available for voting' : '';
  const message = (proposalMessage && proposal) && proposalMessage.id === proposal.proposal_id ? proposalMessage.value : upcomingMessage;

  /** Reset Error on Address changed **/
  useEffect(() => {
    setVoteError('')
  }, [address]) // eslint-disable-line

  /** Update Already voted and selected Option **/
  useEffect(() => {
    if (!proposal || isProposalEnded) return;

    const voted = votes.find((vote) => {
      return vote.proposal_id === proposal.proposal_id;
    });
    const option = voted ? (voted.option || voted.options) : false;

    setAlreadyVotedOption(option);
    if (option && !selectedOption) setSelectedOption(option);
  }, [votes, proposal]) // eslint-disable-line

  /** Set Voting Power & percentage of each option & highest voted option **/
  useEffect(() => {
    if (!proposal) return;

    const isImported = accountInfo && address;
    const isNotDelegated = isImported ? accountInfo.delegate.toLowerCase() === address.toLowerCase() : true;
    const votingPower = isImported ? (isNotDelegated ? accountInfo.stake_amount : 0) + accountInfo.delegated_stake_amount : 0
    const totalVote = proposal.vote_stats.total_vote_count;

    setVotingPower(votingPower);

    if (Object.keys(proposal.vote_stats).length !== 0) {
      let percentages = [];
      let highestOption = { vote_count: 0, options: [] };

      if (proposal.vote_stats.total_address_count > 0) {
        proposal.vote_stats.options.forEach((item) => {
          percentages[item.option] = calculatePercent(item.vote_count, totalVote);

          if (item.vote_count > highestOption.vote_count) {
            highestOption = item;
            highestOption["options"] = [item.option]
          }else if (item.vote_count == highestOption.vote_count) {
            highestOption["options"].push(item.option) 
          }
        });
      }
      setHighestOption(highestOption.options || null);
      setPercentageOptions(percentages);
    }
  }, [accountInfo, address, proposal]);

  function onSelect(optionValue) {
    setVoteError('');
    setSelectedOption(optionValue);
  }
  function decode(num) {
    return num.toString(2).split('').map(i => parseInt(i)).reverse().reduce((acc, item, index) => {if (!!item) acc.push(index+1); return acc}, [] )
  }
  function onSubmit() {
    const stakedAmount = isStaked ? accountInfo.stake_amount : 0;

    const error = validateProposalVoting(
      address, selectedOption, alreadyVotedOption, votingPower, stakedAmount, proposal.status
    );

    setVoteError(error);

    if (!error) {
      // const voteParams = { campID: proposal.proposal_id, option: (proposal.proposal_type == PROPOSAL_TYPES.BINARY) ? selectedOption : selectedOption.map(i=>i+1).reduce((acc, item) => acc += (1<<(item-1)), 0) };
      const voteParams = { campID: proposal.proposal_id, option: selectedOption.map(i=>i+1).reduce((acc, item) => acc += (1<<(item-1)), 0) };
      if (submit) {
        submit(voteParams);
      } else {
        setVoteInfo(voteParams);
      }
    }
  }

  return {
    alreadyVotedOption, selectedOption, setSelectedOption, onSelect, onSubmit, message,
    voteInfo, setVoteInfo, voteError, percentageOptions, highestOption, isStaked, votingPower
  }
}
