export const campaignActionTypes = {
  SET_ACTIVE_CAMPAIGNS: "CAMPAIGN.SET_ACTIVE_CAMPAIGNS",
  SET_UPCOMING_CAMPAIGNS: "CAMPAIGN.SET_UPCOMING_CAMPAIGNS",
  SET_ENDED_CAMPAIGNS: "CAMPAIGN.SET_ENDED_CAMPAIGNS",
  SET_UNVOTED_CAMPAIGNS: 'CAMPAIGN.SET_UNVOTED_CAMPAIGNS',
  SET_PROPOSAL_MESSAGE: 'CAMPAIGN.SET_PROPOSAL_MESSAGE',
}

export function setActiveCampaigns(campaigns) {
  return {
    type: campaignActionTypes.SET_ACTIVE_CAMPAIGNS,
    payload: campaigns
  }
}

export function setUpcomingCampaigns(campaigns) {
  return {
    type: campaignActionTypes.SET_UPCOMING_CAMPAIGNS,
    payload: campaigns
  }
}

export function setEndedCampaigns(campaigns) {
  return {
    type: campaignActionTypes.SET_ENDED_CAMPAIGNS,
    payload: campaigns
  }
}

export function setUnvotedCampaigns(unvotedCampaigns) {
  return {
    type: campaignActionTypes.SET_UNVOTED_CAMPAIGNS,
    payload: unvotedCampaigns
  }
}

export function setProposalMessage(message) {
  return {
    type: campaignActionTypes.SET_PROPOSAL_MESSAGE,
    payload: message
  }
}
