import { compareTwoNumber } from "src/app/utils/calculators";
import { PROPOSAL_STATUSES } from "src/app/configs/constants";

export function verifyKey(keystring) {
  try {
    const key = JSON.parse(keystring);
    if (!key.address || key.address === "" || !key.crypto || key.crypto === "") {
      return "Invalid keystore file"
    }
  } catch (e) {
    return "Malformed JSON keystore file"
  }
  return null
}

export function anyErrors(errors) {
  const keys = Object.keys(errors);

  for (let i = 0; i < keys.length; i++) {
    if (errors[keys[i]] != null && errors[keys[i]] !== "") {
      return true
    }
  }

  return false
}

export function filterNumberInput(event, value, preVal) {
  let strRemoveText = value.replace(/[^0-9.]/g, '');
  let str = strRemoveText.replace(/\./g, (val, i) => {
    if (strRemoveText.indexOf('.') !== i) val = '';
    return val
  });

  if (str === ".") str = "0.";

  event.target.value = str;

  return preVal !== str;
}

export function validateBalanceInput(amount, balance, address) {
  let errorMessage = null;

  if (!address) {
    errorMessage = 'Please import your wallet first';
  } else if (!+amount) {
    errorMessage = 'Please enter a positive amount';
  } else if (compareTwoNumber(balance, amount) === -1) {
    errorMessage = `The token amount is too high. You don't have enough tokens`;
  }

  return errorMessage;
}

export function validateClaimRewardsInput(amount, rewards, address) {
  let errorMessage = null;

  if (!address) {
    errorMessage = 'Please import your wallet first';
  } else if (!+amount) {
    errorMessage = 'Please enter a positive amount';
  } else if (compareTwoNumber(rewards, amount) === -1) {
    errorMessage = `The token amount is too high. You don't have enough tokens`;
  }

  return errorMessage;
}

export function verifyAccount(addr) {
  return /^0x[0-9a-fA-F]{40}$/.test(addr)
}

export function verifyMetamask() {
  let isMetamask = false;

  if (window.ethereum && window.ethereum.isMetaMask) {
    isMetamask = true;
  }

  return isMetamask;
}

export function verifyContainString(string, search) {
  return string.indexOf(search) !== -1;
}

export function checkIsObjectEmpty(object) {
  return !object || Object.keys(object).length === 0
}

export function validateProposalVoting(
  address, selectedOption, alreadyVotedOption, votingPower, stakeAmount, proposalStatus
) {
  let error = '';

  if (!address) {
    error = "Please import a wallet before voting";
  } else if (!selectedOption) {
    error = "Please choose an option before voting";
  } else if (proposalStatus === PROPOSAL_STATUSES.UPCOMING) {
    error = "This proposal has not started yet. Please come back later when the proposal is available for voting"
  } else if (votingPower === 0) {
    if (stakeAmount === 0) {
      error = "You cannot vote in the current epoch because you did not stake any KNC in previous epochs. KNC that you stake in this current epoch will be active for voting from the next epoch onwards.";
    } else {
      error = `You cannot vote at the current epoch because you have delegated your voting power to someone else already. Please remove your representative if you want to make your own vote and it will be validated starting from the next epoch. <a class="text-underline" href="https://blog.kyber.network/kyberdao-staking-and-voting-overview-70be71ee58f0#3da5" target="_blank">Read more</a>`;
    }
  } else if (alreadyVotedOption === selectedOption) {
    error = "Please choose another option to update your vote";
  }

  return error;
}
