import React from 'react'
import CopyableTx from "src/app/components/Commons/CopyableTx"
import { formatAddress } from "src/app/utils/fortmaters";
import ENV from "src/app/configs/env";

export default function DelegateSuccessModal({ txHash, close, delegatedAddress, delegatedPartner }) {
  const formattedAddress = formatAddress(delegatedAddress, 7, -4);

  return (
    <div className="modal__delegate modal-interact">
      <div className="modal__tx-done">
        <div className="modal-content">
          <div className="title">Done!</div>
          {delegatedPartner.image && (
            <img className="modal__image" src={delegatedPartner.image} alt={delegatedPartner.name}/>
          )}
          <div className="ins">
            <span>Successfully delegated your voting power to&nbsp;</span>
            <a className="font-weight-bold common__link-text common__link-text--black" href={`${ENV.URLS.ETHERSCAN}/address/${delegatedAddress}`} target="_blank" rel="noreferrer noopener">
              {delegatedPartner.name ? `${delegatedPartner.name} (${formattedAddress})` : formattedAddress}
            </a>
          </div>
          <CopyableTx txHash={txHash}/>
          <div className="note">
            <span>Note: Your representative will only be able to vote in the next Epoch.</span>
          </div>
        </div>
        <div className="panel">
          <div className="common__button" onClick={close}>Done</div>
        </div>
      </div>
    </div>
  )
}
