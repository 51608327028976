import React from 'react'
import CopyableTx from "src/app/components/Commons/CopyableTx"
import ViewOn from "src/app/components/Commons/ViewOn";
import rewardKnc from "src/assets/images/reward/reward-knc.svg";
import { roundNumber } from "src/app/utils/fortmaters";

export default function ClaimRewardSuccessModal({ txHash, close, amount }) {
  return (
    <div className="modal__claim modal-interact">
      <div className="modal__tx-done">
        <div className="modal-content">
          <div className="image">
            <img src={rewardKnc} width="120" alt="Reward KNC" />
          </div>
          <div className="ins">You have successfully claimed your rewards of</div>
          <div className="amount">
            <span className="amount__value">{roundNumber(amount, 6)}</span>
            <span className="amount__token">KNC</span>
          </div>
          <div className="panel-light modal-broadcast__transaction">
            <CopyableTx txHash={txHash} />
            <ViewOn txHash={txHash} />
          </div>
        </div>
        <div className="panel">
          <div className="common__button" onClick={close}>Done</div>
        </div>
      </div>
    </div>
  )
}
