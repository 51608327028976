export const stakeActionTypes = {
  STAKE: 'STAKE.STAKE',
  SET_ERROR: 'STAKE.SET_ERROR'
};

export function stake(stakingAmount, gasPrice, gas) {
  return {
    type: stakeActionTypes.STAKE,
    payload: { stakingAmount, gasPrice, gas }
  }
}

export function setStakeError(error) {
  return {
    type: stakeActionTypes.SET_ERROR,
    payload: error
  }
}
