import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { clearAccount, importAccount } from "src/app/actions/accountAction";
import { modal } from "src/app/components/Modals/Modal";
import DappService from "src/app/services/accounts/DappService";
import { MOBILE_SCREEN_SIZE, WALLET_TYPES } from "src/app/configs/constants";
import { verifyMetamask } from "src/app/utils/validators";
import BasicModal from "src/app/components/Modals/BasicModal";
import { fromNetworkIdToName } from "src/app/utils/converters";
import ENV from "src/app/configs/env";

export default function useSettingUpAccount() {
  const dispatch = useDispatch();
  const { wallet, devicePath } = useSelector((state) => state.account);

  useEffect(() => {
    const isMetamask = verifyMetamask();
    const isMobile = window.innerWidth < MOBILE_SCREEN_SIZE;
    const isWeb3Imported = window.ethereum;

    if (isWeb3Imported && (!isMetamask || isMobile)) {
      connectToDapp();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!wallet) {
      modal.close();
      return;
    }

    if (typeof wallet.subscribeToDisconnect === 'function') {
      wallet.subscribeToDisconnect(() => dispatch(clearAccount()));
    }

    if (wallet.needTobeInitiated) {
      initiateWallet();
    }
  }, []); // eslint-disable-line

  async function connectToDapp() {
    const wallet = new DappService();
    const address = await wallet.connect(openConnectErrorModal, openNetworkErrorModal);

    if (!address) return;

    dispatch(importAccount(address, wallet, WALLET_TYPES.DAPP));
  }

  async function initiateWallet() {
    try {
      await wallet.setConnection(devicePath);
    } catch (e) {
      dispatch(clearAccount());
    }
  }

  function openConnectErrorModal() {
    modal.show(BasicModal, {
      title: "Error",
      content: <div>Cannot connect to your wallet.</div>
    });
  }

  function openNetworkErrorModal(currentNetworkId) {
    modal.show(BasicModal, {
      title: "Error",
      content: (
        <div className="text-center">
          <p className="mb-2">Your wallet should be on <b>{fromNetworkIdToName(ENV.NETWORK_ID)}</b>.</p>
          <p>Currently it is on <b>{fromNetworkIdToName(currentNetworkId)}</b> instead.</p>
        </div>
      )
    });
  }
}
