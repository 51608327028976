import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAccountBalance,
  setAccountInfo,
} from "src/app/actions/accountAction";
import {
  FETCHING_INTERVALS,
  PROPOSAL_STATUSES,
  TX_STATUS,
} from "src/app/configs/constants";
import { setDelegatedAddress } from "src/app/actions/delegateAction";
import { setVotes } from "src/app/actions/voteAction";
import { updateTxsStatus } from "src/app/actions/txAction";
import {
  fetchVotes,
  fetchDaoInfo,
  fetchCampaigns,
  fetchHistories,
  fetchStakerInfo,
} from "src/app/services/apis/daoService";
import Web3Service from "src/app/services/web3/Web3Service";
import {
  setCurrentNetworkData,
  setDaoInfo,
  setGasPrices,
  setHeaderData,
  setHeaderDataLoading,
  setVotingReward,
} from "src/app/actions/globalAction";
import {
  setActiveCampaigns,
  setUpcomingCampaigns,
  setEndedCampaigns,
  setUnvotedCampaigns,
} from "src/app/actions/campaignAction";
import { setHistories } from "src/app/actions/historyAction";
import { fetchAccountRewards, resetRewards } from "src/app/actions/rewardAction";
import {
  fetchTokenPrices,
  fetchVotingReward,
} from "src/app/services/apis/kyberNetworkService";
import { checkIsObjectEmpty } from "src/app/utils/validators";
import ENV from "src/app/configs/env";
import { formatBigNumber } from "src/app/utils/fortmaters";
import { fetchGasPrices } from "src/app/services/apis/cacheService";

export default function useFetchingData() {
  const dispatch = useDispatch();
  const address = useSelector((state) => state.account.address);
  const daoInfo = useSelector((state) => state.global.daoInfo);
  const txs = useSelector((state) => state.txs.data);
  const activeCampaigns = useSelector(
    (state) => state.campaign.activeCampaigns
  );

  const [isFetchedCampaigns, setIsFetchedCampaigns] = useState(false);

  const web3Service = new Web3Service();
  const daoInfoInterval = useRef(false);
  const balanceInterval = useRef(false);
  const campaignAndVoteInterval = useRef(false);
  const updateTxsStatusInterval = useRef(false);

  /** Fetch Token Prices & Campaigns & Gas Prices **/
  useEffect(() => {
    getBRRAndFeeData();

    getCampaignsAndVotes(address);

    getTokenPrices();
    const tokenPriceInterval = setInterval(() => {
      getTokenPrices(false);
    }, FETCHING_INTERVALS.HEADER_DATA);

    getGasPrices();
    const gasPricesInterval = setInterval(() => {
      getGasPrices();
    }, FETCHING_INTERVALS.GAS_PRICE);

    let votingRewardInterval

    if (ENV.NETWORK_ID === 1) {
      getVotingReward();
      votingRewardInterval = setInterval(() => {
        getVotingReward();
      }, FETCHING_INTERVALS.VOTING_REWARD);
    }
    return () => {
      clearInterval(tokenPriceInterval);
      clearInterval(gasPricesInterval);

      if (votingRewardInterval) {
        clearInterval(votingRewardInterval)
      }
    };
  }, []); // eslint-disable-line

  /** Update Ended Campaigns **/
  useEffect(() => {
    if (isFetchedCampaigns)
      getCampaignsAndVotes(address, [PROPOSAL_STATUSES.ENDED]);
  }, [activeCampaigns.length]); // eslint-disable-line

  /** Fetch Balance, Delegated Address, Histories, Rewards, Votes, DAO info & Staker Info **/
  useEffect(() => {
    clearIntervalsOnAddressChanged();

    getDaoAndStakerInfo();
    daoInfoInterval.current = setInterval(() => {
      getDaoAndStakerInfo();
    }, FETCHING_INTERVALS.DEFAULT);

    campaignAndVoteInterval.current = setInterval(() => {
      getCampaignsAndVotes(address);
    }, FETCHING_INTERVALS.DEFAULT);

    if (!address) return;

    balanceInterval.current = setInterval(() => {
      dispatch(fetchAccountBalance(address));
      dispatch(fetchAccountRewards(address));
    }, FETCHING_INTERVALS.BALANCE);

    web3Service
      .fetchDelegatedAddress(address)
      .then((address) => dispatch(setDelegatedAddress(address)));
    fetchHistories(address).then((histories) =>
      dispatch(setHistories(histories || []))
    );

    return () => {
      clearIntervalsOnAddressChanged();
      resetToInitialStateOnAccountChanged();
    };
  }, [address]); // eslint-disable-line

  /** Update Txs **/
  useEffect(() => {
    if (!txs) return;

    clearIntervalsOnTxsChanged();

    updateTxsStatusInterval.current = setInterval(() => {
      dispatch(updateTxsStatus());
    }, FETCHING_INTERVALS.TX_MINE);

    let pendingTx = txs.findIndex((item) => item.status === TX_STATUS.PENDING);
    if (pendingTx === -1) {
      clearIntervalsOnTxsChanged();
    }

    return () => {
      clearIntervalsOnTxsChanged();
    };
  }, [txs]); // eslint-disable-line

  function clearIntervalsOnTxsChanged() {
    clearInterval(updateTxsStatusInterval.current);
  }

  function clearIntervalsOnAddressChanged() {
    clearInterval(balanceInterval.current);
    clearInterval(daoInfoInterval.current);
    clearInterval(campaignAndVoteInterval.current);
  }

  async function getTokenPrices(needLoading = true) {
    dispatch(setHeaderDataLoading(needLoading));

    const prices = await fetchTokenPrices();

    dispatch(setHeaderDataLoading(false));

    if (!prices) return;

    dispatch(setHeaderData({ ...prices, isLoading: false }));
  }

  async function getGasPrices() {
    const gasPrices = await fetchGasPrices();

    if (!gasPrices) return;

    dispatch(setGasPrices(gasPrices));
  }

  async function getVotingReward() {
    const votingReward = await fetchVotingReward();

    if (!votingReward) return;
    dispatch(setVotingReward(votingReward));
  }

  async function getBRRAndFeeData() {
    // START TODO: need to be removed when API is fixed

    try {
      const web3Service = new Web3Service();
      const networkData = await web3Service.fetchLatestBRRAndFeeData();
      dispatch(setCurrentNetworkData(networkData));
    } catch (e) { }

    // END TODO: need to be removed when API is fixed
  }

  async function getVotes(address) {
    if (!address) return [];
    return await fetchVotes(address);
  }

  async function getDaoAndStakerInfo() {
    const daoInfo = await fetchDaoInfo();

    // Start TODO: need to be removed when API is fixed
    const web3Service = new Web3Service();
    let totalStakedFromContract = daoInfo.total_staked;
    try {
      totalStakedFromContract = await web3Service.fetchTokenBalance(
        ENV.CONTRACTS.STAKING
      );
      totalStakedFromContract = formatBigNumber(totalStakedFromContract);
    } catch (e) { }
    daoInfo.total_staked = totalStakedFromContract
      ? totalStakedFromContract
      : daoInfo.total_staked;
    // End TODO: need to be removed when API is fixed

    updateDaoInfo(daoInfo);

    if (address && daoInfo) {
      const stakerInfo = await fetchStakerInfo(address, daoInfo.current_epoch);
      dispatch(setAccountInfo(stakerInfo));
    }
  }

  function resetToInitialStateOnAccountChanged() {
    dispatch(resetRewards());
    dispatch(setUnvotedCampaigns(0));
    dispatch(setVotes([]));
    dispatch(setHistories([]));
    dispatch(setAccountInfo(null));
  }

  async function getCampaignsAndVotes(address, statuses = []) {
    const campaigns = await fetchCampaigns(statuses);
    const votes = await getVotes(address);
    let activeCampaigns = [],
      upcomingCampaigns = [],
      endedCampaigns = [];
    const isFetchAll = !statuses.length;

    campaigns.forEach((campaign) => {
      if (campaign.status === PROPOSAL_STATUSES.ACTIVE) {
        activeCampaigns.push(campaign);
      } else if (campaign.status === PROPOSAL_STATUSES.UPCOMING) {
        upcomingCampaigns.push(campaign);
      } else {
        endedCampaigns.push(campaign);
      }
    });

    if (isFetchAll || statuses.includes(PROPOSAL_STATUSES.ACTIVE))
      dispatch(setActiveCampaigns(activeCampaigns));
    if (isFetchAll || statuses.includes(PROPOSAL_STATUSES.UPCOMING))
      dispatch(setUpcomingCampaigns(upcomingCampaigns));
    if (isFetchAll || statuses.includes(PROPOSAL_STATUSES.ENDED))
      dispatch(setEndedCampaigns(endedCampaigns));
    if (isFetchAll) setIsFetchedCampaigns(true);

    dispatch(setVotes(votes));
  }

  const updateDaoInfo = (daoInfo) => {
    if (checkIsObjectEmpty(daoInfo)) return;

    const currentEpoch = daoInfo.current_epoch;
    const startTime =
      daoInfo.first_epoch_start_timestamp +
      (currentEpoch - 1) * daoInfo.epoch_period_in_seconds;
    const endTime = startTime + daoInfo.epoch_period_in_seconds;

    dispatch(
      setDaoInfo({
        currentEpoch: currentEpoch,
        totalStaked: daoInfo.total_staked,
        currentEpochReward: daoInfo.current_epoch_reward,
        currentEpochRebate: daoInfo.current_epoch_rebate,
        currentEpochBurn: daoInfo.current_epoch_burn,
        currentEpochFee:
          daoInfo.current_epoch_reward +
          daoInfo.current_epoch_burn +
          daoInfo.current_epoch_rebate,
        totalFee:
          daoInfo.total_burn + daoInfo.total_rebate + daoInfo.total_reward,
        totalBurn: daoInfo.total_burn,
        totalRebate: daoInfo.total_rebate,
        totalReward: daoInfo.total_reward,
        startTime: startTime,
        endTime: endTime,
      })
    );
  };
}
