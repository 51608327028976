import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import localStorage from 'redux-persist/lib/storage'
import accountReducer from "src/app/reducers/accountReducer";
import globalReducer from "src/app/reducers/globalReducer";
import campaignReducer from "src/app/reducers/campaignReducer";
import voteReducer from "src/app/reducers/voteReducer";
import queueReducer from "src/app/reducers/queueReducer";
import executeReducer from "src/app/reducers/executeReducer";
import delegateReducer from "src/app/reducers/delegateReducer";
import withdrawReducer from "src/app/reducers/withdrawReducer";
import rewardReducer from "src/app/reducers/rewardReducer";
import stakeReducer from "src/app/reducers/stakeReducer";
import migrateReducer from "src/app/reducers/migrateReducer";
import approveReducer from "src/app/reducers/approveReducer";
import txReducer from "src/app/reducers/txReducer";
import historyReducer from "src/app/reducers/historyReducer";

const rootReducer = combineReducers({
  global: globalReducer,
  account: persistReducer({
    key: 'account',
    storage: localStorage,
    whitelist: ['address', 'devicePath', 'type', 'keyString']
  }, accountReducer),
  campaign: campaignReducer,
  vote: voteReducer,
  queue: queueReducer,
  execute: executeReducer,
  delegate: delegateReducer,
  withdraw: withdrawReducer,
  reward: rewardReducer,
  stake: stakeReducer,
  migrate: migrateReducer,
  approve: approveReducer,
  txs: persistReducer({
    key: 'txs',
    storage: localStorage,
    whitelist: ['data']
  }, txReducer),
  history: historyReducer,
});

export default rootReducer
