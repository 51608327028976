import { stakeActionTypes } from "src/app/actions/stakeAction";

const initialState = {
  stakeError: ''
};

export default function stakeReducer(state = initialState, action) {
  switch (action.type) {
    case stakeActionTypes.SET_ERROR: {
      return {
        ...state,
        stakeError: action.payload
      }
    }
    default:
      return state
  }
}