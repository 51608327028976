import {voteActionTypes} from "src/app/actions/voteAction";

const initialState = {
  init: true,
  votes: [],
  voteError: '',
};

export default function voteReducer(state = initialState, action) {
  switch (action.type) {
    case voteActionTypes.SET_VOTES: {
      return {
        ...state,
        votes: action.payload
      }
    }
    case voteActionTypes.SET_VOTE_ERROR: {
      return {
        ...state,
        voteError: action.payload
      }
    }
    default:
      return state
  }
}
