export const ABI = {
  ERC20: [
    {
      constant: true,
      inputs: [],
      name: "name",
      outputs: [{ name: "", type: "string" }],
      payable: false,
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "minter",
      outputs: [{ name: "", type: "address" }],
      payable: false,
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_spender", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "approve",
      outputs: [{ name: "o_success", type: "bool" }],
      payable: false,
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "totalSupply",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_recipient", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "createIlliquidToken",
      outputs: [{ name: "o_success", type: "bool" }],
      payable: false,
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_from", type: "address" },
        { name: "_recipient", type: "address" },
        { name: "_amount", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [{ name: "o_success", type: "bool" }],
      payable: false,
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "endMintingTime",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "decimals",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_recipient", type: "address" },
        { name: "_value", type: "uint256" },
      ],
      name: "createToken",
      outputs: [{ name: "o_success", type: "bool" }],
      payable: false,
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "_owner", type: "address" }],
      name: "balanceOf",
      outputs: [{ name: "balance", type: "uint256" }],
      payable: false,
      type: "function",
    },
    {
      constant: true,
      inputs: [{ name: "", type: "address" }],
      name: "illiquidBalance",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "symbol",
      outputs: [{ name: "", type: "string" }],
      payable: false,
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { name: "_recipient", type: "address" },
        { name: "_amount", type: "uint256" },
      ],
      name: "transfer",
      outputs: [{ name: "o_success", type: "bool" }],
      payable: false,
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "LOCKOUT_PERIOD",
      outputs: [{ name: "", type: "uint256" }],
      payable: false,
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { name: "_owner", type: "address" },
        { name: "_spender", type: "address" },
      ],
      name: "allowance",
      outputs: [{ name: "o_remaining", type: "uint256" }],
      payable: false,
      type: "function",
    },
    { constant: false, inputs: [], name: "makeLiquid", outputs: [], payable: false, type: "function" },
    {
      inputs: [
        { name: "_minter", type: "address" },
        { name: "_endMintingTime", type: "uint256" },
      ],
      payable: false,
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "_from", type: "address" },
        { indexed: true, name: "_recipient", type: "address" },
        { indexed: false, name: "_value", type: "uint256" },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, name: "_owner", type: "address" },
        { indexed: true, name: "_spender", type: "address" },
        { indexed: false, name: "_value", type: "uint256" },
      ],
      name: "Approval",
      type: "event",
    },
  ],
  PROXY: [
    {
      inputs: [{ internalType: "address", name: "_admin", type: "address" }],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "address", name: "newAdmin", type: "address" },
        { indexed: false, internalType: "address", name: "previousAdmin", type: "address" },
      ],
      name: "AdminClaimed",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "address", name: "newAlerter", type: "address" },
        { indexed: false, internalType: "bool", name: "isAdd", type: "bool" },
      ],
      name: "AlerterAdded",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
        { indexed: false, internalType: "address", name: "sendTo", type: "address" },
      ],
      name: "EtherWithdraw",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "trader", type: "address" },
        { indexed: false, internalType: "contract IERC20", name: "src", type: "address" },
        { indexed: false, internalType: "contract IERC20", name: "dest", type: "address" },
        { indexed: false, internalType: "address", name: "destAddress", type: "address" },
        { indexed: false, internalType: "uint256", name: "actualSrcAmount", type: "uint256" },
        { indexed: false, internalType: "uint256", name: "actualDestAmount", type: "uint256" },
        { indexed: false, internalType: "address", name: "platformWallet", type: "address" },
        { indexed: false, internalType: "uint256", name: "platformFeeBps", type: "uint256" },
      ],
      name: "ExecuteTrade",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: "contract IKyberHint", name: "kyberHintHandler", type: "address" }],
      name: "KyberHintHandlerSet",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "contract IKyberNetwork", name: "newKyberNetwork", type: "address" },
        { indexed: false, internalType: "contract IKyberNetwork", name: "previousKyberNetwork", type: "address" },
      ],
      name: "KyberNetworkSet",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "address", name: "newOperator", type: "address" },
        { indexed: false, internalType: "bool", name: "isAdd", type: "bool" },
      ],
      name: "OperatorAdded",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "contract IERC20", name: "token", type: "address" },
        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
        { indexed: false, internalType: "address", name: "sendTo", type: "address" },
      ],
      name: "TokenWithdraw",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: "address", name: "pendingAdmin", type: "address" }],
      name: "TransferAdminPending",
      type: "event",
    },
    {
      inputs: [{ internalType: "address", name: "newAlerter", type: "address" }],
      name: "addAlerter",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOperator", type: "address" }],
      name: "addOperator",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "admin",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    { inputs: [], name: "claimAdmin", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
      inputs: [],
      name: "enabled",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getAlerters",
      outputs: [{ internalType: "address[]", name: "", type: "address[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract ERC20", name: "src", type: "address" },
        { internalType: "contract ERC20", name: "dest", type: "address" },
        { internalType: "uint256", name: "srcQty", type: "uint256" },
      ],
      name: "getExpectedRate",
      outputs: [
        { internalType: "uint256", name: "expectedRate", type: "uint256" },
        { internalType: "uint256", name: "worstRate", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IERC20", name: "src", type: "address" },
        { internalType: "contract IERC20", name: "dest", type: "address" },
        { internalType: "uint256", name: "srcQty", type: "uint256" },
        { internalType: "uint256", name: "platformFeeBps", type: "uint256" },
        { internalType: "bytes", name: "hint", type: "bytes" },
      ],
      name: "getExpectedRateAfterFee",
      outputs: [{ internalType: "uint256", name: "expectedRate", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getOperators",
      outputs: [{ internalType: "address[]", name: "", type: "address[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "kyberHintHandler",
      outputs: [{ internalType: "contract IKyberHint", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "kyberNetwork",
      outputs: [{ internalType: "contract IKyberNetwork", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "maxGasPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "pendingAdmin",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "alerter", type: "address" }],
      name: "removeAlerter",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "operator", type: "address" }],
      name: "removeOperator",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "contract IKyberHint", name: "_kyberHintHandler", type: "address" }],
      name: "setHintHandler",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "contract IKyberNetwork", name: "_kyberNetwork", type: "address" }],
      name: "setKyberNetwork",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IERC20", name: "token", type: "address" },
        { internalType: "uint256", name: "minConversionRate", type: "uint256" },
      ],
      name: "swapEtherToToken",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IERC20", name: "token", type: "address" },
        { internalType: "uint256", name: "srcAmount", type: "uint256" },
        { internalType: "uint256", name: "minConversionRate", type: "uint256" },
      ],
      name: "swapTokenToEther",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IERC20", name: "src", type: "address" },
        { internalType: "uint256", name: "srcAmount", type: "uint256" },
        { internalType: "contract IERC20", name: "dest", type: "address" },
        { internalType: "uint256", name: "minConversionRate", type: "uint256" },
      ],
      name: "swapTokenToToken",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IERC20", name: "src", type: "address" },
        { internalType: "uint256", name: "srcAmount", type: "uint256" },
        { internalType: "contract IERC20", name: "dest", type: "address" },
        { internalType: "address payable", name: "destAddress", type: "address" },
        { internalType: "uint256", name: "maxDestAmount", type: "uint256" },
        { internalType: "uint256", name: "minConversionRate", type: "uint256" },
        { internalType: "address payable", name: "platformWallet", type: "address" },
      ],
      name: "trade",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract ERC20", name: "src", type: "address" },
        { internalType: "uint256", name: "srcAmount", type: "uint256" },
        { internalType: "contract ERC20", name: "dest", type: "address" },
        { internalType: "address payable", name: "destAddress", type: "address" },
        { internalType: "uint256", name: "maxDestAmount", type: "uint256" },
        { internalType: "uint256", name: "minConversionRate", type: "uint256" },
        { internalType: "address payable", name: "walletId", type: "address" },
        { internalType: "bytes", name: "hint", type: "bytes" },
      ],
      name: "tradeWithHint",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IERC20", name: "src", type: "address" },
        { internalType: "uint256", name: "srcAmount", type: "uint256" },
        { internalType: "contract IERC20", name: "dest", type: "address" },
        { internalType: "address payable", name: "destAddress", type: "address" },
        { internalType: "uint256", name: "maxDestAmount", type: "uint256" },
        { internalType: "uint256", name: "minConversionRate", type: "uint256" },
        { internalType: "address payable", name: "platformWallet", type: "address" },
        { internalType: "uint256", name: "platformFeeBps", type: "uint256" },
        { internalType: "bytes", name: "hint", type: "bytes" },
      ],
      name: "tradeWithHintAndFee",
      outputs: [{ internalType: "uint256", name: "destAmount", type: "uint256" }],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newAdmin", type: "address" }],
      name: "transferAdmin",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newAdmin", type: "address" }],
      name: "transferAdminQuickly",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "amount", type: "uint256" },
        { internalType: "address payable", name: "sendTo", type: "address" },
      ],
      name: "withdrawEther",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IERC20", name: "token", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
        { internalType: "address", name: "sendTo", type: "address" },
      ],
      name: "withdrawToken",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
  STAKING: [
    {
      inputs: [
        { internalType: "contract IERC20", name: "_kncToken", type: "address" },
        { internalType: "uint256", name: "_epochPeriod", type: "uint256" },
        { internalType: "uint256", name: "_startTimestamp", type: "uint256" },
        { internalType: "contract IKyberDao", name: "_kyberDao", type: "address" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "staker", type: "address" },
        { indexed: true, internalType: "address", name: "representative", type: "address" },
        { indexed: true, internalType: "uint256", name: "epoch", type: "uint256" },
        { indexed: false, internalType: "bool", name: "isDelegated", type: "bool" },
      ],
      name: "Delegated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "uint256", name: "curEpoch", type: "uint256" },
        { indexed: true, internalType: "address", name: "staker", type: "address" },
        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "Deposited",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "uint256", name: "curEpoch", type: "uint256" },
        { indexed: true, internalType: "address", name: "staker", type: "address" },
        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "Withdraw",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "uint256", name: "curEpoch", type: "uint256" },
        { indexed: false, internalType: "address", name: "staker", type: "address" },
        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "WithdrawDataUpdateFailed",
      type: "event",
    },
    {
      inputs: [{ internalType: "address", name: "newRepresentative", type: "address" }],
      name: "delegate",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "deposit",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "epochPeriodInSeconds",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "firstEpochStartTimestamp",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getCurrentEpochNumber",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "staker", type: "address" },
        { internalType: "uint256", name: "epoch", type: "uint256" },
      ],
      name: "getDelegatedStake",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "timestamp", type: "uint256" }],
      name: "getEpochNumber",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "staker", type: "address" }],
      name: "getLatestDelegatedStake",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "staker", type: "address" }],
      name: "getLatestRepresentative",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "staker", type: "address" }],
      name: "getLatestStakeBalance",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "staker", type: "address" }],
      name: "getLatestStakerData",
      outputs: [
        { internalType: "uint256", name: "stake", type: "uint256" },
        { internalType: "uint256", name: "delegatedStake", type: "uint256" },
        { internalType: "address", name: "representative", type: "address" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "staker", type: "address" },
        { internalType: "uint256", name: "epoch", type: "uint256" },
      ],
      name: "getRepresentative",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "staker", type: "address" },
        { internalType: "uint256", name: "epoch", type: "uint256" },
      ],
      name: "getStake",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "staker", type: "address" },
        { internalType: "uint256", name: "epoch", type: "uint256" },
      ],
      name: "getStakerData",
      outputs: [
        { internalType: "uint256", name: "stake", type: "uint256" },
        { internalType: "uint256", name: "delegatedStake", type: "uint256" },
        { internalType: "address", name: "representative", type: "address" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "staker", type: "address" },
        { internalType: "uint256", name: "epoch", type: "uint256" },
      ],
      name: "getStakerRawData",
      outputs: [
        { internalType: "uint256", name: "stake", type: "uint256" },
        { internalType: "uint256", name: "delegatedStake", type: "uint256" },
        { internalType: "address", name: "representative", type: "address" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "staker", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
        { internalType: "uint256", name: "curEpoch", type: "uint256" },
      ],
      name: "handleWithdrawal",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "staker", type: "address" }],
      name: "initAndReturnStakerDataForCurrentEpoch",
      outputs: [
        { internalType: "uint256", name: "stake", type: "uint256" },
        { internalType: "uint256", name: "delegatedStake", type: "uint256" },
        { internalType: "address", name: "representative", type: "address" },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "kncToken",
      outputs: [{ internalType: "contract IERC20", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "kyberDao",
      outputs: [{ internalType: "contract IKyberDao", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "withdraw",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
  DAO: [
    {
      inputs: [
        { internalType: "address", name: "admin", type: "address" },
        { internalType: "address", name: "daoOperator", type: "address" },
        { internalType: "address[]", name: "executors", type: "address[]" },
        { internalType: "address[]", name: "votingPowerStrategies", type: "address[]" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "address", name: "newAdmin", type: "address" },
        { indexed: false, internalType: "address", name: "previousAdmin", type: "address" },
      ],
      name: "AdminClaimed",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "uint256", name: "proposalId", type: "uint256" },
        { indexed: true, internalType: "address", name: "creator", type: "address" },
        { indexed: true, internalType: "contract IExecutorWithTimelock", name: "executor", type: "address" },
        { indexed: true, internalType: "contract IVotingPowerStrategy", name: "strategy", type: "address" },
        { indexed: false, internalType: "address[]", name: "targets", type: "address[]" },
        { indexed: false, internalType: "uint256[]", name: "weiValues", type: "uint256[]" },
        { indexed: false, internalType: "string[]", name: "signatures", type: "string[]" },
        { indexed: false, internalType: "bytes[]", name: "calldatas", type: "bytes[]" },
        { indexed: false, internalType: "bool[]", name: "withDelegatecalls", type: "bool[]" },
        { indexed: false, internalType: "uint256", name: "startTime", type: "uint256" },
        { indexed: false, internalType: "uint256", name: "endTime", type: "uint256" },
        { indexed: false, internalType: "string", name: "link", type: "string" },
        { indexed: false, internalType: "uint256", name: "maxVotingPower", type: "uint256" },
      ],
      name: "BinaryProposalCreated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, internalType: "address", name: "newDaoOperator", type: "address" }],
      name: "DaoOperatorTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, internalType: "address", name: "executor", type: "address" }],
      name: "ExecutorAuthorized",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, internalType: "address", name: "executor", type: "address" }],
      name: "ExecutorUnauthorized",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "uint256", name: "proposalId", type: "uint256" },
        { indexed: true, internalType: "address", name: "creator", type: "address" },
        { indexed: true, internalType: "contract IExecutorWithTimelock", name: "executor", type: "address" },
        { indexed: true, internalType: "contract IVotingPowerStrategy", name: "strategy", type: "address" },
        { indexed: false, internalType: "string[]", name: "options", type: "string[]" },
        { indexed: false, internalType: "uint256", name: "startTime", type: "uint256" },
        { indexed: false, internalType: "uint256", name: "endTime", type: "uint256" },
        { indexed: false, internalType: "string", name: "link", type: "string" },
        { indexed: false, internalType: "uint256", name: "maxVotingPower", type: "uint256" },
      ],
      name: "GenericProposalCreated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: "uint256", name: "proposalId", type: "uint256" }],
      name: "ProposalCanceled",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "uint256", name: "proposalId", type: "uint256" },
        { indexed: true, internalType: "address", name: "initiatorExecution", type: "address" },
      ],
      name: "ProposalExecuted",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "uint256", name: "proposalId", type: "uint256" },
        { indexed: false, internalType: "uint256", name: "executionTime", type: "uint256" },
        { indexed: true, internalType: "address", name: "initiatorQueueing", type: "address" },
      ],
      name: "ProposalQueued",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: "address", name: "pendingAdmin", type: "address" }],
      name: "TransferAdminPending",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "uint256", name: "proposalId", type: "uint256" },
        { indexed: true, internalType: "address", name: "voter", type: "address" },
        { indexed: true, internalType: "uint32", name: "voteOptions", type: "uint32" },
        { indexed: false, internalType: "uint224", name: "votingPower", type: "uint224" },
      ],
      name: "VoteEmitted",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "uint256", name: "proposalId", type: "uint256" },
        { indexed: true, internalType: "address", name: "voter", type: "address" },
        { indexed: true, internalType: "uint32", name: "voteOptions", type: "uint32" },
        { indexed: false, internalType: "uint224", name: "oldVotingPower", type: "uint224" },
        { indexed: false, internalType: "uint224", name: "newVotingPower", type: "uint224" },
      ],
      name: "VotingPowerChanged",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, internalType: "address", name: "strategy", type: "address" }],
      name: "VotingPowerStrategyAuthorized",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: true, internalType: "address", name: "strategy", type: "address" }],
      name: "VotingPowerStrategyUnauthorized",
      type: "event",
    },
    {
      inputs: [],
      name: "DOMAIN_TYPEHASH",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "NAME",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "VOTE_EMITTED_TYPEHASH",
      outputs: [{ internalType: "bytes32", name: "", type: "bytes32" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "admin",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address[]", name: "executors", type: "address[]" }],
      name: "authorizeExecutors",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address[]", name: "strategies", type: "address[]" }],
      name: "authorizeVotingPowerStrategies",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "proposalId", type: "uint256" }],
      name: "cancel",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    { inputs: [], name: "claimAdmin", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
      inputs: [
        { internalType: "contract IExecutorWithTimelock", name: "executor", type: "address" },
        { internalType: "contract IVotingPowerStrategy", name: "strategy", type: "address" },
        {
          components: [
            { internalType: "address[]", name: "targets", type: "address[]" },
            { internalType: "uint256[]", name: "weiValues", type: "uint256[]" },
            { internalType: "string[]", name: "signatures", type: "string[]" },
            { internalType: "bytes[]", name: "calldatas", type: "bytes[]" },
            { internalType: "bool[]", name: "withDelegatecalls", type: "bool[]" },
          ],
          internalType: "struct IKyberGovernance.BinaryProposalParams",
          name: "executionParams",
          type: "tuple",
        },
        { internalType: "uint256", name: "startTime", type: "uint256" },
        { internalType: "uint256", name: "endTime", type: "uint256" },
        { internalType: "string", name: "link", type: "string" },
      ],
      name: "createBinaryProposal",
      outputs: [{ internalType: "uint256", name: "proposalId", type: "uint256" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IExecutorWithTimelock", name: "executor", type: "address" },
        { internalType: "contract IVotingPowerStrategy", name: "strategy", type: "address" },
        { internalType: "string[]", name: "options", type: "string[]" },
        { internalType: "uint256", name: "startTime", type: "uint256" },
        { internalType: "uint256", name: "endTime", type: "uint256" },
        { internalType: "string", name: "link", type: "string" },
      ],
      name: "createGenericProposal",
      outputs: [{ internalType: "uint256", name: "proposalId", type: "uint256" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "proposalId", type: "uint256" }],
      name: "execute",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "getDaoOperator",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "proposalId", type: "uint256" }],
      name: "getProposalById",
      outputs: [
        {
          components: [
            { internalType: "uint256", name: "id", type: "uint256" },
            { internalType: "enum IKyberGovernance.ProposalType", name: "proposalType", type: "uint8" },
            { internalType: "address", name: "creator", type: "address" },
            { internalType: "contract IExecutorWithTimelock", name: "executor", type: "address" },
            { internalType: "contract IVotingPowerStrategy", name: "strategy", type: "address" },
            { internalType: "address[]", name: "targets", type: "address[]" },
            { internalType: "uint256[]", name: "weiValues", type: "uint256[]" },
            { internalType: "string[]", name: "signatures", type: "string[]" },
            { internalType: "bytes[]", name: "calldatas", type: "bytes[]" },
            { internalType: "bool[]", name: "withDelegatecalls", type: "bool[]" },
            { internalType: "string[]", name: "options", type: "string[]" },
            { internalType: "uint256[]", name: "voteCounts", type: "uint256[]" },
            { internalType: "uint256", name: "totalVotes", type: "uint256" },
            { internalType: "uint256", name: "maxVotingPower", type: "uint256" },
            { internalType: "uint256", name: "startTime", type: "uint256" },
            { internalType: "uint256", name: "endTime", type: "uint256" },
            { internalType: "uint256", name: "executionTime", type: "uint256" },
            { internalType: "string", name: "link", type: "string" },
            { internalType: "bool", name: "executed", type: "bool" },
            { internalType: "bool", name: "canceled", type: "bool" },
          ],
          internalType: "struct IKyberGovernance.ProposalWithoutVote",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "proposalId", type: "uint256" }],
      name: "getProposalState",
      outputs: [{ internalType: "enum IKyberGovernance.ProposalState", name: "", type: "uint8" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "proposalId", type: "uint256" }],
      name: "getProposalVoteDataById",
      outputs: [
        { internalType: "uint256", name: "", type: "uint256" },
        { internalType: "uint256[]", name: "", type: "uint256[]" },
        { internalType: "string[]", name: "", type: "string[]" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getProposalsCount",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "proposalId", type: "uint256" },
        { internalType: "address", name: "voter", type: "address" },
      ],
      name: "getVoteOnProposal",
      outputs: [
        {
          components: [
            { internalType: "uint32", name: "optionBitMask", type: "uint32" },
            { internalType: "uint224", name: "votingPower", type: "uint224" },
          ],
          internalType: "struct IKyberGovernance.Vote",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "voter", type: "address" },
        { internalType: "uint256", name: "newVotingPower", type: "uint256" },
        { internalType: "uint256[]", name: "proposalIds", type: "uint256[]" },
      ],
      name: "handleVotingPowerChanged",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "executor", type: "address" }],
      name: "isExecutorAuthorized",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "strategy", type: "address" }],
      name: "isVotingPowerStrategyAuthorized",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "pendingAdmin",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "proposalId", type: "uint256" }],
      name: "queue",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "proposalId", type: "uint256" },
        { internalType: "uint256", name: "optionBitMask", type: "uint256" },
      ],
      name: "submitVote",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "proposalId", type: "uint256" },
        { internalType: "uint256", name: "optionBitMask", type: "uint256" },
        { internalType: "uint8", name: "v", type: "uint8" },
        { internalType: "bytes32", name: "r", type: "bytes32" },
        { internalType: "bytes32", name: "s", type: "bytes32" },
      ],
      name: "submitVoteBySignature",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newAdmin", type: "address" }],
      name: "transferAdmin",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newAdmin", type: "address" }],
      name: "transferAdminQuickly",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newDaoOperator", type: "address" }],
      name: "transferDaoOperator",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address[]", name: "executors", type: "address[]" }],
      name: "unauthorizeExecutors",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address[]", name: "strategies", type: "address[]" }],
      name: "unauthorizeVotingPowerStrategies",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
  FEE_HANDLER: [
    {
      inputs: [
        { internalType: "address", name: "_daoSetter", type: "address" },
        { internalType: "contract IKyberProxy", name: "_kyberProxy", type: "address" },
        { internalType: "address", name: "_kyberNetwork", type: "address" },
        { internalType: "contract IERC20", name: "_knc", type: "address" },
        { internalType: "uint256", name: "_burnBlockInterval", type: "uint256" },
        { internalType: "address", name: "_daoOperator", type: "address" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "uint256", name: "rewardBps", type: "uint256" },
        { indexed: false, internalType: "uint256", name: "rebateBps", type: "uint256" },
        { indexed: false, internalType: "uint256", name: "burnBps", type: "uint256" },
        { indexed: false, internalType: "uint256", name: "expiryTimestamp", type: "uint256" },
        { indexed: true, internalType: "uint256", name: "epoch", type: "uint256" },
      ],
      name: "BRRUpdated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "contract ISanityRate", name: "sanityRate", type: "address" },
        { indexed: false, internalType: "uint256", name: "weiToBurn", type: "uint256" },
      ],
      name: "BurnConfigSet",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: "uint256", name: "amount", type: "uint256" }],
      name: "EthReceived",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "contract IERC20", name: "token", type: "address" },
        { indexed: true, internalType: "address", name: "platformWallet", type: "address" },
        { indexed: false, internalType: "uint256", name: "platformFeeWei", type: "uint256" },
        { indexed: false, internalType: "uint256", name: "rewardWei", type: "uint256" },
        { indexed: false, internalType: "uint256", name: "rebateWei", type: "uint256" },
        { indexed: false, internalType: "address[]", name: "rebateWallets", type: "address[]" },
        { indexed: false, internalType: "uint256[]", name: "rebatePercentBpsPerWallet", type: "uint256[]" },
        { indexed: false, internalType: "uint256", name: "burnAmtWei", type: "uint256" },
      ],
      name: "FeeDistributed",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "uint256", name: "kncTWei", type: "uint256" },
        { indexed: true, internalType: "contract IERC20", name: "token", type: "address" },
        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "KncBurned",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: "contract IKyberDao", name: "kyberDao", type: "address" }],
      name: "KyberDaoAddressSet",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: "address", name: "kyberNetwork", type: "address" }],
      name: "KyberNetworkUpdated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: "contract IKyberProxy", name: "kyberProxy", type: "address" }],
      name: "KyberProxyUpdated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "platformWallet", type: "address" },
        { indexed: true, internalType: "contract IERC20", name: "token", type: "address" },
        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "PlatformFeePaid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "rebateWallet", type: "address" },
        { indexed: true, internalType: "contract IERC20", name: "token", type: "address" },
        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "RebatePaid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "staker", type: "address" },
        { indexed: true, internalType: "uint256", name: "epoch", type: "uint256" },
        { indexed: true, internalType: "contract IERC20", name: "token", type: "address" },
        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "RewardPaid",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "uint256", name: "epoch", type: "uint256" },
        { indexed: false, internalType: "uint256", name: "rewardsWei", type: "uint256" },
      ],
      name: "RewardsRemovedToBurn",
      type: "event",
    },
    {
      inputs: [],
      name: "brrAndEpochData",
      outputs: [
        { internalType: "uint64", name: "expiryTimestamp", type: "uint64" },
        { internalType: "uint32", name: "epoch", type: "uint32" },
        { internalType: "uint16", name: "rewardBps", type: "uint16" },
        { internalType: "uint16", name: "rebateBps", type: "uint16" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "burnBlockInterval",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "burnKnc",
      outputs: [{ internalType: "uint256", name: "kncBurnAmount", type: "uint256" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "platformWallet", type: "address" }],
      name: "claimPlatformFee",
      outputs: [{ internalType: "uint256", name: "amountWei", type: "uint256" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "rebateWallet", type: "address" }],
      name: "claimReserveRebate",
      outputs: [{ internalType: "uint256", name: "amountWei", type: "uint256" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "staker", type: "address" },
        { internalType: "uint256", name: "epoch", type: "uint256" },
      ],
      name: "claimStakerReward",
      outputs: [{ internalType: "uint256", name: "amountWei", type: "uint256" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "daoOperator",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "daoSetter",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "feePerPlatformWallet",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getBRR",
      outputs: [
        { internalType: "uint256", name: "rewardBps", type: "uint256" },
        { internalType: "uint256", name: "rebateBps", type: "uint256" },
        { internalType: "uint256", name: "epoch", type: "uint256" },
      ],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "getLatestSanityRate",
      outputs: [{ internalType: "uint256", name: "kncToEthSanityRate", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getSanityRateContracts",
      outputs: [{ internalType: "contract ISanityRate[]", name: "sanityRates", type: "address[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IERC20", name: "token", type: "address" },
        { internalType: "address[]", name: "rebateWallets", type: "address[]" },
        { internalType: "uint256[]", name: "rebateBpsPerWallet", type: "uint256[]" },
        { internalType: "address", name: "platformWallet", type: "address" },
        { internalType: "uint256", name: "platformFee", type: "uint256" },
        { internalType: "uint256", name: "networkFee", type: "uint256" },
      ],
      name: "handleFees",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "", type: "address" },
        { internalType: "uint256", name: "", type: "uint256" },
      ],
      name: "hasClaimedReward",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "knc",
      outputs: [{ internalType: "contract IERC20", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "kyberDao",
      outputs: [{ internalType: "contract IKyberDao", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "kyberNetwork",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "kyberProxy",
      outputs: [{ internalType: "contract IKyberProxy", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "lastBurnBlock",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "epoch", type: "uint256" }],
      name: "makeEpochRewardBurnable",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "readBRRData",
      outputs: [
        { internalType: "uint256", name: "rewardBps", type: "uint256" },
        { internalType: "uint256", name: "rebateBps", type: "uint256" },
        { internalType: "uint256", name: "expiryTimestamp", type: "uint256" },
        { internalType: "uint256", name: "epoch", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "rebatePerWallet",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "rewardsPaidPerEpoch",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      name: "rewardsPerEpoch",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract ISanityRate", name: "_sanityRate", type: "address" },
        { internalType: "uint256", name: "_weiToBurn", type: "uint256" },
      ],
      name: "setBurnConfigParams",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "contract IKyberDao", name: "_kyberDao", type: "address" }],
      name: "setDaoContract",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "contract IKyberProxy", name: "_newProxy", type: "address" }],
      name: "setKyberProxy",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "_kyberNetwork", type: "address" }],
      name: "setNetworkContract",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "totalPayoutBalance",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "weiToBurn",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ],
  REWARDS_CLAIMER: [
    {
      inputs: [
        { internalType: "contract IKyberDao", name: "_kyberDao", type: "address" },
        { internalType: "address", name: "_admin", type: "address" },
      ],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "address", name: "newAdmin", type: "address" },
        { indexed: false, internalType: "address", name: "previousAdmin", type: "address" },
      ],
      name: "AdminClaimed",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "address", name: "newAlerter", type: "address" },
        { indexed: false, internalType: "bool", name: "isAdd", type: "bool" },
      ],
      name: "AlerterAdded",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
        { indexed: false, internalType: "address", name: "sendTo", type: "address" },
      ],
      name: "EtherWithdraw",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "address", name: "newOperator", type: "address" },
        { indexed: false, internalType: "bool", name: "isAdd", type: "bool" },
      ],
      name: "OperatorAdded",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "contract IERC20", name: "token", type: "address" },
        { indexed: false, internalType: "uint256", name: "amount", type: "uint256" },
        { indexed: false, internalType: "address", name: "sendTo", type: "address" },
      ],
      name: "TokenWithdraw",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: "address", name: "pendingAdmin", type: "address" }],
      name: "TransferAdminPending",
      type: "event",
    },
    {
      inputs: [{ internalType: "address", name: "newAlerter", type: "address" }],
      name: "addAlerter",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOperator", type: "address" }],
      name: "addOperator",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "admin",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    { inputs: [], name: "claimAdmin", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
      inputs: [
        { internalType: "contract IFeeHandler", name: "feeHandler", type: "address" },
        { internalType: "uint256[]", name: "unclaimedEpochs", type: "uint256[]" },
      ],
      name: "claimMultipleRewards",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "getAlerters",
      outputs: [{ internalType: "address[]", name: "", type: "address[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getOperators",
      outputs: [{ internalType: "address[]", name: "", type: "address[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IFeeHandler", name: "feeHandler", type: "address" },
        { internalType: "address", name: "staker", type: "address" },
      ],
      name: "getUnclaimedEpochs",
      outputs: [{ internalType: "uint256[]", name: "unclaimedEpochs", type: "uint256[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "kyberDao",
      outputs: [{ internalType: "contract IKyberDao", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "pendingAdmin",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "alerter", type: "address" }],
      name: "removeAlerter",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "operator", type: "address" }],
      name: "removeOperator",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newAdmin", type: "address" }],
      name: "transferAdmin",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newAdmin", type: "address" }],
      name: "transferAdminQuickly",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "amount", type: "uint256" },
        { internalType: "address payable", name: "sendTo", type: "address" },
      ],
      name: "withdrawEther",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IERC20", name: "token", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
        { internalType: "address", name: "sendTo", type: "address" },
      ],
      name: "withdrawToken",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
  MIGRATE: [
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "owner", type: "address" },
        { indexed: true, internalType: "address", name: "spender", type: "address" },
        { indexed: false, internalType: "uint256", name: "value", type: "uint256" },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "account", type: "address" },
        { indexed: true, internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "Migrated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "account", type: "address" },
        { indexed: true, internalType: "uint256", name: "amount", type: "uint256" },
        { indexed: true, internalType: "address", name: "minter", type: "address" },
      ],
      name: "Minted",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "oldMinter", type: "address" },
        { indexed: true, internalType: "address", name: "newMinter", type: "address" },
      ],
      name: "MinterChanged",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "previousOwner", type: "address" },
        { indexed: true, internalType: "address", name: "newOwner", type: "address" },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "from", type: "address" },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        { indexed: false, internalType: "uint256", name: "value", type: "uint256" },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "spender", type: "address" },
      ],
      name: "allowance",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "approve",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "account", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "burn",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "burnFrom",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newMinter", type: "address" }],
      name: "changeMinter",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "subtractedValue", type: "uint256" },
      ],
      name: "decreaseAllowance",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IERC20", name: "token", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "emergencyERC20Drain",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "addedValue", type: "uint256" },
      ],
      name: "increaseAllowance",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "_oldKNC", type: "address" },
        { internalType: "address", name: "_minter", type: "address" },
      ],
      name: "initialize",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "mint",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
      name: "mintWithOldKnc",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "minter",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "oldKNC",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    { inputs: [], name: "renounceOwnership", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "totalSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "transfer",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "sender", type: "address" },
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
      name: "transferOwnership",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
  ],
  REWARDS_DISTRIBUTOR: [
    {
      inputs: [{ internalType: "address", name: "admin", type: "address" }],
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: false, internalType: "address", name: "newAdmin", type: "address" },
        { indexed: false, internalType: "address", name: "previousAdmin", type: "address" },
      ],
      name: "AdminClaimed",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "uint256", name: "cycle", type: "uint256" },
        { indexed: true, internalType: "address", name: "user", type: "address" },
        { indexed: false, internalType: "contract IERC20Ext[]", name: "tokens", type: "address[]" },
        { indexed: false, internalType: "uint256[]", name: "claimAmounts", type: "uint256[]" },
      ],
      name: "Claimed",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "uint256", name: "cycle", type: "uint256" },
        { indexed: false, internalType: "bytes32", name: "root", type: "bytes32" },
        { indexed: false, internalType: "string", name: "contentHash", type: "string" },
      ],
      name: "RootUpdated",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [{ indexed: false, internalType: "address", name: "pendingAdmin", type: "address" }],
      name: "TransferAdminPending",
      type: "event",
    },
    {
      inputs: [],
      name: "BPS",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "admin",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "cycle", type: "uint256" },
        { internalType: "uint256", name: "index", type: "uint256" },
        { internalType: "address", name: "user", type: "address" },
        { internalType: "contract IERC20Ext[]", name: "tokens", type: "address[]" },
        { internalType: "uint256[]", name: "cumulativeAmounts", type: "uint256[]" },
        { internalType: "bytes32[]", name: "merkleProof", type: "bytes32[]" },
      ],
      name: "claim",
      outputs: [{ internalType: "uint256[]", name: "claimAmounts", type: "uint256[]" }],
      stateMutability: "nonpayable",
      type: "function",
    },
    { inputs: [], name: "claimAdmin", outputs: [], stateMutability: "nonpayable", type: "function" },
    {
      inputs: [
        { internalType: "address", name: "", type: "address" },
        { internalType: "contract IERC20Ext", name: "", type: "address" },
      ],
      name: "claimedAmounts",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "cycle", type: "uint256" },
        { internalType: "uint256", name: "index", type: "uint256" },
        { internalType: "address", name: "account", type: "address" },
        { internalType: "contract IERC20Ext[]", name: "tokens", type: "address[]" },
        { internalType: "uint256[]", name: "cumulativeAmounts", type: "uint256[]" },
      ],
      name: "encodeClaim",
      outputs: [
        { internalType: "bytes", name: "encodedData", type: "bytes" },
        { internalType: "bytes32", name: "encodedDataHash", type: "bytes32" },
      ],
      stateMutability: "pure",
      type: "function",
    },
    {
      inputs: [
        { internalType: "address", name: "user", type: "address" },
        { internalType: "contract IERC20Ext[]", name: "tokens", type: "address[]" },
      ],
      name: "getClaimedAmounts",
      outputs: [{ internalType: "uint256[]", name: "userClaimedAmounts", type: "uint256[]" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "getMerkleData",
      outputs: [
        {
          components: [
            { internalType: "uint256", name: "cycle", type: "uint256" },
            { internalType: "bytes32", name: "root", type: "bytes32" },
            { internalType: "string", name: "contentHash", type: "string" },
          ],
          internalType: "struct RewardsDistributor.MerkleData",
          name: "",
          type: "tuple",
        },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "cycle", type: "uint256" },
        { internalType: "uint256", name: "index", type: "uint256" },
        { internalType: "address", name: "user", type: "address" },
        { internalType: "contract IERC20Ext[]", name: "tokens", type: "address[]" },
        { internalType: "uint256[]", name: "cumulativeAmounts", type: "uint256[]" },
        { internalType: "bytes32[]", name: "merkleProof", type: "bytes32[]" },
      ],
      name: "isValidClaim",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [],
      name: "pendingAdmin",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [
        { internalType: "uint256", name: "cycle", type: "uint256" },
        { internalType: "bytes32", name: "root", type: "bytes32" },
        { internalType: "string", name: "contentHash", type: "string" },
      ],
      name: "proposeRoot",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [
        { internalType: "contract IPool", name: "treasuryPool", type: "address" },
        { internalType: "contract IERC20Ext[]", name: "tokens", type: "address[]" },
        { internalType: "uint256[]", name: "amounts", type: "uint256[]" },
      ],
      name: "pullFundsFromTreasury",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newAdmin", type: "address" }],
      name: "transferAdmin",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "newAdmin", type: "address" }],
      name: "transferAdminQuickly",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    { stateMutability: "payable", type: "receive" },
  ],
};

export const ACTIONS = {
  APPROVE: 1,
  STAKE: 2,
  VOTE: 3,
  DELEGATE: 4,
  WITHDRAW: 5,
  CLAIM_REWARDS: 7,
  MIGRATE: 8,
  QUEUE: 9,
  EXECUTE: 10,
  CANCEL: 11,
};

export const STAKING_STEPS = {
  DEFAULT: 0,
  APPROVE_ZERO: 1,
  APPROVE_MAX: 2,
  CONFIRM: 3,
  BROADCAST: 4,
  SUCCESS: 5,
};

export const WITHDRAW_STEPS = {
  DEFAULT: 0,
  CONFIRM: 1,
  BROADCAST: 2,
  SUCCESS: 3,
};

export const CLAIM_REWARD_STEPS = {
  DEFAULT: 0,
  CONFIRM: 1,
  BROADCAST: 2,
  SUCCESS: 3,
};

export const BROADCAST_STATUSES = {
  BROADCAST: 0,
  SUCCESS: 1,
  ERROR: 2,
};

export const PROPOSAL_STATUSES = {
  ACTIVE: "Active",
  UPCOMING: "Pending",
  ENDED: "Ended",
  SUCCEEDED: "Succeeded",
  QUEUED: "Queued",
  EXECUTED: "Executed",
  FINALIZED: "Finalized",
  CANCELED: "Canceled",
  FAILED: "Failed",
  EXPIRED: "Expired",
};

export const PROPOSAL_TYPES = {
  FEE: "Fee",
  BRR: "BRR",
  GENERAL: "General",
  PROTOCOL: "Protocol",
  RESERVES: "Reserves",
  GENERIC: "GenericProposal",
  BINARY: "BinaryProposal",
};

export const BALANCE_PERCENTAGES = [25, 50, 100];

export const DEFAULT_GAS_PRICE = "Standard";

export const GAS_PRICE_LIMIT = 150;

export const DEFAULT_GAS = {
  APPROVE: 100000,
  APPROVE_MAX: 70000,
  STAKE: 300000,
  WITHDRAW: 300000,
  VOTE: 300000,
  DELEGATE: 350000,
  CLAIM_REWARDS: 1000000,
};

export const FETCHING_INTERVALS = {
  BALANCE: 300000,
  VOTING_REWARD: 300000,
  TX_MINE: 3000,
  HEADER_DATA: 30000,
  GAS_PRICE: 60000,
  DEFAULT: 10000,
};

export const WALLET_TYPES = {
  METAMASK: "metamask",
  KEYSTORE: "keystore",
  WALLET_CONNECT: "wallet-connect",
  WALLET_LINK: "wallet-link",
  TREZOR: "trezor",
  LEDGER: "ledger",
  DAPP: "Dapp",
};

export const TX_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  FAILED: "failed",
};

export const TOPICS = {
  VOTE: "0x612a68349ed2a41418dc9795d6525cab0c1f41d11b97b3dff2dc55695dfbdec2",
  STAKE: "0x1599c0fcf897af5babc2bfcf707f5dc050f841b044d97c3251ecec35b9abf80b",
  DELEGATE: "0xfbb976ae5268347766b726bd1edba29af0fe16f9c505fbd3b9a10cb6d00cfa3d",
  CLAIM: "0x1869cd8d657c37384f585ccf16d7d1baddfcb0552617440f4fefe638726094c4",
  WITHDRAW: "0x9da6493a92039daf47d1f2d7a782299c5994c6323eb1e972f69c432089ec52bf",
  MIGRATE: "0x8b80bd19aea7b735bc6d75db8d6adbe18b28c30d62b3555245eb67b2340caedc",
};

export const LIMIT = {
  DEVICE_ADDRESS: 5,
  VOTER_NAME: 3,
};

export const TIMEOUT_IN_MS = 600000;

export const MOBILE_SCREEN_SIZE = 1000;

export const PAGING = {
  PROPOSAL_DISPLAY: 10,
  HISTORY_DISPLAY: 20,
  ADDRESS_DISPLAY: 6,
};

export const MINIMUM_PERCENT = 0.01;

export const LEDGER_DERIVATION_PATHS = [
  { value: "m/44'/60'", desc: "Ledger Live", bip44: true },
  { value: "m/44'/60'/0'", desc: "Ledger Legacy" },
  { defaultValue: "m/44'/60'/1'/0", desc: "Your Custom Path", custom: true },
];

export const TREZOR_DERIVATION_PATHS = [
  { value: "m/44'/60'/0'/0", desc: "Trezor (ETH)" },
  { value: "m/44'/61'/0'/0", desc: "Trezor (ETC)" },
  { defaultValue: "m/44'/60'/1'/0", desc: "Your Custom Path", custom: true },
];

export const MAXIMUM_CLAIMABLE_EPOCHS = 12;

export const ABOUT_VIDEO_IDS = ["SOppp6nfkq0", "RgCxVYiuG64", "0xI_dKD6Wkw", "_1LHgANRtGk"];
export const INTRODUCTION_VIDEO_ID = "HFPlKULkp_U";
export const TUTORIAL_VIDEO_ID = "WBmqd5KBw2s";
export const TERMS_LINK = "https://files.kyber.network/dao-tac.pdf";

export const TIME_RANGE = {
  ALL: "All",
  A_YEAR: "1 Year",
  THREE_MONTH: "3 Months",
  A_MONTH: "1 Month",
  A_WEEK: "7 Days",
};
