import React, { useEffect }  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { formatAddress } from "src/app/utils/fortmaters";
import { approveToken, setApproveError } from "src/app/actions/approveAction";
import { ACTIONS, DEFAULT_GAS } from "src/app/configs/constants";
import ModalPanel from "src/app/components/Modals/ModalPanel";
import { setTxConfirming } from "src/app/actions/accountAction";
import GasOption from "src/app/components/Commons/GasOption";
import ENV from 'src/app/configs/env';

export default function ApproveTokenModal(props) {
  const dispatch = useDispatch();
  const { address, txConfirming, privateKey } = useSelector((state) => state.account);
  const { approveError,  } = useSelector((state) => state.approve);

  useEffect(() => {
    dispatch(setApproveError(''));
    dispatch(setTxConfirming(false));
  }, [dispatch]);
  function approve() {
    dispatch(approveToken(props.isApproveToMax, props.token, props.delegator));
  }

  return (
    <div className="modal__delegate modal-interact">
      <div className="modal-interact">
        <div className="modal__block">
          <div className="modal__title">
            {props.isApproveToMax ? 'Approve Token' : 'Reset Allowance'}
          </div>
        </div>

        <div className="modal__block grey">
          <div className="modal__text light mb-3">
            {props.isApproveToMax
              ? `You need to grant permission to ${props.delegator === ENV.KNC_ADDRESS ? 'new KNC token ' : 'KyberDAO ' } smart contract to interact with KNC in your wallet.`
              : 'Your KNC allowance is insufficient for KyberDAO to make a transaction, you need to reset it first.'
            }
          </div>
          <div className="modal__text bold mb-1">Your wallet address: {formatAddress(address, 10, -6)}</div>
        </div>

        <div className="modal__block pt-0">
          <GasOption
            txType={ACTIONS.APPROVE}
            txParams={{ address: address, isApproveToMax: props.isApproveToMax }}
            defaultGasLimit={DEFAULT_GAS.APPROVE}
          />
        </div>

        <ModalPanel
          error={approveError}
          txConfirming={txConfirming}
          buttonConfirm={props.isApproveToMax ? 'Approve' : 'Reset'}
          handleCancel={props.close}
          handleConfirm={approve}
          showWatingMessage={!privateKey}
        />
      </div>
    </div>
  )
}
