import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { vote } from "src/app/actions/voteAction";
import { setVoteError } from "src/app/actions/voteAction";
import { ACTIONS, DEFAULT_GAS } from "src/app/configs/constants";
import ModalPanel from "src/app/components/Modals/ModalPanel";
import { setTxConfirming } from "src/app/actions/accountAction";
import GasOption from "src/app/components/Commons/GasOption";

export default function VoteConfirmModal({close, campID, option}) {
  const dispatch = useDispatch();
  const { address, txConfirming, privateKey } = useSelector((state) => state.account);
  const { voteError } = useSelector((state) => state.vote);

  useEffect(() => {
    dispatch(setVoteError(''));
    dispatch(setTxConfirming(false));
  }, [dispatch]);

  const voteSubmit = () => {
    dispatch(vote(campID, option));
  };

  return (
    <div className="modal__vote modal-interact">
      <div className="modal-content">
        <div className="title">Submit Vote</div>
        <div className="info">Do you wish to submit your vote?</div>
        <div className="note mt-4 mb-4">
          <span>Note: You will not be able to withdraw your vote. However, you can still change your vote later.</span>
        </div>

        <GasOption
          txType={ACTIONS.VOTE}
          txParams={{ address, campID, option }}
          defaultGasLimit={DEFAULT_GAS.VOTE}
        />
      </div>

      <ModalPanel
        error={voteError}
        txConfirming={txConfirming}
        handleCancel={close}
        handleConfirm={voteSubmit}
        showWatingMessage={!privateKey}
      />
    </div>
  )
}
