import React from 'react'
import { useSelector } from "react-redux";
import { ACTIONS, DEFAULT_GAS } from "src/app/configs/constants";
import GasOption from "src/app/components/Commons/GasOption";
import { roundNumber } from "src/app/utils/fortmaters";
import { compareTwoNumber } from 'src/app/utils/calculators';

export default function ClaimRewardsFormModal({ claimingAmount, address, openClaimingModal }) {
  const { rewards } = useSelector((state) => state.reward);

  function showClaimingAmount(amount) {
    if (compareTwoNumber(amount, 0) === 0) {
      return 0
    } else if (compareTwoNumber(amount, 0.0001) === -1) {
      return '< 0.0001'
    } else {
      return roundNumber(amount, 6)
    }
  }

  return (
    <div className="modal__claim modal-interact medium">
      <div className="modal-content">
        <div className="text-center pt-3 pb-4">
          <div className="modal-content__title">{showClaimingAmount(claimingAmount)} <span className="text-success">KNC</span></div>
          <div className="modal-content__sub-title">Unclaimed Rewards</div>
        </div>

        <GasOption
          txType={ACTIONS.CLAIM_REWARDS}
          txParams={{
            cycle: rewards.cycle,
            index: rewards.index,
            user: address,
            tokens: rewards.tokens,
            cumulativeAmounts: rewards.cumulativeAmounts,
            merkleProof: rewards.merkleProof,
          }}
          defaultGasLimit={DEFAULT_GAS.CLAIM_REWARDS}
          isOpened={true}
        />
      </div>

      <div className="panel reward__button-container">
        <div className="reward__button common__button" onClick={openClaimingModal}>Claim</div>
      </div>
    </div>
  )
}
