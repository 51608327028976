import React, { useState, useEffect, useRef } from "react";
import eyeOpen from "src/assets/images/icons/eye-open.svg";
import eyeClose from "src/assets/images/icons/eye-close.svg";

export default function InputPasswordModal(props) {
  const [isShowPW, setIsShowPW] = useState(false);
  const inputRef = useRef(null);
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    setTimeout(() => {
      inputRef.current && inputRef.current.focus();
    }, 100);
  });

  function handleSubmit(e) {
    e.preventDefault();
    if (typeof props.onSubmit == "function") {
      props.onSubmit(text)(setError);
    }
  }

  function onTextChange(e) {
    setError("");
    setText(e.target.value);
  }

  return (
    <div className={`modal-interact`}>
      <div className="modal-content">
        <div className="title">{props.title}</div>
        <div className="common__input-password">
          <form onSubmit={handleSubmit}>
            <input
              type={isShowPW ? "text" : "password"}
              placeholder={props.title}
              onChange={onTextChange}
              ref={inputRef}
            />
          </form>
          <img src={isShowPW ? eyeOpen : eyeClose} alt="lock" onClick={() => setIsShowPW(!isShowPW)}/>
          {error && <div className="common__error common__slide-up mt-3">{error}</div>}
        </div>
      </div>
      <div className="panel">
        <div className="common__button common__button--hollow" onClick={props.close}>Cancel</div>
        <div className="common__button" onClick={handleSubmit}>Confirm</div>
      </div>
    </div>
  );
}
