import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modal } from "../Modals/Modal";
import { WALLET_TYPES } from "src/app/configs/constants";
import { unlock, addressFromKey } from "src/app/utils/keys";
import InputPasswordModal from "../Modals/InputPasswordModal";
import { importAccount } from "src/app/actions/accountAction";
import KeystoreService from "src/app/services/accounts/KeystoreService";

// Only for KeyStore and PrivateKey, all called as pkey
export default function useCheckAccountImported() {
  const dispatch = useDispatch();
  const { type, privateKey, keyString } = useSelector((state) => state.account);
  const [openPassphareModal, setOpenPassphareModal] = useState(false);
  const [accountIsImported, setAccountIsImported] = useState(false);
  const [onAccountImported, setOnAccountImported] = useState(null);

  useEffect(() => {
    function unLockKeyStore(passphrase) {
      try {
        const wallet = new KeystoreService();
        const privateKey = unlock(keyString, passphrase, true);
        const address = addressFromKey(keyString);
        setAccountIsImported(true);
        dispatch(
          importAccount(address, wallet, WALLET_TYPES.KEYSTORE, {
            privateKey: privateKey.toString("hex"),
            keyString: keyString,
          })
        );

        modal.close();
        if (typeof onAccountImported == "function") {
          onAccountImported();
        }
        return () => {};
      } catch (e) {
        return (fn) => fn(e.message);
      }
    }

    if (openPassphareModal && type === WALLET_TYPES.KEYSTORE && !privateKey) {
      modal.show(
        InputPasswordModal,
        {
          title: "Enter your passphrase",
          onSubmit: (passphrase) => unLockKeyStore(passphrase),
        },
        () => setOpenPassphareModal(false)
      );
    }
  }, [type, privateKey, openPassphareModal, dispatch, keyString, onAccountImported]);

  useEffect(() => {
    setAccountIsImported(
      (type === WALLET_TYPES.KEYSTORE && !!privateKey) || type !== WALLET_TYPES.KEYSTORE
    )
  }, [privateKey, type]);

  function openActionModal(callback) {
    if (!accountIsImported) {
      setOpenPassphareModal(true);
      setOnAccountImported(() => callback);
    } else {
      callback();
    }
  }

  return { openActionModal };
}
