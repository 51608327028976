const envConfig = {
  NETWORK_ID: 1,
  CHAIN_NAME: "mainnet",
  URLS: {
    ETHERSCAN: "https://etherscan.io",
    ENJINX: "https://kyber.enjinx.io",
    DAOV1: "",
  },
  NODE: {
    URL: "https://ethereum.knstats.com/v1/mainnet/node",
    CONNECTION_TIMEOUT: 6000,
  },
  NODE_MATIC: {
    URL: "https://polygon.dmm.exchange/v1/mainnet/geth?appId=prod-dmm",
    CONNECTION_TIMEOUT: 6000,
  },
  CONTRACTS: {
    PROXY: "0xc153eead19e0dbbdb3462dcc2b703cc6d738a37c",
    STAKING: "0x9f349Eb72C3E07F6E840978C65a8BF3b9C74C4f8",
    DAO: "0x39E507f4F7c3e85eD799FDbdf04E42104a8f6b9A",
    REWARDS_DISTRIBUTOR: "", // no contract
    FEE_HANDLER: "0xEc30037C9A8A6A3f42734c30Dfa0a208aF71b40C",
    REWARDS_CLAIMER: "0xfa68D9F61326223C7651Fbd2011221Ff561098FA",
  },
  KNC_ADDRESS: "0xdd974d5c2e2928dea5f71b9825b8b646686bd200",
  KNC_TOTAL_SUPPLY: 210757979,
  APIS: {
    DAO: "https://staging-dao-stats-v2.knstats.com",
    CACHE: "https://production-cache.kyber.network",
    KN: "https://api.kyber.network",
    THE_GRAPH:
      "https://api.thegraph.com/subgraphs/name/namnm1991/kyber-dao-info",
  },
};

export default envConfig;
