import BigNumber from "bignumber.js";
import { PROPOSAL_TYPES, TIME_RANGE, WALLET_TYPES } from "../configs/constants";
import MetamaskService from "../services/accounts/MetamaskService";
import KeystoreService from "../services/accounts/KeystoreService";
import WalletConnectService from "../services/accounts/WalletConnectService";
import WalletLinkService from "../services/accounts/WalletLinkService";
import TrezorService from "../services/accounts/Devices/TrezorService";
import LedgerService from "../services/accounts/Devices/LedgerService";
import DappService from "../services/accounts/DappService";
import { verifyContainString } from "./validators";

export function getNumberByPercentage(number, percentage) {
  if (percentage === 100) return number;

  const bigNumber = new BigNumber(number.toString());

  return bigNumber.multipliedBy(percentage / 100).toString();
}

export function toGwei(number) {
  const bigNumber = new BigNumber(number.toString());
  return bigNumber.div(1000000000).toString();
}

export function toWei(number) {
  return toBigAmount(number, 9);
}

export function toBigAmount(number, decimal = 18) {
  const bigNumber = new BigNumber(number.toString());
  return bigNumber.times(Math.pow(10, decimal)).toFixed(0)
}

export function toHex(number) {
  const bigNumber = new BigNumber(number);
  return "0x" + bigNumber.toString(16);
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

export function getCurrentTimestamp() {
  return Math.floor(Date.now() / 1000);
}

export function fromNetworkIdToName(networkId) {
  let networkName = 'Unknown Network';

  if (networkId === 1) {
    networkName = 'Mainnet';
  } else if (networkId === 3) {
    networkName = 'Ropsten Network';
  } else if (networkId === 4) {
    networkName = 'Rinkeby Network';
  } else if (networkId === 5) {
    networkName = 'Goerli Test Network';
  } else if (networkId === 42) {
    networkName = 'Kovan Network';
  }

  return networkName;
}

export function getWalletByType(address, type) {
  let wallet = null;
  const props = { address };

  if (type === WALLET_TYPES.METAMASK) {
    wallet = new MetamaskService(props);
  } else if (type === WALLET_TYPES.KEYSTORE) {
    wallet = new KeystoreService(props);
  } else if (type === WALLET_TYPES.WALLET_CONNECT) {
    wallet = new WalletConnectService(props);
  } else if (type === WALLET_TYPES.WALLET_LINK) {
    wallet = new WalletLinkService(props);
  } else if (type === WALLET_TYPES.TREZOR) {
    wallet = new TrezorService(props);
  } else if (type === WALLET_TYPES.LEDGER) {
    wallet = new LedgerService(props);
  } else if (type === WALLET_TYPES.DAPP) {
    wallet = new DappService(props);
  }

  return wallet;
}

export function getSocialIconByLink(link) {
  let icon = 'default';

  if (verifyContainString(link, 'discord')) {
    icon = 'discord';
  } else if (verifyContainString(link, 'github')) {
    icon = 'github';
  } else if (verifyContainString(link, 'twitter')) {
    icon = 'twitter';
  } else if (verifyContainString(link, 'blog.kyber.network') || verifyContainString(link, 'medium')) {
    icon = 'medium';
  }

  return icon;
}

export function getTagColorAndStatus(tag, activeTags = [], isClickable = false) {
  let tagColor = "grey";
  let active = "";
  let clickable = "";

  if (tag === PROPOSAL_TYPES.FEE || tag === PROPOSAL_TYPES.BINARY) {
    tagColor = "blue";
  } else if (tag === PROPOSAL_TYPES.GENERAL || tag === PROPOSAL_TYPES.GENERIC) {
    tagColor = "orange";
  } else if (tag === PROPOSAL_TYPES.BRR) {
    tagColor = "violet";
  } else if (tag === PROPOSAL_TYPES.RESERVES) {
    tagColor = "sky";
  } else if (tag === PROPOSAL_TYPES.PROTOCOL) {
    tagColor = "green";
  }

  if (isClickable) {
    if (activeTags.indexOf(tag) !== -1) {
      active = "active";
    }

    clickable = "clickable";
  }

  return { active, clickable, tagColor }
}

export function convertTextToDays(timeRange = TIME_RANGE.ALL) {
  let rowNumber;

  switch (timeRange) {
    case TIME_RANGE.A_WEEK:
      rowNumber = 7;
      break;
    case TIME_RANGE.A_MONTH:
      rowNumber = 30;
      break;
    case TIME_RANGE.THREE_MONTH:
      rowNumber = 90;
      break;
    case TIME_RANGE.A_YEAR:
      rowNumber = 365;
      break;
    default:
      rowNumber = 1000;
  }

  return rowNumber;
}

export function shortenNumber(number) {
  const symbol = ["", "k", "M", "G", "T", "P", "E"];
  const tier = Math.log10(Math.abs(number)) / 3 | 0;

  if (tier === 0) return number;

  const suffix = symbol[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;

  return scaled.toFixed(1) + suffix;
}