import React, { Component } from 'react';
import { withRouter } from "react-router";
import ENV from "src/app/configs/env";
import Header from "src/app/components/Layouts/Header";
import Footer from "src/app/components/Layouts/Footer";
import errorBlocks from "src/assets/images/backgrounds/error-blocks.svg";

class ComponentErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorStack: null
    };

    const { history } = this.props;

    history.listen(() => {
      if (this.state.error) this.setState({ error: null });
    });
  }

  componentDidCatch(error) {
    this.setState({
      error: error.message,
      errorStack: error.stack
    })
  }

  render() {
    if (this.state.error) {
      return (
        <div className="body-wrapper body-wrapper--page error-page">
          <Header/>
          <img className="error-page__image" src={errorBlocks} alt="error-blocks"/>
          <p className="error-page__text">Sorry, there is an unexpected error.</p>
          <p className="error-page__text">We are working to fix the problem. Be back soon.</p>

          {ENV.NETWORK_ID !== 1 && (
            <div>
              <div className="error-page__text-error mt-5">{this.state.error}</div>
              <pre className="error-page__stack">{this.state.errorStack}</pre>
            </div>
          )}
          <Footer/>
        </div>
      )
    }

    return this.props.children;
  }
}

export default withRouter(ComponentErrorBoundary);
