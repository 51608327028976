import React from 'react';
import { useDispatch } from "react-redux";
import { importAccount } from "src/app/actions/accountAction";
import { WALLET_TYPES } from "src/app/configs/constants";
import WalletLinkService from "src/app/services/accounts/WalletLinkService";
import { modal } from "src/app/components/Modals/Modal";
import BasicModal from "src/app/components/Modals/BasicModal";

export default function WalletLinkAccount(props) {
  const dispatch = useDispatch();

  async function connect() {
    const wallet = new WalletLinkService();
    const address = await wallet.connect(openEthereumErrorModal);

    if (address) {
      dispatch(importAccount(address, wallet, WALLET_TYPES.WALLET_LINK));
      modal.close();
    }
  }

  function openEthereumErrorModal() {
    modal.show(BasicModal, {
      title: "Error",
      content: <div>Cannot connect to Coinbase</div>
    });
  }

  return (
    <div className={`account__item ${props.className}`} onClick={connect}>
      <div className="account__item-icon coinbase-wallet"/>
      <div className="account__item-name">Coinbase</div>
    </div>
  )
};
