import { historyActionTypes } from "src/app/actions/historyAction";

const initialState = {
  histories: null,
  page: 1
};

export default function historyReducer(state = initialState, action) {
  switch (action.type) {
    case historyActionTypes.SET_HISTORIES: {
      return {
        ...state,
        histories: action.payload
      }
    }
    case historyActionTypes.SET_PAGE: {
      return {
        ...state,
        page: action.payload
      }
    }
    default:
      return state
  }

}