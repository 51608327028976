import React from 'react'
import CopyableTx from "src/app/components/Commons/CopyableTx";

export default function VoteSuccessModal({close, txHash}) {
  return (
    <div className="modal__vote modal-interact">
      <div className="modal__tx-done">
        <div className="modal-content">
          <div className="title">Your vote has been submitted.</div>
          <CopyableTx txHash={txHash} />
          <div className="note">
            <span>Note: You will be eligible for rewards in the next Epoch. Remember to vote for all proposals for the full reward.</span>
          </div>
        </div>
        <div className="panel">
          <div className="common__button" onClick={()=>close()}>Done</div>
        </div>
      </div>
    </div>
  )
}
