const txService = {
  txMap: {},
  handleSuccess: function(hash, fn) {
    this.txMap[hash] = {
        success: () => {
          fn()
          this.txMap[hash].success = () => {}
        }
    }
  }
}

export default txService