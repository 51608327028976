import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modal } from "src/app/components/Modals/Modal";
import BroadcastModal from "src/app/components/Modals/BroadcastModal";
import { ACTIONS, DEFAULT_GAS, TOPICS } from "src/app/configs/constants";
import { setTxConfirming } from "src/app/actions/accountAction";
import { setExecuteError, execute } from "src/app/actions/executeAction";
import GasOption from "src/app/components/Commons/GasOption";
import ModalPanel from "src/app/components/Modals/ModalPanel";
import CopyableTx from "src/app/components/Commons/CopyableTx";

export default function ExecutingContainer({executeInfo, clear}) {
  const { lastTx } = useSelector((state) => state.account);

  const [step, setStep] = useState(0);
  const isSendingTx = lastTx.type === ACTIONS.EXECUTE;

  const modals = [
    () => modal.show(ExecuteConfirmModal, { ...executeInfo }, closeModal),
    () => modal.show(BroadcastModal, { txHash: lastTx.hash, topic: TOPICS.EXECUTE, nextStep: nextStep }, closeModal),
    () => modal.show(ExecuteSuccessModal, { txHash: lastTx.hash }, closeModal)
  ];

  const requireInfo = (fn) => {
    if (Object.keys( executeInfo ).length > 0) {
      fn()
    }
  };

  useEffect(() => {
    if (!isSendingTx) return;
    nextStep();
  }, [lastTx] ); // eslint-disable-line

  useEffect(() => {
    requireInfo(() => {
      if (typeof modals[step] === 'function') modals[step]();
    })
  }, [step, executeInfo] ); // eslint-disable-line

  function closeModal() {
    setStep(0);
    clear();
  }

  function nextStep() {
    requireInfo(() => setStep(v => v + 1 ));
  }

  return <></>
}

function ExecuteConfirmModal({close, campID}) {
    const dispatch = useDispatch();
    const { address, txConfirming, privateKey } = useSelector((state) => state.account);
    const { executeError } = useSelector((state) => state.execute);
  
    useEffect(() => {
      dispatch(setExecuteError(''));
      dispatch(setTxConfirming(false));
    }, [dispatch]);
  
    const executeSubmit = () => {
      dispatch(execute(campID));
    };
  
    return (
      <div className="modal__vote modal-interact">
        <div className="modal-content">
          <div className="title">Execute</div>
          <div className="common__note mt-4 mb-4">
            <span>Do you wish to execute the proposal?</span>
          </div>
  
          <GasOption
            txType={ACTIONS.EXECUTE}
            txParams={{ address, campID }}
            defaultGasLimit={DEFAULT_GAS.VOTE}
          />
        </div>
  
        <ModalPanel
          error={executeError}
          txConfirming={txConfirming}
          handleCancel={close}
          handleConfirm={executeSubmit}
          showWatingMessage={!privateKey}
        />
      </div>
    )
  }
  
  function ExecuteSuccessModal({close, txHash}) {
    return (
      <div className="modal__vote modal-interact">
        <div className="modal__tx-done">
          <div className="modal-content">
            <div className="title">Done!</div>
            <div className="ins">The proposal has been executed already.</div>
            <CopyableTx txHash={txHash} />
          </div>
          <div className="panel">
            <div className="common__button" onClick={()=>close()}>Done</div>
          </div>
        </div>
      </div>
    )
  }
  