import React from "react";

export default function BasicModal(props) {
  return (
    <div className={`modal-interact ${props.className ? props.className : ''}`}>
      <div className="modal-content"> 
        <div className="title">{props.title}</div>
        {props.content}
      </div>
      <div className="panel">
        <div className="common__button common__button--hollow" onClick={props.close}>Cancel</div>

        {!!props.submit && (
          <div className="common__button" onClick={props.submit}>Confirm</div>
        )}
      </div>
    </div>
  )
}