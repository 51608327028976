import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { getNumberByPercentage } from "src/app/utils/converters";
import { roundNumber } from "src/app/utils/fortmaters";
import { BALANCE_PERCENTAGES } from "src/app/configs/constants";
import { filterNumberInput } from "src/app/utils/validators";
import Loading from "src/app/components/Commons/Loading";
import kncTokenOld from "src/assets/images/logos/logo-kncl.png";
import kncToken from "src/assets/images/tokens/knc.svg";

export default function AccountBalance(props) {
  let { address, balance, init } = useSelector((state) => state.account);
  const [balancePercentage, setBalancePercentage] = useState(0);
  const accountBalance = !!props.isOld ? balance.KNCOld : (props.stakedBalance ? balance.stakedKNC : balance.KNC);

  function handleBalancePercentageClicked(percentage) {
    props.clearError();
    props.setAmount(getNumberByPercentage(accountBalance, percentage));
    setBalancePercentage(percentage);
  }

  function handleAmountChanged(e) {
    const isValid = filterNumberInput(e, e.target.value, props.amount);

    if (!isValid) return;
    props.clearError();
    props.setAmount(e.target.value);
    setBalancePercentage(0)
  }

  return (
    <div className="account-balance">
      <div className={`account-balance__input-container ${props.error ? 'error' : ''}`}>
        <div className="account-balance__label">
          <img className="mr-1" src={!!props.isOld ? kncTokenOld : kncToken } alt="KNC Token" width={`${!!props.isOld ? "24px" : "auto"}`} />
          <div>{!!props.isOld ? "KNCL" : "KNC"}</div>
        </div>
        <input
          className="account-balance__input"
          type="text"
          min="0"
          step="0.000001"
          placeholder="0"
          onChange={handleAmountChanged}
          value={props.amount}
        />
      </div>

      {!!props.error && (
        <div className="common__error common__slide-up mt-1 mb-2">{props.error}</div>
      )}

      {address && (
        <div className="account-balance__info">
          <div className="account-balance__balance-container">
            <span>{props.stakedBalance ? 'Staked Balance' : 'Balance'}: </span>
            <span className="account-balance__balance">
            {init && balance.isLoading ? <Loading/> : 
              <span className="pointer" onClick={() => handleBalancePercentageClicked(100)}>
                {roundNumber(accountBalance, 4, true)} KNC
              </span>
            }
          </span>
          </div>

          <div className="account-balance__percentage-container">
            {!props.isOld && BALANCE_PERCENTAGES.map(percentage => (
              <div
                key={percentage}
                className={`account-balance__percentage ${percentage === balancePercentage ? 'active' : ''} ${balance.isLoading ? 'disabled' : ''}`}
                onClick={() => handleBalancePercentageClicked(percentage)}
              >
                {percentage}%
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
};
