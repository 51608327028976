import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DelegatingContainer from "src/app/components/Delegate/DelegatingContainer";
import { formatAddress, upperCase } from "src/app/utils/fortmaters";
import Loading from "src/app/components/Commons/Loading";
import { checkIsObjectEmpty, verifyAccount } from "src/app/utils/validators";
import closeIcon from "src/assets/images/icons/close.svg";
import { routes } from "src/app/configs/routes";
import { fetchAddressName } from "src/app/services/apis/daoService";
import useCheckAccountImported from "../Hooks/useCheckAccountImported";

export default function Delegate() {
  const { init, delegatedAddress } = useSelector((state) => state.delegate);
  const { address } = useSelector((state) => state.account);
  const { openActionModal } = useCheckAccountImported();

  const [delegateError, setDelegateError] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [delegateInfo, setDelegateInfo] = useState({});
  const [partnerInfo, setPartnerInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchDelegatedPartnerData() {
      const partner = await fetchAddressName(delegatedAddress);
      setPartnerInfo(partner);
    }

    if (delegatedAddress) fetchDelegatedPartnerData();
  }, [delegatedAddress]);

  async function delegate() {
    const fAddress = address.toLowerCase();
    const fNewAddress = newAddress.toLowerCase();
    const fDelegatedAddress = delegatedAddress.toLowerCase();

    if (fNewAddress === "" || !verifyAccount(fNewAddress)) {
      setDelegateError("Please key in a valid Ethereum address");
    } else if (
      (fDelegatedAddress === "" && fNewAddress === fAddress) ||
      (fDelegatedAddress !== "" && fNewAddress === fDelegatedAddress)
    ) {
      setDelegateError("Cannot delegate to the same address. Please choose another one.");
    } else {
      setIsLoading(true);
      const delegatedPartner = await fetchAddressName(fNewAddress);
      setDelegateInfo({ delegatedAddress: fNewAddress, delegatedPartner: delegatedPartner });
      setIsLoading(false);
    }
  }

  const removeDelegatedAddress = () => {
    setDelegateInfo({ isRemoved: true });
  };

  const handleAddressOnchange = (e) => {
    setDelegateError("");
    setNewAddress(e.target.value);
  };

  return (
    <div className="account__block">
      <div className="common__flex">
        <div className="account__title">Delegate</div>
        <Link to={routes.delegation} className="common__link mt-1" data-close-dropdown={true}>
          More
        </Link>
      </div>
      <div>
        {init && <Loading />}

        {!!delegatedAddress && delegatedAddress !== "" && upperCase(delegatedAddress) !== upperCase(address) && (
          <div className="current-delegate">
            <div className="account__text">You have delegated your voting power to:</div>
            <div className="mt-1 mb-3">
              <span className="account__text-small font-weight-bold mr-1">
                {!checkIsObjectEmpty(partnerInfo) ? partnerInfo.name : formatAddress(delegatedAddress, 18, -8)}
              </span>
              <img
                className="account__close-icon"
                src={closeIcon}
                alt="Close Icon"
                onClick={() => openActionModal(removeDelegatedAddress)}
              />
            </div>
          </div>
        )}

        <div className="delegate-form">
          <div className="account__text">Delegate your voting power to someone else.</div>
          <input
            className={`account__input mt-3 ${delegateError ? "error" : ""}`}
            type="text"
            placeholder="Delegate’s Ethereum Address"
            onChange={handleAddressOnchange}
          />

          {!!delegateError && <div className="common__error common__slide-up mt-1 mb-2">{delegateError}</div>}

          <div
            className={`common__button common__button--hollow mt-3 ${isLoading ? "common__disabled" : ""}`}
            onClick={() => openActionModal(delegate)}
          >
            Delegate
          </div>
        </div>
      </div>
      <DelegatingContainer delegateInfo={delegateInfo} clear={() => setDelegateInfo({})} />
    </div>
  );
}
