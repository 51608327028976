import React from 'react'
import CopyableTx from "src/app/components/Commons/CopyableTx"

export default function RemoveDelegateSuccessModal({ txHash, close }) {
  return (
    <div className="modal__delegate modal-interact">
      <div className="modal__tx-done">
        <div className="modal-content">
          <div className="title">Done!</div>
          <div className="ins">You have successfully removed your representative.</div>
          <CopyableTx txHash={txHash} />
          <div className="note">
            <span>Note: You will not have any rewards if you do not stake. If you still wish to get rewards, remember to stake and vote.</span>
          </div>
        </div>
        <div className="panel">
          <div className="common__button" onClick={() => close()}>Done</div>
        </div>
      </div>
    </div>
  )
}
