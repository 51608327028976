import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch } from "react-redux";
import { importAccount } from "src/app/actions/accountAction";
import { LEDGER_DERIVATION_PATHS, WALLET_TYPES } from "src/app/configs/constants";
import LedgerService from "src/app/services/accounts/Devices/LedgerService";
import { modal } from "src/app/components/Modals/Modal";
import BasicModal from "src/app/components/Modals/BasicModal";
import DeviceLoading from "src/app/components/Modals/Device/DeviceLoading";
import DeviceSelectAddress from "src/app/components/Modals/Device/DeviceSelectAddress";

export default function LedgerAccount(props) {
  const dispatch = useDispatch();
  const [init, setInit] = useState(false);
  const [selectedPath, setSelectedPath] = useState(LEDGER_DERIVATION_PATHS[0]);

  useEffect(() => {
    if (!init) return;
    setConnection();
  }, [selectedPath.value]); // eslint-disable-line

  function connect() {
    setInit(true);
    setConnection();
  }

  async function setConnection() {
    showLoadingModal();

    try {
      const wallet = new LedgerService();

      await wallet.setConnection(selectedPath.value, selectedPath.bip44);

      showSelectAddressModal(wallet, selectedPath);
    } catch (e) {
      const errorCode = e.id ? e.id : e.statusCode;
      showErrorModal(e.message, errorCode);
    }
  }

  function onSelectAddress(address, wallet) {
    let devicePath = `${selectedPath.value}/${address.index}`;

    if (selectedPath.bip44) {
      devicePath = `${selectedPath.value}/${address.index}'/0/0`;
    }

    dispatch(importAccount(address.address, wallet, WALLET_TYPES.LEDGER, { devicePath }));
    modal.close();
  }

  function showSelectAddressModal(wallet, path) {
    modal.show(DeviceSelectAddress, {
      deviceType: 'Ledger',
      selectedPath: path,
      wallet: wallet,
      onSelectAddress: onSelectAddress,
      onSelectPath: setSelectedPath,
      paths: LEDGER_DERIVATION_PATHS
    });
  }

  function showLoadingModal() {
    modal.show(DeviceLoading, {
      loadingContent: (
        <Fragment>
          <div>Please make sure:</div>
          <div>- Your Ledger is properly plugged in.</div>
          <div>- You have logged into your Ledger.</div>
        </Fragment>
      ),
      hideCloseButton: true
    });
  }

  function showErrorModal(errorMessage, errorCode) {
    modal.show(BasicModal, {
      title: "Error",
      content: (
        <>
          {errorMessage && (
            <div>{errorMessage}</div>
          )}

          {(!errorMessage || errorCode === 'U2F_1' || errorCode === 26628) && (
            <div className="mt-2 common__lh-5">
              <p>Cannot connect to your Ledger. Please check if:</p>
              <p>- Your Ledger is properly plugged in.</p>
              <p>- You have logged into your Ledger.</p>
              <p>- You have launched Ethereum Application on your Ledger.</p>
              <p>- Your selected path is supported by Ledger.</p>
              <p>- "Browser Support" has been enabled in your Ledger. Learn to enable "Browser Support" <a href="https://support.ledgerwallet.com/hc/en-us" className="common__link-text" target="_blank" rel="noreferrer noopener">here</a></p>
            </div>
          )}
        </>
      ),
    }, resetToDefaultPath);
  }

  function resetToDefaultPath() {
    setSelectedPath(LEDGER_DERIVATION_PATHS[0])
  }

  return (
    <div className={`account__item ${props.className}`} onClick={connect}>
      <div className="account__item-icon ledger"/>
      <div className="account__item-name">Ledger</div>
    </div>
  )
};
