import { withdrawActionTypes } from "src/app/actions/withdrawAction";

const initialState = {
  withdrawError: ''
};

export default function withdrawReducer(state = initialState, action) {
  switch (action.type) {
    case withdrawActionTypes.SET_ERROR: {
      return {
        ...state,
        withdrawError: action.payload
      }
    }
    default:
      return state
  }
}