import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApproveTokenModal from "../Modals/Approve/ApproveTokenModal";
import { modal } from "src/app/components/Modals/Modal";
import BroadcastModal from "../Modals/BroadcastModal";
import { ACTIONS, DEFAULT_GAS, STAKING_STEPS, TOPICS } from "src/app/configs/constants";
import Web3Service from "src/app/services/web3/Web3Service";
import AccountBalance from "../Accounts/AccountBalance";
import GasOption from "../Commons/GasOption";
import { toggleAccountModal } from "src/app/actions/globalAction";
import { compareTwoNumber } from "src/app/utils/calculators";
import { toBigAmount } from "src/app/utils/converters";
import { validateBalanceInput } from "src/app/utils/validators";
import MigrateConfirmModal from "./MigrateConfirmModal";
import MigrateSuccessModal from "./MigrateSuccessModal";
import ENV from "src/app/configs/env";
import kncToken from "src/assets/images/tokens/knc.svg";
import newTag from "src/assets/images/tokens/new_tag.svg";
import arrow from "src/assets/images/tokens/ic-arrow-forward.svg";
import Loading from "../Commons/Loading";
import { roundNumber } from "src/app/utils/fortmaters";

const MIGRATE_STEPS = STAKING_STEPS;
export default () => {
  const dispatch = useDispatch();
  const { address, balance, lastTx } = useSelector((state) => state.account);

  const [migrateAmount, setMigrateAmount] = useState("");
  const [migrateStep, setMigrateStep] = useState(MIGRATE_STEPS.DEFAULT);
  const [forceOpenModal, setForceUpdateModal] = useState(0);
  const [error, setError] = useState("");

  const isSendingTx =
    migrateStep !== MIGRATE_STEPS.DEFAULT && (lastTx.type === ACTIONS.APPROVE || lastTx.type === ACTIONS.MIGRATE);

  useEffect(() => {
    if (migrateStep === MIGRATE_STEPS.APPROVE_ZERO) {
      modal.show(
        ApproveTokenModal,
        {
          isApproveToMax: false,
          token: ENV.OLD_KNC_ADDRESS,
          delegator: ENV.KNC_ADDRESS,
        },
        closeModal
      );
    } else if (migrateStep === MIGRATE_STEPS.APPROVE_MAX) {
      modal.close();
      setTimeout(() => {
        modal.show(
          ApproveTokenModal,
          {
            isApproveToMax: true,
            token: ENV.OLD_KNC_ADDRESS,
            delegator: ENV.KNC_ADDRESS,
          },
          closeModal
        );
      }, 0);
    } else if (migrateStep === MIGRATE_STEPS.CONFIRM) {
      modal.show(MigrateConfirmModal, { migrateAmount: migrateAmount }, closeModal);
    } else if (migrateStep === MIGRATE_STEPS.BROADCAST) {
      modal.show(BroadcastModal, { txHash: lastTx.hash, topic: TOPICS.MIGRATE, nextStep: goToNextStep }, closeModal);
    } else if (migrateStep === MIGRATE_STEPS.SUCCESS) {
      modal.show(MigrateSuccessModal, { txHash: lastTx.hash, migrateAmount: migrateAmount }, closeModal);
    }
  }, [migrateStep, forceOpenModal]); // eslint-disable-line

  useEffect(() => {
    if (!isSendingTx) return;

    // vutien
    // txService.handleSuccess(lastTx.hash, () => {
    //   const currentPendingStakeAmount = accountInfo.pending_stake_amount;
    //   dispatch(
    //     setPendingStakeAmount(currentPendingStakeAmount + migrateAmount)
    //   );
    // });
    goToNextStep();
  }, [lastTx]); // eslint-disable-line

  useEffect(() => {
    clearError();
  }, [address]); // eslint-disable-line

  async function openMigrateModal() {
    const isInputValid = validateInput();

    if (!isInputValid) return;

    const web3Service = new Web3Service();
    const tokenAllowance = await web3Service.fetchTokenAllowance(address, ENV.OLD_KNC_ADDRESS, ENV.KNC_ADDRESS);
    if (+tokenAllowance === 0) {
      setMigrateStep(MIGRATE_STEPS.APPROVE_MAX);
    } else if (compareTwoNumber(tokenAllowance, toBigAmount(migrateAmount)) === -1) {
      setMigrateStep(MIGRATE_STEPS.APPROVE_ZERO);
    } else {
      setMigrateStep(MIGRATE_STEPS.CONFIRM);
    }
  }

  function validateInput() {
    let isValid = true;
    let errorMessage = validateBalanceInput(migrateAmount, balance.KNCOld, address);

    if (errorMessage !== null) {
      setError(errorMessage);
      isValid = false;
    } else if (isSendingTx && migrateStep !== MIGRATE_STEPS.DEFAULT) {
      setForceUpdateModal(forceOpenModal + 1);
      isValid = false;
    }

    return isValid;
  }

  function resetStep() {
    setMigrateStep(MIGRATE_STEPS.DEFAULT);
  }

  function goToNextStep() {
    setMigrateStep(migrateStep + 1);
  }

  function clearError() {
    setError("");
  }

  function closeModal() {
    setMigrateAmount("");
    resetStep();
  }

  function openImportModal() {
    dispatch(toggleAccountModal());
  }

  return (
    <div style={{ padding: "30px auto" }}>
      <MigrateForm
        address={address}
        error={error}
        setError={setError}
        migrationAmount={migrateAmount}
        setMigrationAmount={setMigrateAmount}
        openStakingModal={openMigrateModal}
        openImportModal={openImportModal}
      />
    </div>
  );
};

function MigrateForm(props) {
  function clearError() {
    props.setError("");
  }

  const { address, balance, init } = useSelector((state) => state.account);
  return (
    <div className="migration" style={{ background: "white" }}>
      <div className="common__block-title">KNC Migration</div>
      <div className="ins mb-3">
        Click <b style={{ fontWeight: "500" }}>Migrate</b> to start the migration process from KNC Legacy to the new
        KNC. You will receive the new KNC tokens in your wallet once the transaction has been confirmed. Conversion rate
        is 1:1. Read about the KNC migration{" "}
        <a style={{ color: "#31cb9e" }} href="https://blog.kyber.network/knc-token-migration-guide-fda08bfe62c2">
          here
        </a>
        .
      </div>
      <div className="row">
        <div className="col-lg-5">
          <AccountBalance
            amount={props.migrationAmount}
            setAmount={props.setMigrationAmount}
            error={props.error}
            clearError={clearError}
            isOld={true}
          />
        </div>
        <div className="col-lg-2 text-center">
          <img src={arrow} className="migration__arrow" alt="arrow-icon" />
        </div>
        <div className="col-lg-5">
          <div>
            <div className={`account-balance__input-container`}>
              <div className="account-balance__label" style={{ position: "relative" }}>
                <img
                  className="mr-1"
                  src={newTag}
                  alt="KNC Token"
                  style={{ position: "absolute", top: "0", left: "0" }}
                />
                <img className="mr-1" src={kncToken} alt="KNC Token" />
                <div>KNC</div>
              </div>
              <input
                className="account-balance__input"
                type="text"
                min="0"
                step="0.000001"
                placeholder="0"
                onChange={() => {}}
                disabled={true}
                value={props.migrationAmount}
              />
            </div>
            <div className="account-balance__balance-container text-right">
              <span>Balance: </span>
              <span className="account-balance__balance">
                {init && balance.isLoading ? <Loading /> : <span>{roundNumber(balance.KNC, 4, true)} KNC</span>}
              </span>
            </div>
          </div>
        </div>
      </div>

      <GasOption
        txType={ACTIONS.MIGRATE}
        txParams={{ address: props.address, migrateAmount: 1 }}
        defaultGasLimit={DEFAULT_GAS.STAKE}
      />
      <div className="staking__button-container">
        {!address ? (
          <>
            <button className="common__button" onClick={props.openImportModal}>
              Import wallet
            </button>
          </>
        ) : (
          <div className="staking__button common__button" onClick={props.openStakingModal}>
            Migrate
          </div>
        )}
      </div>
    </div>
  );
}
