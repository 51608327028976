import React from 'react';
import { useDispatch } from "react-redux";
import WalletConnectService from "src/app/services/accounts/WalletConnectService";
import { clearAccount, importAccount } from "src/app/actions/accountAction";
import { modal } from "src/app/components/Modals/Modal";
import BasicModal from "src/app/components/Modals/BasicModal";
import { WALLET_TYPES } from "src/app/configs/constants";

export default function WalletConnectAccount(props) {
  const dispatch = useDispatch();

  async function connect() {
    const wallet = new WalletConnectService();

    await wallet.openQRCodeModal();

    wallet.getConnected(
      (address) => {
        dispatch(importAccount(address, wallet, WALLET_TYPES.WALLET_CONNECT));
        modal.close();
      },
      (errorMessage) => openErrorModal(errorMessage)
    );

    wallet.getDisconnected(
      () => dispatch(clearAccount()),
      (errorMessage) => openErrorModal(errorMessage)
    );
  }

  function openErrorModal(errorMessage) {
    modal.show(BasicModal, {
      title: "Error",
      content: <div>{errorMessage}</div>
    });
  }

  return (
    <div className={`account__item ${props.className}`} onClick={connect}>
      <div className="account__item-icon wallet-connect"/>
      <div className="account__item-name">Connect</div>
    </div>
  )
};
