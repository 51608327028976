import React from "react";
import IcDiscord from "src/assets/icons/IcDiscord";
import IcGithub from "src/assets/icons/IcGithub";
import IcTwitter from "src/assets/icons/IcTwitter";
import { NavLink } from "react-router-dom";
import { routes } from "src/app/configs/routes";
import { useSelector } from "react-redux";
import { displayFormattedNumber, roundNumber } from "src/app/utils/fortmaters";
import { TERMS_LINK } from "src/app/configs/constants";

export default function Footer() {
  const { daoInfo, headerData } = useSelector((state) => state.global);
  const ethRateInUSD = headerData.eth.rate;
  const totalFeeInUSD = daoInfo.totalFee * ethRateInUSD;
  const totalRewardInUSD = daoInfo.totalReward * ethRateInUSD;
  const totalRebateInUSD = daoInfo.totalRebate * ethRateInUSD;
  const totalBurnInUSD = daoInfo.totalBurn * ethRateInUSD;

  return (
    <div className="footer">
      <div className="footer__data hide">
        <div className="footer__data-item">KNC price: ${roundNumber(headerData.knc.rate, 2, true)}</div>
        <div className="footer__data-item">ETH price: ${roundNumber(ethRateInUSD, 2, true)}</div>
        <div className="footer__data-item">Total Fee: ${displayFormattedNumber(totalFeeInUSD)}</div>
        <div className="footer__data-item">Total Burn: ${displayFormattedNumber(totalBurnInUSD)}</div>
        <div className="footer__data-item">Total Reward: ${displayFormattedNumber(totalRewardInUSD)}</div>
        <div className="footer__data-item">Total Rebate: ${displayFormattedNumber(totalRebateInUSD)}</div>
      </div>

      <div className="footer__content container d-flex">
        <div className="footer__menus">
          <div>
            <div className="social">
              <a href="https://twitter.com/KyberDAO/" target="_blank" rel="noreferrer noopener">
                <IcTwitter />
              </a>
              <a href="https://discord.com/invite/NB3vc8J9uv" target="_blank" rel="noreferrer noopener">
                <IcDiscord />
              </a>

              <a
                href="https://github.com/KyberNetwork/developer-portal/blob/stakingSection/README.md"
                target="_blank"
                rel="noreferrer noopener"
              >
                <IcGithub />
              </a>
            </div>
            <div className="links">
              <a href="https://gov.kyber.org/" target="_blank" rel="noreferrer noopener">
                Forum
              </a>
              <NavLink to={routes.faq}>FAQs</NavLink>
              <a href={TERMS_LINK} target="_blank" rel="noopener noreferrer">
                Terms
              </a>
              <a href="https://kyberswap.com/" target="_blank" rel="noopener noreferrer">
                KyberSwap
              </a>
              <a href="https://kyber.network/" target="_blank" rel="noopener noreferrer">
                Kyber Network
              </a>
            </div>
          </div>
          <p>© 2020 Kyber Network | DAO. All rights reserved.</p>
        </div>
        <div className="footer__menus__mobile">
          <div>
            <a href="https://gov.kyber.org/" target="_blank" rel="noreferrer noopener">
              Forum
            </a>
            |<NavLink to={routes.faq}>FAQs</NavLink>|
            <a href={TERMS_LINK} target="_blank" rel="noopener noreferrer">
              Terms
            </a>
          </div>
          <div>
            <a href="https://kyberswap.com/" target="_blank" rel="noopener noreferrer">
              KyberSwap
            </a>
            |
            <a href="https://kyber.network/" target="_blank" rel="noopener noreferrer">
              Kyber Network
            </a>
          </div>
          <div className="social">
            <a href="https://twitter.com/KyberDAO/" target="_blank" rel="noreferrer noopener">
              <IcTwitter />
            </a>
            <a href="https://discord.com/invite/NB3vc8J9uv" target="_blank" rel="noreferrer noopener">
              <IcDiscord />
            </a>
            <a
              href="https://github.com/KyberNetwork/developer-portal/blob/stakingSection/README.md"
              target="_blank"
              rel="noreferrer noopener"
            >
              <IcGithub />
            </a>
          </div>
          <p>© 2020 Kyber Network | DAO. All rights reserved.</p>
        </div>
      </div>
    </div>
  );
}
