import React from "react";
import { useDispatch, useSelector } from "react-redux";
import IdleTimer from 'react-idle-timer'
import { clearPrivateKey } from "src/app/actions/accountAction";
import { TIMEOUT_IN_MS, WALLET_TYPES } from "src/app/configs/constants";

export default function Timeout() {
  const dispatch = useDispatch();
  const { address, type } = useSelector(state => state.account);

  function onIdle() {
    if (!address || type === WALLET_TYPES.DAPP) return;
    dispatch(clearPrivateKey());
  }

  return <IdleTimer element={document} onIdle={onIdle} timeout={TIMEOUT_IN_MS} />
}