import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { displayFormattedNumber, formatBigNumber } from "src/app/utils/fortmaters";
import CountUp from "react-countup";
import Web3Service from "src/app/services/web3/Web3Service";
import { FETCHING_INTERVALS } from "src/app/configs/constants";
import Loading from "src/app/components/Commons/Loading";

export default function StakeInfo() {
  const { daoInfo, headerData, votingReward } = useSelector((state) => state.global);

  const [startTotalStaked, setStartTotalStaked] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);

  const kncInUSD = headerData.knc.rate;
  const totalStakedKNCInUSD = totalStaked * kncInUSD;
  const [totalMigrated, setTotalMigrated] = useState(0);
  const totalMigratedKNCInUSD = totalMigrated * kncInUSD;

  useEffect(() => {
    function setStartAndEndPoint(unit, field, setStartPoint, setEndPoint) {
      if (unit === 0 && daoInfo[field]) {
        setStartPoint(+daoInfo[field]);
      }

      if (daoInfo[field]) {
        setEndPoint(+daoInfo[field]);
      }
    }

    setStartAndEndPoint(totalStaked, "totalStaked", setStartTotalStaked, setTotalStaked);
  }, [daoInfo]); // eslint-disable-line

  useEffect(() => {
    const web3Service = new Web3Service();
    function fetchTokenTotalSupply() {
      web3Service.fetchTokenTotalSupply().then((res) => {
        setTotalMigrated(formatBigNumber(res));
      });
    }

    const interval = setInterval(fetchTokenTotalSupply, FETCHING_INTERVALS.BALANCE);
    fetchTokenTotalSupply();

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="stake-info">
      <div className="stake-info__title">
        Stake <span className="text-success">KNC</span> - Vote - Earn Rewards
      </div>
      <div className="row">
        <div className="col-lg-4 stake-info__item">
          <div className="stake-info__item-wrapper">
            <div className="stake-info__item-title">Total Staked</div>
            <div className="stake-info__item-value">
              <div>
                <CountUp start={startTotalStaked} end={totalStaked} separator="," preserveValue={true} />
                <span className="stake-info__item-unit">KNC</span>
              </div>
              <div className="stake-info__item-subvalue">~ {displayFormattedNumber(totalStakedKNCInUSD)} USD</div>
            </div>
          </div>
        </div>

        <div className="col-lg-4 stake-info__item">
          <div className="stake-info__item-wrapper">
            <div className="stake-info__item-title">Voting Reward</div>
            <div className="stake-info__item-value">
              {!votingReward ? (
                <Loading />
              ) : (
                <>
                  <div>
                    <span>{displayFormattedNumber(votingReward.knc)}</span>
                    <span className="stake-info__item-unit">KNC</span>
                  </div>
                  <div className="stake-info__item-subvalue">~ {displayFormattedNumber(votingReward.usd)} USD</div>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-4 stake-info__item">
          <div className="stake-info__item-wrapper">
            <div className="stake-info__item-title">Total KNC migrated</div>
            <div className="stake-info__item-value">
              <div>
                <span>{displayFormattedNumber(totalMigrated)}</span>
                <span className="stake-info__item-unit">KNC</span>
              </div>
              <div className="stake-info__item-subvalue">~ {displayFormattedNumber(totalMigratedKNCInUSD)} USD</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
