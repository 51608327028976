import React from 'react';
import poolOperatorImage from 'src/assets/images/backgrounds/pool-operator.svg';
import delegationImage from 'src/assets/images/backgrounds/delegation.svg';

export default function DelegationExplain() {
  return (
    <div className="delegation">
      <div className="delegation__block">
        <div className="delegation__title">What is delegation?</div>
        <div className="delegation__content-container">
          <div className="delegation__content">
            <div className="delegation__subtitle">
              Easily stake your tokens and get rewards by choosing a pool operator that aligns with your needs
            </div>
            <div className="delegation__text">
              KNC holders who are willing to stake their tokens but do not have the resources to regularly participate
              in
              voting in every epoch (about 2 weeks), have the ability to delegate their voting power to another Ethereum
              address or a ‘pool’ to vote on their behalf. Projects or developers that set up and manage this voting
              pool
              are called ‘pool operators’.
            </div>
            <a className="common__button common__button--lg"
               href="https://blog.kyber.network/kyberdao-options-for-pool-operators-d2ea050c76c5"
               target="_blank"
               rel="noopener noreferrer">
              Learn More
            </a>
          </div>
          <img className="delegation__image" src={delegationImage} alt="Delegation"/>
        </div>
      </div>

      <div className="delegation__block">
        <div className="delegation__title">Be a pool operator</div>
        <div className="delegation__content-container">
          <div className="delegation__content">
            <div className="delegation__subtitle">Determine your own fee structure</div>
            <div className="delegation__text">
              A key player in this delegated voting system is the KyberDAO pool operator. Pool operators form an crucial
              part of the overall KyberDAO system, helping to ensure that a wide range of voting interests will be
              represented while allowing KNC holders to easily stake their tokens and get rewards by choosing a pool
              operator who aligns with them.
            </div>
            <a className="common__button common__button--lg"
               href="https://blog.kyber.network/kyberdao-options-for-pool-operators-d2ea050c76c5"
               target="_blank"
               rel="noopener noreferrer">
              Learn More
            </a>
          </div>
          <img className="delegation__image" src={poolOperatorImage} alt="Pool Operator"/>
        </div>
      </div>
    </div>
  )
}
