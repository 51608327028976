import React, { useState, useEffect } from "react";
import Web3Service from "src/app/services/web3/Web3Service";
import broadcast from "src/assets/images/icons/broadcast.svg";
import done from "src/assets/images/icons/done.svg";
import error from "src/assets/images/icons/error.svg";
import errorBold from "src/assets/images/icons/error-bold.svg";
import CopyableTx from "src/app/components/Commons/CopyableTx";
import Loading from "src/app/components/Commons/Loading";
import { BROADCAST_STATUSES, FETCHING_INTERVALS } from "src/app/configs/constants";
import ViewOn from "src/app/components/Commons/ViewOn";

export default function BroadcastModal({ txHash, topic, close, nextStep }) {
  const [status, setStatus] = useState(BROADCAST_STATUSES.BROADCAST);

  const web3Service = new Web3Service();

  useEffect(() => {
    const checkTxMinedInterval = setInterval(() => {
      web3Service.checkTxMined(txHash, topic).then(isMined => {
        if (isMined !== null) {
          clearInterval(checkTxMinedInterval);

          if (isMined) {
            setStatus(BROADCAST_STATUSES.SUCCESS);
            if (typeof nextStep === 'function') nextStep();
            return;
          }

          setStatus(BROADCAST_STATUSES.ERROR);
        }
      })
    }, FETCHING_INTERVALS.TX_MINE);

    return () => {
      clearInterval(checkTxMinedInterval)
    }
  }, []); // eslint-disable-line

  const collection = [
    {
      status: BROADCAST_STATUSES.BROADCAST,
      title: "Broadcasted",
      button: "Close",
      icon: broadcast,
      onClick: close,
      message: (
        <>
          <Loading />
          <span className="broadcast-mining ml-1"> Waiting for the transaction to be mined</span>
        </>
      )
    },
    {
      status: BROADCAST_STATUSES.SUCCESS,
      title: "Done",
      button: "Done",
      icon: done,
      onClick: close,
      message: <span className="broadcast-mined">Transaction successfully mined</span>
    },
    {
      status: BROADCAST_STATUSES.ERROR,
      title: "Failed",
      button: "Try again",
      icon: error,
      onClick: close,
      message: (
        <>
          <img alt="icon" src={errorBold} className="transaction-error" />
          <span className="broadcast-mining ml-4 pl-1">Transaction error</span>
        </>
      )
    }
  ];

  const item = collection[status];

  return (
    <div className="modal-interact">
      <div className="modal-broadcast">
        <div className="modal-content">
          <div className="title common__flex-column">
            <img width="120" alt="icon" src={item.icon} />
            <div>{item.title}</div>
          </div>

          <div className="modal-broadcast__result">
            {item.message}
          </div>
        </div>
        <div className="panel-light modal-broadcast__transaction">
          <CopyableTx txHash={txHash} />
          <ViewOn txHash={txHash} />
        </div>
        <div className="panel">
          <div
            className={`common__button ${item.status === BROADCAST_STATUSES.ERROR ? "common__button--hollow" : ""}`}
            onClick={item.onClick}
          >
            {item.button}
          </div>
        </div>
      </div>
    </div>
  )
}
