import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { PAGING } from "src/app/configs/constants";
import { setPage } from "src/app/actions/historyAction";
import Loading from "src/app/components/Commons/Loading";
import { formatAddress, roundNumber } from "src/app/utils/fortmaters";
import ENV from "src/app/configs/env";
import etherscanLogo from "src/assets/images/logos/etherscan.png";

export default function History() {
  const dispatch = useDispatch();
  const { address } = useSelector(state => state.account);
  const { histories, page } = useSelector(state => state.history);
  const { activeCampaigns, endedCampaigns } = useSelector(state => state.campaign);
  let tempDate = ""

  const renderDetails = (action) => {
    const actionData = action.meta;

    if (["Deposit", "Withdraw"].includes(action.type)) {
      return `${action.type} ${roundNumber(actionData.amount, 4, true)} KNC`;
    } else if (action.type === "Delegate") {
      const delegatedAddress = actionData.d_addr;
      const formattedAddress = formatAddress(delegatedAddress, 10, -5);

      return `${action.type} to ${formattedAddress}`;
    } else if (action.type === "VoteEmitted") {
      const campaigns = activeCampaigns.concat(endedCampaigns);
      const filteredCampaigns = campaigns.filter(c => c.proposal_id === actionData.proposal_id)[0];

      if (typeof filteredCampaigns === "undefined") return;

      const options = actionData.options.map((o, index) => filteredCampaigns.opts_desc[o] || o)

      return `Vote for proposal ${filteredCampaigns.title ? `"${filteredCampaigns.title}"` : `#${filteredCampaigns.proposal_id}`}: ${options}`;
    } else if (action.type === 'ClaimReward') {
      return `Claimed ${actionData.amount ? actionData.amount : 0} ETH as Reward for Epoch ${action.epoch}`;
    }
  };

  return (
    <div className="history">
      <div className="common__block h-100">
        <div className="common__block-header">
          <div className="common__block-title">Your Past Actions</div>
        </div>
        {address && (
          <>
            {!histories && <Loading/>}
            {(histories && histories.length !== 0) && (
              <div className="common__block-body">
                <div className="history__table">
                  {histories.slice(0, page * PAGING.HISTORY_DISPLAY).map((action, index) => {
                    const day = moment.unix(action.timestamp).format("DD MMM YYYY")
                    const show = day !== tempDate
                    if (show) tempDate = day

                    return (
                      <div key={index} className={"row common__slide-up"}>
                        {show && <div className="col-md-12 p-0 font-weight-medium mt-3 history__subtitle">{day}</div>}
                        <div className="history__table-epoch col-md-3 p-0">
                          Epoch {action.epoch}: {moment.unix(action.timestamp).format("HH:mm")}
                        </div>
                        <div className="col-md-9 p-0">
                          <span className="mr-2">{renderDetails(action)}</span>
                          {action.tx_hash && (
                            <a href={`${ENV.URLS.ETHERSCAN}/tx/${action.tx_hash}`} target="_blank" rel="noreferrer noopener">
                              <img className="icon__etherscan" alt="Etherscan" src={etherscanLogo}/>
                            </a>
                          )}
                        </div>
                      </div>
                    )
                  })}
                  {page * PAGING.HISTORY_DISPLAY < histories.length && (
                    <div className="common__flex mt-4" style={{ justifyContent: "center" }}>
                      <div className="common__link " onClick={() => dispatch(setPage(page + 1))}>View More</div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {(histories && histories.length === 0) && (
              <div>No past actions.</div>
            )}
          </>
        )}
        {!address && <div>Please import a wallet first</div>}
      </div>
    </div>
  );
}
