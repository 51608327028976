import React from "react";

export default function LogoKyber(props) {
  return (
    <svg width="38" height="54" viewBox="0 0 38 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.189 26.9923L36.3558 38.5537C37.0522 38.9562 37.9499 38.4454 37.9499 37.6406V16.3439C37.9499 15.5391 37.0677 15.0284 36.3558 15.4308L16.189 26.9923Z"
        fill={props.fill || "#31CB9E"}
      />
      <path
        d="M35.1799 10.4471L21.7922 0.216654C21.1731 -0.247662 20.2754 0.0773594 20.1051 0.835743L15.2144 23.6182L35.0716 12.2115C35.7371 11.8246 35.799 10.9114 35.1799 10.4471Z"
        fill={props.fill || "#31CB9E"}
      />
      <path
        d="M21.7922 53.7834L35.2109 43.5375C35.8145 43.0732 35.7526 42.16 35.1026 41.7731L15.2144 30.3818L20.1206 53.1643C20.2599 53.9227 21.1576 54.2477 21.7922 53.7834Z"
        fill={props.fill || "#31CB9E"}
      />
      <path
        d="M11.0198 26.9922L16.2975 2.44534C16.4987 1.48575 15.3998 0.773796 14.6105 1.39288L1.09888 11.7162C0.402407 12.2424 0 13.0782 0 13.9449V40.0859C0 40.9526 0.402407 41.7884 1.09888 42.3146L14.6105 52.6225C15.3843 53.2106 16.4987 52.5141 16.2975 51.57L11.0198 26.9922Z"
        fill={props.fill || "#31CB9E"}
      />
    </svg>
  );
}
