import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { calculateTxFee } from "src/app/utils/calculators";

export default function ModalPanel(props) {
  const balanceETH = useSelector((state) => state.account.balance.ETH);
  const { gasPrices, selectedGasPrice, estimatedGas } = useSelector((state) => state.global);
  const [error, setError] = useState('');
  const buttonConfirm = props.buttonConfirm ? props.buttonConfirm : 'Confirm';
  const isLoading = props.isGasLoading || props.txConfirming;

  useEffect(() => {
    const txFee = props.txFee ? props.txFee : calculateTxFee(gasPrices[selectedGasPrice], estimatedGas);

    if (txFee > balanceETH) {
      setError("Insufficient ETH for transaction, deposit more ETH to continue.");
    } else {
      setError("");
    }
  }, [props.txFee, gasPrices, selectedGasPrice, estimatedGas, balanceETH]);

  return (
    <>
      {(!!props.error || !!error) && (
        <div className="modal__block pt-0">
          <div className="common__error common__slide-up">{error || props.error}</div>
        </div>
      )}

      {(props.showWatingMessage && props.txConfirming) && (
        <div className="modal__block pt-0">
          <div className="common__message common__slide-up">Waiting for confirmation from your wallet</div>
        </div>
      )}

      <div className="panel">
        <div className="common__button common__button--hollow" onClick={props.handleCancel}>Cancel</div>
        <div className={`common__button ${isLoading || error ? 'common__disabled' : ''}`} onClick={props.handleConfirm}>
          {buttonConfirm}
        </div>
      </div>
    </>
  )
}
