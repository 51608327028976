import { delegateActionTypes } from "src/app/actions/delegateAction";

const initialState = {
  init: true,
  delegateError: '',
  delegatedAddress: "",
};

export default function delegateReducer(state = initialState, action) {
  switch (action.type) {
    case delegateActionTypes.SET_DELEGATE_ERROR: {
      return {
        ...state,
        delegateError: action.payload
      }
    }

    case delegateActionTypes.SET_DELEGATED_ADDRESS: {
      const address = action.payload;
      return {
        ...state,
        init: false,
        delegatedAddress: address
      }
    }

    default:
      return state
  }
}