export const rewardActionTypes = {
  FETCH_ACCOUNT_REWARDS: 'REWARD.FETCH_ACCOUNT_REWARDS',
  CLAIM_REWARDS: 'REWARD.CLAIM_REWARDS',
  SET_REWARDS: 'REWARD.SET_REWARDS',
  RESET_REWARDS: 'REWARD.RESET_REWARDS',
  SET_ERROR: 'REWARD.SET_ERROR',
};

export function fetchAccountRewards(address) {
  return {
    type: rewardActionTypes.FETCH_ACCOUNT_REWARDS,
    payload: address
  }
}

export function claimRewards(rewards) {
  return {
    type: rewardActionTypes.CLAIM_REWARDS,
    payload: rewards
  }
}

export function setRewardError(error) {
  return {
    type: rewardActionTypes.SET_ERROR,
    payload: error
  }
}

export function setRewards(rewards) {
  return {
    type: rewardActionTypes.SET_REWARDS,
    payload: { rewards }
  }
}

export function resetRewards() {
  return {
    type: rewardActionTypes.RESET_REWARDS
  }
}
